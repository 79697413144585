import * as React from 'react';
import { SVGProps } from 'react';
const SvgChemicalBottle = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.5 5A.75.75 0 0 0 9 5h1.5ZM15 5a.75.75 0 0 0-1.5 0H15Zm.466 8.196-.636.397.636-.397Zm3.784 6.054V20a.75.75 0 0 0 .636-1.148l-.636.398Zm-14.5 0-.636-.398A.75.75 0 0 0 4.75 20v-.75Zm3.784-6.054.636.397-.636-.397ZM9 5v3.956h1.5V5H9Zm6 3.956V5h-1.5v3.956H15Zm-.17 4.637 3.784 6.055 1.272-.796-3.784-6.054-1.272.795Zm4.42 4.907H4.75V20h14.5v-1.5ZM5.386 19.648l3.784-6.055-1.272-.795-3.784 6.054 1.272.796ZM13.5 8.956c0 1.64.46 3.247 1.33 4.637l1.272-.795A7.25 7.25 0 0 1 15 8.956h-1.5Zm-4.5 0a7.25 7.25 0 0 1-1.102 3.842l1.272.795a8.75 8.75 0 0 0 1.33-4.637H9Z"
      fill="currentColor"
    />
    <path d="M6.75 4.75h10.5" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default SvgChemicalBottle;
