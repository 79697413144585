import * as React from 'react';
import { Flex } from '@mutiny-pkg/dumpster-ui';
import { Box } from '../Box';
import { gridBaseStyles, headerBaseStyles, sidebarBaseStyles, mainPaneBaseStyles } from './TwoPaneLayout.css';

export interface TwoPaneLayoutProps {
  renderHeader: () => React.ReactNode;
  renderSidebar: () => React.ReactNode;
  children?: React.ReactNode;
}

export const TwoPaneLayout: React.FC<TwoPaneLayoutProps> = ({ renderHeader, renderSidebar, children }) => {
  return (
    <Box className={gridBaseStyles}>
      <Box className={headerBaseStyles}>{renderHeader()}</Box>
      <Flex.Container>
        <Flex.Container flex={1} className={mainPaneBaseStyles}>
          {children}
        </Flex.Container>
        <Box display="flex" flexDirection="column" className={sidebarBaseStyles}>
          {renderSidebar()}
        </Box>
      </Flex.Container>
    </Box>
  );
};
