import cx from 'classnames';
import * as React from 'react';

import { Grid } from '@material-ui/core';

import { Card, CardBody } from '@mutiny-pkg/dumpster-ui';
import WithStyles, { IStyleProps } from '../higher_order/WithStyles';

import Logo from './Logo';

interface IProps extends IStyleProps {
  color?: 'gray' | 'purple';
  size?: 'sm' | 'md' | 'lg';
  subText?: React.ReactNode;
  children?: React.ReactNode;
}

// tslint:disable:object-literal-sort-keys
const SIZES: {
  sm: GetComponentProps<typeof Grid>;
  md: GetComponentProps<typeof Grid>;
  lg: GetComponentProps<typeof Grid>;
} = {
  sm: {
    xs: 10,
    sm: 8,
    md: 6,
    lg: 4,
  },
  md: {
    xs: 10,
    sm: 8,
    md: 4,
    lg: 3,
  },
  lg: {
    sm: 12,
    lg: 8,
  },
};
// tslint:enable:object-literal-sort-keys

const Background = ({ color = 'purple', classes, subText, size = 'sm', children, ...props }: IProps) => (
  <div
    className={cx(classes.container, {
      [classes.purple]: color === 'purple',
      [classes.gray]: color === 'gray',
    })}
  >
    <div
      className={cx('app-content-container', {
        [classes.innerContentSm]: size === 'sm',
        [classes.innerContentLg]: size === 'lg',
      })}
    >
      <Grid container justify="center" alignContent="center">
        <Grid item {...SIZES[size]}>
          <div className="mb-120">
            <Grid container justify="center" style={{ marginBottom: '4px' }}>
              <Grid item>
                <Logo color={color === 'purple' ? 'light' : 'dark'} />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item sm={12}>
                <Card {...props}>
                  <CardBody>{children}</CardBody>
                </Card>
              </Grid>
            </Grid>
            {subText && <div className={classes.subText}>{subText}</div>}
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default WithStyles((theme) => ({
  container: {
    minHeight: '100vh',
    overflowY: 'auto',
  },

  gray: {
    backgroundColor: '#fcfcfd',
  },
  purple: {
    backgroundImage: theme.colors.gradients.fullPurple,
  },

  innerContentLg: {
    marginTop: '10vh',
  },
  innerContentSm: {
    marginTop: '20vh',
  },

  subText: {
    '& > a': {
      textDecoration: 'underline',
    },
    color: theme.colors.white,
    fontSize: theme.typography.body1.fontSize,
    marginTop: '20px',
    opacity: 0.7,
    textAlign: 'center',
  },
}))(Background);
