import React, { CSSProperties, useEffect, useRef } from 'react';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import cx from 'classnames';
import * as styles from './AppBar.css';
import { Flex } from '../Flex';
import { ButtonVariantEnum, IconButton } from '../Button/Button';
import { NavMenu } from '../NavMenu';

type RootProps = {
  children: React.ReactNode;
  style?: CSSProperties;
  withNavMenu?: boolean;
} & styles.AppBarVariants;

const navMenuSpacing = {
  width: `calc(100vw - ${NavMenu.NAV_MENU_WIDTH}px)`,
};

export const Root = ({ style = {}, color = 'white', fullWidth = false, children, withNavMenu }: RootProps) => {
  const mergedStyle = withNavMenu ? { ...navMenuSpacing, ...style } : style;
  return (
    <div className={styles.appBarContainer}>
      <div className={styles.appBar({ color, fullWidth })} style={mergedStyle}>
        {children}
      </div>
    </div>
  );
};

type SharedHeaderProps = {
  children?: React.ReactNode;
  navigationBack?: React.ReactNode;
};

type StandardHeaderProps = SharedHeaderProps & {
  heading: string;
  subheader?: React.ReactNode;
};

type ChildrenHeaderProps = SharedHeaderProps & {
  heading: React.ReactNode;
  headingLabel: string;
};

type HeaderProps = StandardHeaderProps | ChildrenHeaderProps;

export const Header = (props: HeaderProps) => {
  const { navigationBack, heading, children } = props;
  const headingLabel = 'headingLabel' in props ? props.headingLabel : '';
  const headerRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (!headerRef.current) return;

    if (headerRef.current.scrollWidth > headerRef.current.clientWidth) {
      headerRef.current.title = typeof heading === 'string' ? heading : headingLabel;
    }
  }, [heading, headingLabel]);

  return (
    <div className={styles.headingContainer}>
      <Flex.Container display="flex" gap="4u">
        {navigationBack}
        <div className={cx(styles.headingAlignment, styles.headingOverflow)}>
          <Flex.Container alignItems="center" className={styles.headingOverflow}>
            <Flex.Container alignItems="baseline" gap="4u" className={styles.headingOverflow}>
              <h1 className={styles.header} ref={headerRef}>
                {heading}
              </h1>

              {'subheader' in props ? <span className={styles.subheader}>{props.subheader}</span> : null}
            </Flex.Container>
          </Flex.Container>
        </div>
      </Flex.Container>
      {children}
    </div>
  );
};

export const Actions = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.appBarControls}>{children}</div>;
};

type BackNavButtonProps = {
  onClick: () => void;
  backLabel: string;
};

export const BackNavButton = ({ onClick, backLabel }: BackNavButtonProps) => (
  <div className={styles.backButtonPlacement}>
    <IconButton variant={ButtonVariantEnum.text} icon={Icons.ChevronLeft} onClick={onClick} label={backLabel} />
  </div>
);
