import React from 'react';
import * as TogglePrimitive from '@radix-ui/react-toggle';
import * as style from './Toggle.css';

const togglePrimitiveEventHandlers = {
  onMouseDown: (e: React.MouseEvent) => e.preventDefault(),
};

export const Toggle = ({ children, ...props }: TogglePrimitive.ToggleProps) => {
  return (
    <TogglePrimitive.Root {...togglePrimitiveEventHandlers} {...props} className={style.toggle}>
      {children}
    </TogglePrimitive.Root>
  );
};
