import * as React from 'react';
import { SVGProps } from 'react';
const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.75 13.25 18 12a4.243 4.243 0 0 0 0-6v0a4.243 4.243 0 0 0-6 0l-1.25 1.25M7.25 10.75 6 12a4.243 4.243 0 0 0 0 6v0a4.243 4.243 0 0 0 6 0l1.25-1.25M14.25 9.75l-4.5 4.5"
    />
  </svg>
);
export default SvgLink;
