import * as React from 'react';
import { SVGProps } from 'react';
const SvgChartIncrease = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.75 16.25c6.389 0 8.25-4.563 8.25-7.75V5.75m0 0-2.25 2.5M16 5.75l2.25 2.5m-13.5-3.5v14.5h14.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgChartIncrease;
