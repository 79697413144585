import React from 'react';
import * as PrimitiveAvatar from '@radix-ui/react-avatar';
import { avatar, avatarFallback, avatarImage } from './Avatar.css';

export type AvatarProps = PrimitiveAvatar.AvatarProps & {
  imageSrc?: string;
  fallback: string;
};

export const Avatar = ({ fallback, imageSrc }: AvatarProps) => {
  const delayMs = imageSrc ? 600 : 0;

  return (
    <PrimitiveAvatar.Root className={avatar}>
      <PrimitiveAvatar.Image className={avatarImage} src={imageSrc} alt={fallback} />
      <PrimitiveAvatar.AvatarFallback className={avatarFallback} delayMs={delayMs}>
        {fallback}
      </PrimitiveAvatar.AvatarFallback>
    </PrimitiveAvatar.Root>
  );
};
