import * as SelectPrimitive from '@radix-ui/react-select';
import React from 'react';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import * as style from './Select.css';
import { SelectInput } from './SelectInput';
import { SelectVariantEnum } from './interfaces';

export type SelectItem = {
  label?: string;
  value: string;
};

export type SelectGroup = {
  label: string;
  items: SelectItem[];
};

export type SelectProps = SelectPrimitive.SelectProps & {
  error?: boolean;
  errorMessage?: string;
  items: SelectItem[] | SelectGroup[];
  label?: string;
  placeholder?: string;
  onClear?: () => void;
  variant?: SelectVariantEnum;
  fullWidth?: boolean;
  menuMinWidth?: CSSStyleDeclaration['minWidth'];
};

export type SelectGroupProps = SelectGroup & {
  last: boolean;
};

export const Select = React.forwardRef(
  (
    {
      error,
      errorMessage,
      items,
      label,
      placeholder,
      onClear,
      fullWidth,
      variant,
      menuMinWidth,
      ...props
    }: SelectProps,
    forwardedRef: React.Ref<HTMLButtonElement>,
  ) => {
    return (
      <SelectPrimitive.Root {...props}>
        <SelectPrimitive.Trigger asChild aria-label={label} ref={forwardedRef}>
          <SelectInput
            label={label}
            errorMessage={errorMessage}
            disabled={props.disabled}
            editable={false}
            error={error}
            onClear={onClear}
            fullWidth={fullWidth}
            variant={variant}
          >
            <SelectPrimitive.Value placeholder={placeholder} />
          </SelectInput>
        </SelectPrimitive.Trigger>
        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            className={style.selectContent}
            position="popper"
            style={{ minWidth: menuMinWidth ?? '146px' }}
          >
            <SelectPrimitive.ScrollUpButton className={style.selectScrollButton}>
              <Icons.ChevronUp />
            </SelectPrimitive.ScrollUpButton>
            <SelectPrimitive.Viewport className={style.selectViewport}>
              {items.map((item, i, { length }) => {
                if ('items' in item) {
                  return <SelectGroup key={item.label} items={item.items} label={item.label} last={length - 1 === i} />;
                }
                return (
                  <SelectItem key={item.value} value={item.value}>
                    {item.label ?? item.value}
                  </SelectItem>
                );
              })}
            </SelectPrimitive.Viewport>
            <SelectPrimitive.ScrollDownButton className={style.selectScrollButton}>
              <Icons.ChevronDown />
            </SelectPrimitive.ScrollDownButton>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    );
  },
);

export const SelectGroup = ({ items, label, last }: SelectGroupProps) => {
  return (
    <SelectPrimitive.Group>
      <SelectPrimitive.Label className={style.selectGroupLabel}>{label}</SelectPrimitive.Label>
      {items.map((item) => (
        <SelectItem key={item.value} value={item.value}>
          {item.label ?? item.value}
        </SelectItem>
      ))}
      {!last && <SelectPrimitive.Separator />}
    </SelectPrimitive.Group>
  );
};

export const SelectItem = React.forwardRef(
  ({ children, ...props }: SelectPrimitive.SelectItemProps, forwardedRef: React.Ref<HTMLDivElement>) => {
    return (
      <SelectPrimitive.Item className={style.selectItem} {...props} ref={forwardedRef}>
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      </SelectPrimitive.Item>
    );
  },
);
