import React from 'react';
import cx from 'classnames';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import * as styles from './Spinner.css';

export type SpinnerProps = {
  size?: keyof typeof styles.spinner;
  className?: string;
};

export const Spinner = ({ className, size = 'medium' }: SpinnerProps) => {
  return <Icons.Eclipse className={cx(styles.spinner[size], className)} />;
};
