import * as React from 'react';
import { SVGProps } from 'react';
const SvgTransposeMatrixNoBackground = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.254 3.345V.332h6.667c.383 0 .703.129.96.385.257.257.386.578.386.961v1.667H6.254ZM2.28 13.665c-.384 0-.704-.128-.961-.385a1.304 1.304 0 0 1-.386-.96V5.651h3.013v8.013H2.28ZM.933 3.345V1.678c0-.383.129-.704.386-.96.257-.257.577-.386.96-.386h1.667v3.013H.933Zm7.084 11.154L5.26 11.742l2.757-2.756.59.59-1.74 1.75h2.977c.575 0 1.067-.204 1.473-.61.407-.407.61-.898.61-1.474V6.255l-1.75 1.75-.59-.59 2.757-2.756L15.1 7.415l-.59.59-1.75-1.75v2.987c0 .81-.283 1.499-.85 2.066a2.813 2.813 0 0 1-2.066.85H6.866l1.74 1.75-.59.59Z"
      fill="#7200B3"
    />
  </svg>
);
export default SvgTransposeMatrixNoBackground;
