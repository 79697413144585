import React, { useRef } from 'react';
import * as style from './TableCell.css';

export type TableCellVariantTypes = NonNullable<style.TableCellVariants>;

export type CellProps = Omit<React.TdHTMLAttributes<HTMLTableCellElement>, 'className'> & style.TableCellVariants;

export const Cell = React.forwardRef<HTMLTableCellElement, CellProps>(
  ({ alignText = 'left', wrapText = 'nowrap', children, ...props }, forwardedRef) => {
    const tableCellRef = useRef<HTMLTableCellElement | null>(null);

    return (
      <td ref={forwardedRef ?? tableCellRef} className={style.tableCell({ alignText, wrapText })} {...props}>
        {children}
      </td>
    );
  },
);
