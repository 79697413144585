import * as React from 'react';
import { SVGProps } from 'react';
const SvgCode = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      width={14.5}
      height={14.5}
      x={4.75}
      y={4.75}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      rx={2}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m8.75 10.75 2.5 2.25-2.5 2.25"
    />
  </svg>
);
export default SvgCode;
