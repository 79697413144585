import React from 'react';
import { Checkbox, Dropdown } from '@mutiny-pkg/dumpster-ui/components';
import { BaseOptionSelected } from './types';

interface Props<Option extends BaseOptionSelected> {
  option: Option;
  onClick: (option: Option) => void;
  optionIcon?: React.ReactNode;
}

export const CheckboxOption = <Option extends BaseOptionSelected>({ option, onClick, optionIcon }: Props<Option>) => {
  if (option.hidden) {
    return null;
  }
  return (
    <Dropdown.Item
      style={{ overflowWrap: 'break-word' }}
      key={option.key}
      onClick={(e) => {
        e.preventDefault();
        onClick(option);
      }}
    >
      <Checkbox noHoverHighlight label={option.displayName} checked={option.selected} onCheckedChange={() => {}}>
        {optionIcon}
      </Checkbox>
    </Dropdown.Item>
  );
};
