import * as React from 'react';
import { SVGProps } from 'react';
const SvgOrderedList = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m4.75 6.25 1.5-1.5v5.5m0 0h-1.5m1.5 0h1M13.25 14.25h-1.594a.899.899 0 0 1-.75-1.393.652.652 0 0 1 .186-.173l1.8-1.198a.68.68 0 0 0 .188-.173c.445-.645-.009-1.563-.827-1.563H10.75M16.75 14.75h1.446c1.235 0 1.454 1.888.314 2.206a.73.73 0 0 1-.17.023l-.59.021.59.02a.725.725 0 0 1 .17.024c1.14.318.92 2.206-.314 2.206H16.75"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgOrderedList;
