import * as React from 'react';
import { SVGProps } from 'react';
const SvgInputField = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.75 7.75h1.5a2 2 0 0 1 2 2v4.5a2 2 0 0 1-2 2h-1.5m-7.5 0h-1.5a2 2 0 0 1-2-2v-4.5a2 2 0 0 1 2-2h1.5M10.75 4.75h2.5M10.75 19.25h2.5M12 5v14"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgInputField;
