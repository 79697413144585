import * as React from 'react';
import { SVGProps } from 'react';
const SvgArrowDownRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17.25 8.75v8.5h-8.5M17 17 6.75 6.75"
    />
  </svg>
);
export default SvgArrowDownRight;
