import React from 'react';
import { TextInput } from '@mutiny-pkg/dumpster-ui/components';

type Props = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
};

export const Search = ({ searchTerm, setSearchTerm }: Props) => {
  return (
    <TextInput
      id="searchTermForfilter"
      onValueChange={(value) => setSearchTerm(value as string)}
      value={searchTerm}
      placeholder="Search"
      fullWidth
      onKeyDown={(event) => event.stopPropagation()}
      type="search"
    />
  );
};
