import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { IconType, Icons } from '@mutiny-pkg/dumpster-ui/icons';
import { useId } from '@mutiny-pkg/dumpster-ui/utils/useId';
import { Label } from '../Label/Label';
import { Message } from '../Message/Message';
import * as styles from './TextInput.css';
import { Spinner } from '../Loaders/Spinner';
import { KeyboardShortcutIndicator } from '../KeyboardShortcutIndicator';
import { Variable, VariableDropdown } from '../Dropdown/VariableDropdown';
import { useInsertVariable } from '../../utils';

type Subtype = 'percent' | undefined;
export type HTMLInputTypeAttribute =
  | 'number'
  | 'search'
  | 'text'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'hidden'
  | 'password'
  | 'inlineAi';
export type TextInputValue = string | ReadonlyArray<string> | number | undefined;

export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  name?: string;
  disabled?: boolean | undefined;
  message?: string;
  error?: boolean;
  errorMessage?: string;
  label?: string;
  labelSize?: 'small' | 'medium' | 'large';
  placeholder?: string | undefined;
  type?: HTMLInputTypeAttribute | undefined;
  subtype?: Subtype;
  value?: TextInputValue;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onValueChange?: (value: TextInputValue) => void;
  onEnter?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  dataTestId?: string;
  required?: boolean;
  fullWidth?: boolean;
  containerClass?: string;
  inputClass?: string;
  autoFocus?: boolean;
  loading?: boolean;
  keyboardShortcut?: string;
  keyboardShortcutHint?: string;
  variables?: Variable[];
  inline?: boolean;
}

export type TextInputIconsProps = {
  children: React.ReactNode;
  startIconColor?: keyof typeof styles.startIconColors;
  type?: HTMLInputTypeAttribute;
  subtype?: Subtype;
  loading?: boolean;
  keyboardShortcut?: string;
  keyboardShortcutHint?: string;
};

export type IconLabelProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
  children: React.ReactNode;
  icon?: IconType;
};

const StartIconLabel = ({ className, children, icon: Icon }: IconLabelProps) => (
  <>
    {Icon && (
      <span className={cx(className, styles.startIconLabel)}>
        <Icon className={styles.iconLabel} />
      </span>
    )}
    {children}
  </>
);

const EndIconLabel = ({ children, icon: Icon }: IconLabelProps) => (
  <>
    {Icon && (
      <span className={styles.endIconLabel}>
        <Icon className={styles.iconLabel} />
      </span>
    )}
    {children}
  </>
);

const TextInputIcons = ({
  type,
  startIconColor: startIconColorProp,
  subtype,
  loading,
  children,
  keyboardShortcut,
  keyboardShortcutHint,
}: TextInputIconsProps) => {
  let startIcon;
  let endIcon: IconType | undefined = useMemo(
    () =>
      keyboardShortcut == null
        ? undefined
        : () => <KeyboardShortcutIndicator hint={keyboardShortcutHint}>{keyboardShortcut}</KeyboardShortcutIndicator>,
    [keyboardShortcut, keyboardShortcutHint],
  );

  let defaultStartIconColor: keyof typeof styles.startIconColors = 'placeholder';
  if (type === 'search') {
    startIcon = Icons.Search;
  } else if (type === 'inlineAi') {
    startIcon = Icons.AiBolt;
    defaultStartIconColor = 'electricPurple';
  }
  const startIconColor = startIconColorProp ?? defaultStartIconColor;

  if (loading) {
    endIcon = Spinner;
  } else if (subtype === 'percent') {
    endIcon = Icons.Percentage;
  }

  return (
    <div className={styles.iconContainer}>
      <StartIconLabel className={styles.startIconColors[startIconColor]} icon={startIcon}>
        <EndIconLabel icon={endIcon}>{children}</EndIconLabel>
      </StartIconLabel>
    </div>
  );
};

export type TextInputRef = Pick<HTMLInputElement, 'focus'>;

export const TextInput = React.forwardRef<TextInputRef, TextInputProps>(
  (
    {
      type,
      subtype,
      label,
      labelSize,
      message,
      disabled,
      error,
      errorMessage,
      onChange,
      onValueChange,
      onEnter,
      required,
      fullWidth = false,
      id: idProp,
      containerClass,
      inputClass,
      autoFocus,
      loading,
      keyboardShortcut,
      keyboardShortcutHint,
      onFocus,
      onBlur,
      variables,
      inline = false,
      ...props
    },
    forwardedRef,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const id = useId(idProp);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onValueChange?.(e.target.value);
      onChange?.(e);
    };

    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus();
      }
    }, [autoFocus]);

    useImperativeHandle(
      forwardedRef,
      () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return inputRef.current!;
      },
      [],
    );

    const insertVariable = useInsertVariable(inputRef, handleChange);

    const [hasFocus, setHasFocus] = useState(false);

    return (
      <div className={cx(styles.containerFullWidth[`${fullWidth}`], containerClass)}>
        {label && (
          <Label size={labelSize} htmlFor={id} disabled={disabled} required={required} fullWidth>
            {label}
          </Label>
        )}
        <TextInputIcons
          type={type}
          subtype={subtype}
          loading={loading}
          // hide this indicator when focussed because the input has a clear button in the same spot when focussed
          keyboardShortcut={hasFocus ? undefined : keyboardShortcut}
          keyboardShortcutHint={keyboardShortcutHint}
        >
          <input
            ref={inputRef}
            id={id}
            key={id}
            className={cx(
              styles.input({
                inline,
                error,
                loading,
                withVariables: (variables?.length ?? 0) > 0,
              }),
              inputClass,
            )}
            type={type}
            data-subtype={subtype}
            disabled={disabled}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && onEnter) {
                e.preventDefault();
                onEnter(e);
              }
            }}
            onFocus={(event) => {
              setHasFocus(true);
              onFocus?.(event);
            }}
            onBlur={(event) => {
              setHasFocus(false);
              onBlur?.(event);
            }}
            {...props}
          />
          {variables && (variables.length ?? 0) > 0 && (
            <div className={styles.variableDropdownContainer}>
              <VariableDropdown variables={variables} onVariableSelected={insertVariable} />
            </div>
          )}
        </TextInputIcons>
        <Message variant="error" active={error && !disabled} disabled={disabled}>
          {errorMessage}
        </Message>
        <Message active={!error && !!message} disabled={disabled}>
          {message}
        </Message>
      </div>
    );
  },
);
