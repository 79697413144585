import React from 'react';
import * as style from '@mutiny-pkg/dumpster-ui/components/Dropdown/ComboBox.css';
import { Flex, Icons, Typography } from '@mutiny-pkg/dumpster-ui/components';
import * as Dropdown from '@mutiny-pkg/dumpster-ui/components/Dropdown/Dropdown';

type Props = {
  option: { label: string; completeness?: number; value: string; showAiBolt?: boolean };
};

export const VariableDropdownItem = React.forwardRef<HTMLDivElement, Props>(({ option }, forwardedRef) => {
  const { label, completeness, value, showAiBolt } = option;
  return (
    <Dropdown.RadioItem
      value={value}
      layout="column"
      ref={forwardedRef}
      colorTheme="default"
      className={style.radioItem}
    >
      <Flex.Container justifyContent="space-between">
        <Flex.Container gap="4u">
          <Typography font="body1" margin="none">
            {label}
          </Typography>
          {showAiBolt && (
            <Typography margin="none" color="electricPurple">
              <Icons.AiBolt />
            </Typography>
          )}
        </Flex.Container>
        <Flex.Container>
          {typeof completeness === 'number' ? (
            <Typography font="body1" margin="none">
              {((completeness ?? 0) * 100).toFixed(0)}%
            </Typography>
          ) : null}
        </Flex.Container>
      </Flex.Container>{' '}
    </Dropdown.RadioItem>
  );
});
