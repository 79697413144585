import * as React from 'react';
import { SVGProps } from 'react';
const SvgArrowUpLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.75 15.25v-8.5h8.5M7 7l10.25 10.25"
    />
  </svg>
);
export default SvgArrowUpLeft;
