import React, { SVGProps } from 'react';

const Clearbit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 72 72"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    {...props}
  >
    <defs>
      <linearGradient id="color-a" x1="50%" x2="100%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#DEF2FE" />
        <stop offset="100%" stopColor="#DBF1FE" />
      </linearGradient>
      <linearGradient id="color-b" x1="0%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#57BCFD" />
        <stop offset="100%" stopColor="#51B5FD" />
      </linearGradient>
      <linearGradient id="color-c" x1="37.5%" x2="62.5%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#1CA7FD" />
        <stop offset="100%" stopColor="#148CFC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#color-a)"
        d="M72,36 L72,52.770861 L71.9958819,53.6375008 C71.9369091,59.6728148 71.2457056,61.9905403 69.9965047,64.3263428 C68.6892015,66.7707872 66.7707872,68.6892015 64.3263428,69.9965047 L64.0001583,70.1671829 C61.6579559,71.3643165 59.1600786,72 52.770861,72 L36,72 L36,36 L72,36 Z"
      />
      <path
        fill="url(#color-b)"
        d="M64.3263428,2.00349528 C66.7707872,3.31079847 68.6892015,5.22921278 69.9965047,7.67365722 L70.1671829,7.99984171 C71.3643165,10.3420441 72,12.8399214 72,19.229139 L72,36 L36,36 L36,0 L52.770861,0 C59.4572515,0 61.8818983,0.696192084 64.3263428,2.00349528 Z"
      />
      <path
        fill="url(#color-c)"
        d="M36,0 L36,72 L19.229139,72 L18.3624992,71.9958819 C12.3271852,71.9369091 10.0094597,71.2457056 7.67365722,69.9965047 C5.22921278,68.6892015 3.31079847,66.7707872 2.00349528,64.3263428 L1.83281705,64.0001583 C0.635683537,61.6579559 0,59.1600786 0,52.770861 L0,19.229139 C0,12.5427485 0.696192084,10.1181017 2.00349528,7.67365722 C3.31079847,5.22921278 5.22921278,3.31079847 7.67365722,2.00349528 L7.99984171,1.83281705 C10.3420441,0.635683537 12.8399214,0 19.229139,0 L36,0 Z"
      />
    </g>
  </svg>
);

export default Clearbit;
