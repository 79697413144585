import React, { SVGProps } from 'react';

const Grab = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 18" fill="none" {...props}>
    <path
      opacity="0.4"
      d="M3.3 16C2.805 16 2.38125 15.8286 2.02875 15.4859C1.67625 15.1432 1.5 14.7312 1.5 14.25C1.5 13.7688 1.67625 13.3568 2.02875 13.0141C2.38125 12.6714 2.805 12.5 3.3 12.5C3.795 12.5 4.21875 12.6714 4.57125 13.0141C4.92375 13.3568 5.1 13.7688 5.1 14.25C5.1 14.7312 4.92375 15.1432 4.57125 15.4859C4.21875 15.8286 3.795 16 3.3 16ZM8.7 16C8.205 16 7.78125 15.8286 7.42875 15.4859C7.07625 15.1432 6.9 14.7312 6.9 14.25C6.9 13.7688 7.07625 13.3568 7.42875 13.0141C7.78125 12.6714 8.205 12.5 8.7 12.5C9.195 12.5 9.61875 12.6714 9.97125 13.0141C10.3238 13.3568 10.5 13.7688 10.5 14.25C10.5 14.7312 10.3238 15.1432 9.97125 15.4859C9.61875 15.8286 9.195 16 8.7 16ZM3.3 10.75C2.805 10.75 2.38125 10.5786 2.02875 10.2359C1.67625 9.89323 1.5 9.48125 1.5 9C1.5 8.51875 1.67625 8.10677 2.02875 7.76406C2.38125 7.42135 2.805 7.25 3.3 7.25C3.795 7.25 4.21875 7.42135 4.57125 7.76406C4.92375 8.10677 5.1 8.51875 5.1 9C5.1 9.48125 4.92375 9.89323 4.57125 10.2359C4.21875 10.5786 3.795 10.75 3.3 10.75ZM8.7 10.75C8.205 10.75 7.78125 10.5786 7.42875 10.2359C7.07625 9.89323 6.9 9.48125 6.9 9C6.9 8.51875 7.07625 8.10677 7.42875 7.76406C7.78125 7.42135 8.205 7.25 8.7 7.25C9.195 7.25 9.61875 7.42135 9.97125 7.76406C10.3238 8.10677 10.5 8.51875 10.5 9C10.5 9.48125 10.3238 9.89323 9.97125 10.2359C9.61875 10.5786 9.195 10.75 8.7 10.75ZM3.3 5.5C2.805 5.5 2.38125 5.32865 2.02875 4.98594C1.67625 4.64323 1.5 4.23125 1.5 3.75C1.5 3.26875 1.67625 2.85677 2.02875 2.51406C2.38125 2.17135 2.805 2 3.3 2C3.795 2 4.21875 2.17135 4.57125 2.51406C4.92375 2.85677 5.1 3.26875 5.1 3.75C5.1 4.23125 4.92375 4.64323 4.57125 4.98594C4.21875 5.32865 3.795 5.5 3.3 5.5ZM8.7 5.5C8.205 5.5 7.78125 5.32865 7.42875 4.98594C7.07625 4.64323 6.9 4.23125 6.9 3.75C6.9 3.26875 7.07625 2.85677 7.42875 2.51406C7.78125 2.17135 8.205 2 8.7 2C9.195 2 9.61875 2.17135 9.97125 2.51406C10.3238 2.85677 10.5 3.26875 10.5 3.75C10.5 4.23125 10.3238 4.64323 9.97125 4.98594C9.61875 5.32865 9.195 5.5 8.7 5.5Z"
      fill="#A195AA"
    />
  </svg>
);

export default Grab;
