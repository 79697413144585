import React, { useState, useMemo } from 'react';
import { Button, Collapsible, SourceIcon, Flex, Spacing, Typography } from '../../index';
import * as styles from './VariableDropdown.css';
import * as Dropdown from '../Dropdown';
import * as style from '../ComboBox.css';
import { useSearchArrowNavigation, useSearching } from '../ComboBox';
import { InputVariables, Variable, GroupedVariable, Group } from './types';
import { AttributesHeader } from './AttributesHeader';
import { SearchOptions } from './SearchOptions';
import { VariableToggleGroup } from './VariableToggleGroup';
import { groupListVariables, formatToGroupedOptions } from './groupingHelpers';
import { VariableDropdownItem } from './VariableDropdownItem';

type UseVariablesIn = { inputVariables: InputVariables; selectedGroup: string };

const isGroupedVariable = (input: InputVariables): input is GroupedVariable[] => {
  return !!input[0] && 'variables' in input[0];
};

const selectVariablesFromGroup = ({
  inputVariables,
  selectedGroup,
}: UseVariablesIn): { scopedVariables: Variable[]; groups: Group[] } => {
  if (isGroupedVariable(inputVariables)) {
    const scopedGroup = inputVariables.find((fg: GroupedVariable) => fg.value === selectedGroup);
    return {
      scopedVariables: scopedGroup?.variables ?? [],
      groups: inputVariables.map(({ name, value }) => ({ name, value })),
    };
  }
  return { scopedVariables: inputVariables, groups: [] };
};

const useVariables = ({
  inputVariables,
  selectedGroup,
}: UseVariablesIn): { scopedVariables: Variable[]; groups: Group[] } =>
  useMemo(() => selectVariablesFromGroup({ inputVariables, selectedGroup }), [inputVariables, selectedGroup]);

type BaseVariableDropdownProps = {
  variables: InputVariables;
  onVariableSelected: (value: string) => void;
  children?: React.ReactNode;
  disabled?: boolean;
};

const BaseVariableDropdown = ({
  variables: inputVariables,
  onVariableSelected,
  children,
  disabled,
}: BaseVariableDropdownProps) => {
  const firstGroup = inputVariables[0] && 'variables' in inputVariables[0] ? inputVariables[0].value : '';
  const [selectedGroup, setSelectedGroup] = useState<string>(firstGroup);
  const { scopedVariables, groups } = useVariables({ inputVariables, selectedGroup });
  const { availableOptions, numOfCollapsibleSectionsWithoutSearch } = React.useMemo(() => {
    const options = scopedVariables.map((variable) => ({
      ...variable,
      label: variable.name,
    }));
    return {
      availableOptions: options,
      numOfCollapsibleSectionsWithoutSearch: formatToGroupedOptions(options).groupedOptions.length,
    };
  }, [scopedVariables]);
  const { clearState, displayedOptions, handleSearch, searchTerm } = useSearching<typeof availableOptions[number]>({
    options: availableOptions,
  });

  const collapsibleSectionsToRender = React.useMemo(() => {
    if (numOfCollapsibleSectionsWithoutSearch > 1) {
      return formatToGroupedOptions(displayedOptions).groupedOptions;
    }
    return [];
  }, [displayedOptions, groups, numOfCollapsibleSectionsWithoutSearch]);
  const standloneVariablesToRender = React.useMemo(() => {
    return displayedOptions.filter((option) => option.showOutsideCollapsibleSection);
  }, [displayedOptions]);

  const { firstItemRef, lastItemRef, onSearchArrowNavigation } = useSearchArrowNavigation();
  return (
    <Dropdown.Root onOpenChange={clearState}>
      <div className={style.inputContainer}>
        <Dropdown.Trigger asChild disabled={disabled}>
          {children ?? (
            <Button className={styles.trigger} variant="text" size="minimal">
              Insert a variable
            </Button>
          )}
        </Dropdown.Trigger>
      </div>
      <Dropdown.Content
        className={styles.container}
        onCloseAutoFocus={(e) => e.preventDefault()}
        align="start"
        side="bottom"
        avoidCollisions={false}
        collisionPadding={40}
        matchTriggerWidth
      >
        <VariableToggleGroup groups={groups} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} />
        <SearchOptions
          displayedOptions={displayedOptions}
          handleSearch={handleSearch}
          onSearchArrowNavigation={onSearchArrowNavigation}
        />
        {displayedOptions.length > 0 ? <AttributesHeader scopedVariables={scopedVariables} /> : null}
        <Dropdown.RadioGroup onValueChange={onVariableSelected}>
          {numOfCollapsibleSectionsWithoutSearch <= 1 &&
            displayedOptions.map((option, idx) => {
              let itemRef;
              if (idx === 0) {
                itemRef = firstItemRef;
              } else if (idx === displayedOptions.length - 1) {
                itemRef = lastItemRef;
              }
              return <VariableDropdownItem key={`${option.value}-${idx}`} option={option} ref={itemRef} />;
            })}
          {numOfCollapsibleSectionsWithoutSearch > 1 && (
            <>
              {standloneVariablesToRender &&
                standloneVariablesToRender.map((variable) => (
                  <VariableDropdownItem key={variable.value} option={variable} />
                ))}
              {collapsibleSectionsToRender.map((group) => {
                const optionSources = group.options.find(
                  (option) => option.valueSources && option.valueSources.length > 0,
                );
                const key = `${selectedGroup ?? '-'}-${group.label}`;
                return (
                  <Collapsible.Root key={key} open={searchTerm ? true : undefined}>
                    <Spacing paddingY="4u" paddingX="8u">
                      <Collapsible.Trigger iconPosition="right" fullWidth>
                        <Flex.Container gap="4u" alignItems="center">
                          <SourceIcon
                            sources={optionSources?.valueSources ?? ['defaultSourceIcon']}
                            displayName={group.displayName}
                          />
                          <Typography font="body1" margin="none" fontWeight="medium">
                            {group.label}
                          </Typography>
                        </Flex.Container>
                      </Collapsible.Trigger>
                    </Spacing>
                    <Collapsible.Content>
                      {group.options.map((option, idx) => (
                        <VariableDropdownItem key={`${option.value}-${idx}`} option={option} />
                      ))}
                    </Collapsible.Content>
                  </Collapsible.Root>
                );
              })}
            </>
          )}
        </Dropdown.RadioGroup>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

const useVariableGrouping = (variables: Variable[]) => useMemo(() => groupListVariables(variables), [variables]);

type VariableDropdownProps = {
  variables: Variable[];
  onVariableSelected: BaseVariableDropdownProps['onVariableSelected'];
  children?: React.ReactNode;
  disabled?: boolean;
};

export const VariableDropdown = (props: VariableDropdownProps) => {
  const variables = useVariableGrouping(props.variables);
  return (
    <BaseVariableDropdown variables={variables} disabled={props.disabled} onVariableSelected={props.onVariableSelected}>
      {props.children}
    </BaseVariableDropdown>
  );
};
