import React from 'react';
import { IconType, Icons } from '@mutiny-pkg/dumpster-ui/icons';
import { IconButton } from '../Button/Button';
import { Link, LinkComponentProps } from '../Link/Link';
import * as style from './FeedbackBlock.css';

export type FeedbackBlockTypes = NonNullable<style.FeedbackBlockVariants>;

type FeedbackBlockProps = style.FeedbackBlockVariants &
  React.HTMLAttributes<HTMLElement> & {
    hideClose?: boolean;
    hideIcon?: boolean;
    onClose?: () => void;
  };

type HeaderProps = {
  children: React.ReactText;
};

type GenericContainerComponentProps = {
  children: React.ReactNode;
};

type IconProps = React.PropsWithChildren<
  { type: Pick<style.FeedbackBlockVariants, 'type'>['type'] } & React.SVGAttributes<SVGElement>
>;

export const Header = ({ children }: HeaderProps) => {
  return <h2 className={style.feedbackBlockHeader}>{children}</h2>;
};

export const Body = ({ children }: GenericContainerComponentProps) => {
  return <div className={style.feedBackBlockDescription}>{children}</div>;
};

export const Content = ({ children }: GenericContainerComponentProps) => (
  <div className={style.contentSectionBase}>{children}</div>
);

export const ActionsSection = ({ children }: GenericContainerComponentProps) => (
  <div className={style.feedbackActionsSection}>{children}</div>
);

export const ActionLink = ({ children, ...props }: LinkComponentProps) => (
  <Link variant="primary" size="large" {...props}>
    {children}
  </Link>
);

const FeedbackBlockIcon = ({ type, ...props }: IconProps) => {
  const iconMap: Record<typeof type, IconType> = {
    attention: Icons.WarningTriangle,
    error: Icons.Alert,
    confirmation: Icons.Checkmark,
    info: Icons.Info,
  };

  const Icon = iconMap[type];
  return <Icon aria-label={type} {...props} />;
};

export const Root = ({
  variant,
  type,
  hideClose = false,
  hideIcon = false,
  fullWidth = false,
  onClose,
  children,
  ...props
}: FeedbackBlockProps) => {
  return (
    <div
      className={style.feedbackBlock({
        variant,
        type,
        fullWidth,
      })}
      {...props}
    >
      {!hideIcon && (
        <div className={style.indicatorIconSection}>
          <FeedbackBlockIcon type={type} />
        </div>
      )}
      <div className={style.contentSection({ variant })}>{children}</div>

      {!hideClose && (
        <div className={style.closeIconSection}>
          <span>
            <IconButton
              variant="close"
              size="minimal"
              icon={Icons.Close}
              label="close"
              aria-label="close"
              onClick={onClose}
            />
          </span>
        </div>
      )}
    </div>
  );
};
