import React, { Ref } from 'react';
import * as PrimitiveAccordion from '@radix-ui/react-accordion';
import * as styles from './Accordion.css';
import { Icons } from '../../icons';

export type AccordionProps = (PrimitiveAccordion.AccordionSingleProps | PrimitiveAccordion.AccordionMultipleProps) &
  React.RefAttributes<HTMLDivElement>;

export const Root = ({ children, ...props }: AccordionProps) => {
  return (
    <PrimitiveAccordion.Root className={styles.root} {...props}>
      {children}
    </PrimitiveAccordion.Root>
  );
};

export type TriggerProps = PrimitiveAccordion.AccordionTriggerProps & styles.TriggerVariants;

export const TriggerIcon = () => <Icons.ChevronDown className={styles.triggerIcon} />;

export const Trigger = React.forwardRef(
  ({ children, iconPosition = 'left', disabled }: TriggerProps, forwardedRef: Ref<HTMLButtonElement>) => {
    return (
      <PrimitiveAccordion.Header className={styles.header}>
        <PrimitiveAccordion.Trigger className={styles.trigger({ iconPosition })} ref={forwardedRef} disabled={disabled}>
          {iconPosition === 'right' && children}
          {iconPosition !== 'none' ? <TriggerIcon /> : children}
          {iconPosition === 'left' && children}
        </PrimitiveAccordion.Trigger>
      </PrimitiveAccordion.Header>
    );
  },
);

export const Content = React.forwardRef(
  ({ children }: PrimitiveAccordion.AccordionContentProps, forwardedRef: Ref<HTMLDivElement>) => {
    return (
      <PrimitiveAccordion.Content className={styles.content} ref={forwardedRef}>
        <div className={styles.innerContent}>{children}</div>
      </PrimitiveAccordion.Content>
    );
  },
);

export const Item = ({ children, value }: PrimitiveAccordion.AccordionItemProps) => {
  return (
    <PrimitiveAccordion.Item className={styles.item} value={value}>
      {children}
    </PrimitiveAccordion.Item>
  );
};
