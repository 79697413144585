import React, { SVGProps } from 'react';

const EmojiNeutral = (props: SVGProps<SVGSVGElement>) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M29.1666 15.8333H50.8333C58.1971 15.8333 64.1666 21.8029 64.1666 29.1667V50.8333C64.1666 58.1971 58.1971 64.1667 50.8333 64.1667H29.1666C21.8028 64.1667 15.8333 58.1971 15.8333 50.8333V29.1667C15.8333 21.8029 21.8029 15.8333 29.1666 15.8333Z"
      stroke="#CDC7D0"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.3333 33.3333C48.3333 34.2538 47.5871 35 46.6667 35C45.7462 35 45 34.2538 45 33.3333C45 32.4129 45.7462 31.6667 46.6667 31.6667C47.5871 31.6667 48.3333 32.4129 48.3333 33.3333Z"
      stroke="#CDC7D0"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35 33.3333C35 34.2538 34.2538 35 33.3334 35C32.4129 35 31.6667 34.2538 31.6667 33.3333C31.6667 32.4129 32.4129 31.6667 33.3334 31.6667C34.2538 31.6667 35 32.4129 35 33.3333Z"
      stroke="#CDC7D0"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M29.1667 47.5H50.8334" stroke="#CDC7D0" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default EmojiNeutral;
