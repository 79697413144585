import React, { SVGProps } from 'react';

const Segment = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <path
      fill="#50B68C"
      fillRule="evenodd"
      d="M6.809 23.78c-.893 0-1.616.709-1.616 1.584 0 .876.723 1.586 1.616 1.586.891 0 1.615-.71 1.615-1.586 0-.875-.724-1.585-1.615-1.585zM23.003 6.208c0 .875.723 1.585 1.615 1.585s1.615-.71 1.615-1.585c0-.873-.719-1.582-1.609-1.585-.898 0-1.62.71-1.62 1.585zm-10.075 6.765c0 .292.24.529.538.529h15.998a.533.533 0 0 0 .532-.529v-1.938a.533.533 0 0 0-.538-.528H13.46a.533.533 0 0 0-.536.528l.004 1.938zm6.153 7.023V18.06a.534.534 0 0 0-.539-.528H2.545a.534.534 0 0 0-.545.522v1.942c0 .291.241.528.538.528h15.998a.534.534 0 0 0 .545-.522v-.006zm10.807-2.6a.535.535 0 0 0-.366-.2l-1.966-.195a.533.533 0 0 0-.587.456c-.83 6.024-6.478 10.248-12.617 9.435-.85-.113-1.684-.32-2.486-.62a.535.535 0 0 0-.686.302l-.76 1.79a.522.522 0 0 0 .308.698c7.352 2.775 15.604-.823 18.433-8.037.41-1.045.69-2.135.835-3.245a.512.512 0 0 0-.108-.384zM2.052 12.515C3.673 6.33 9.354 2.004 15.862 2a14.46 14.46 0 0 1 4.686.773c.285.1.432.409.33.688l-.7 1.812a.538.538 0 0 1-.676.302c-1.17-.398-2.4-.602-3.64-.604A11.206 11.206 0 0 0 8.83 7.434a11.046 11.046 0 0 0-3.809 5.779.53.53 0 0 1-.64.38l-1.926-.422a.532.532 0 0 1-.338-.248.514.514 0 0 1-.065-.408z"
    />
  </svg>
);

export default Segment;
