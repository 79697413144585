// tslint:disable object-literal-sort-keys

import { createTheme, Theme } from '@material-ui/core/styles';
import '@mutiny-pkg/dumpster-ui/theme/fonts.css';

export interface IMutinyTheme extends Theme {
  colors: {
    primary: string;
    primaryDark: string;
    primaryLight: string;

    info: string;
    infoDark: string;

    action: string;
    actionDark: string;
    actionLight: string;

    success: string;
    successLight: string;

    error: string;
    errorLight: string;

    black: string;

    blueLight: string;

    grayAlphaSuperLight: string;
    grayAlphaLight: string;
    grayAlphaMedium: string;
    grayAlphaDark: string;

    grayDark: string;
    grayMedium: string;
    grayLight: string;
    grayLightWarm: string;

    white: string;

    green: string;

    orange: string;
    redOrange: string;

    frostBlue: string;
    electricPurple: string;
    darkPurple: string;
    lilac: string;

    componentOutline: string;
    secondaryButtonHoverBackground: string;
    secondaryButtonPressedBackground: string;
    purpleSurface: string;
    warning: string;

    gradients: {
      blue: string;
      purple: string;
      fullPurple: string;
      sunset: string;
    };
  };
}

const theme: IMutinyTheme = {
  ...createTheme({
    palette: {
      error: {
        main: '#d62f79',
      },
      primary: {
        contrastText: '#ffffff',
        dark: '#006498',
        light: '#028bd2',
        main: '#00a7fe',
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    typography: {
      h1: {
        fontSize: '1.5rem',
        fontWeight: 600,
      },
      h2: {
        fontSize: '1.25rem',
        fontWeight: 600,
      },
      h3: {
        fontSize: '1rem',
        fontWeight: 600,
      },
      h4: {
        fontSize: '0.875rem',
        fontWeight: 600,
      },
      h5: {
        fontSize: '0.875rem',
        fontWeight: 500,
      },
      body1: {
        fontSize: '0.875rem',
      },
      caption: {
        fontSize: '0.675',
      },
      button: {
        fontSize: '0.875rem',
        textTransform: 'none',
      },
      fontFamily: 'proximanova-regular',
    },
  }),
  colors: {
    primary: '#9611df',
    primaryDark: '#5c188a',
    primaryLight: '#aa00ff',

    info: '#ffb239',
    infoDark: '#de730b',

    action: '#00a7fe',
    actionDark: '#0089d0',
    actionLight: '#e6f6ff',

    successLight: '#e3fbdf',
    success: '#32c41e',

    errorLight: '#ffe4f0',
    error: '#d62f79',

    black: '#221436',
    white: '#ffffff',

    grayAlphaSuperLight: 'rgba(218, 220, 227, 0.3)',
    grayAlphaLight: 'rgba(42, 47, 58, 0.2)',
    grayAlphaMedium: 'rgba(42, 47, 58, 0.5)',
    grayAlphaDark: 'rgba(42, 47, 58, 0.72)',

    grayDark: '#dadce3',
    grayMedium: '#eff1f7',
    grayLight: '#fbfbfc',
    grayLightWarm: '#f4f4ee',

    blueLight: '#f2faff',

    green: '#1e9f0d',
    orange: '#ed7005',
    redOrange: '#f05421',
    frostBlue: '#cff6ff',
    electricPurple: '#960fdf',
    darkPurple: '#3a0068',
    lilac: '#f5e1ff',
    warning: 'FFF5DB',

    componentOutline: 'rgba(0, 0, 0, 0.15)',
    secondaryButtonHoverBackground: '#F6F6F6',
    secondaryButtonPressedBackground: '#EBEBEB',
    purpleSurface: '#EAE5F6',

    gradients: {
      blue: 'linear-gradient(225deg, #00cffe, #007efd 99%)',
      purple: 'linear-gradient(190deg, #3a0068, #9b1dd8)',
      fullPurple: 'linear-gradient(185deg, #3a0068, #9b1dd8 100%)',
      sunset: 'linear-gradient(88.39deg, #FF874F 1.37%, #EB347B 98.63%)',
    },
  },
};

export const SIDEBAR_WIDTH_PX = 175;

export default theme;
