import * as React from 'react';
import { SVGProps } from 'react';
const SvgCursor = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.75 5.75 11 18.25 13 13l5.25-2-12.5-5.25ZM13 13l5.25 5.25"
    />
  </svg>
);
export default SvgCursor;
