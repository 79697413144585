import * as React from 'react';
import { SVGProps } from 'react';
const SvgDollar = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle
      cx={12}
      cy={12}
      r={7.25}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.25 8.75h-2.875c-.898 0-1.625.728-1.625 1.625v0c0 .898.727 1.625 1.625 1.625h1.25c.898 0 1.625.727 1.625 1.625v0c0 .898-.727 1.625-1.625 1.625H9.75M12 7.75v.5M12 15.75v.5"
    />
  </svg>
);
export default SvgDollar;
