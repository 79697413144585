import * as React from 'react';
import { SVGProps } from 'react';
const SvgItalic = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 4.75h-2.25m2.25 0h2.25m-2.25 0-4 14.5m0 0H7.75m2.25 0h2.25"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgItalic;
