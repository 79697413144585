import React from 'react';
import cx from 'classnames';
import { Slot } from '@radix-ui/react-slot';
import { useNavClickCallback } from '@mutiny-pkg/dumpster-ui/utils/hooks';
import * as style from './TableRow.css';

export interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  asChild?: boolean;
  hover?: boolean;
  selected?: boolean;
  spotlight?: boolean;
  href?: string;
  animateIn?: boolean;
}

export const Row = React.forwardRef<HTMLTableRowElement, RowProps>(
  ({ asChild, children, ...props }: RowProps, forwardedRef) => {
    const onClickHandler = useNavClickCallback(props.href, props.onClick);
    const resolvedClasses = cx(style.row, props.className, {
      [`${style.rowVariants.spotlight}`]: props.spotlight,
      [`${style.rowVariants.hover}`]: props.hover && !props.selected,
      [`${style.rowVariants.selected}`]: props.selected,
      [`${style.rowVariants.clickable}`]: !!onClickHandler || !!props.onMouseDown,
      [`${style.rowVariants.animateIn}`]: props.animateIn,
    });

    if (asChild) {
      const RowComponent = Slot;
      return (
        <RowComponent {...props} className={resolvedClasses} onClick={onClickHandler}>
          {children}
        </RowComponent>
      );
    }

    return (
      <tr {...props} className={resolvedClasses} ref={forwardedRef} onClick={onClickHandler}>
        {children}
      </tr>
    );
  },
);
