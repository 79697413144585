import React, { SVGProps } from 'react';

const Merged = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
    <rect fill="#1e0036" width="14" height="14" />
    <g transform="translate(2,2) scale(0.71)">
      <path
        d="M7.14575 2.77051H3.93742C3.29309 2.77051 2.77075 3.29284 2.77075 3.93717V7.14551C2.77075 7.78984 3.29309 8.31217 3.93742 8.31217H5.68742V10.0622C5.68742 10.7065 6.20975 11.2288 6.85409 11.2288H10.0624C10.7068 11.2288 11.2291 10.7065 11.2291 10.0622V6.85384C11.2291 6.20951 10.7068 5.68717 10.0624 5.68717H8.31242V3.93717C8.31242 3.29284 7.79008 2.77051 7.14575 2.77051Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Merged;
