import * as React from 'react';
import { SVGProps } from 'react';
const SvgColorSwatch = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.5 16a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.75 6.75a2 2 0 0 1 2-2h4.5a2 2 0 0 1 2 2v10.5a2 2 0 0 1-2 2h-4.5a2 2 0 0 1-2-2V6.75ZM15.75 15.25l2.93-2.992a2 2 0 0 0 .028-2.77l-3.08-3.273c-.755-.803-2.438-.415-2.438-.415"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgColorSwatch;
