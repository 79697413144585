import * as React from 'react';
import { SVGProps } from 'react';
const SvgPause = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15.25 6.75v10.5M8.75 6.75v10.5"
    />
  </svg>
);
export default SvgPause;
