import cx from 'classnames';
import * as React from 'react';

import { InputAdornment, TextField } from '@material-ui/core';
import { Search as MuiSearch, Clear } from '@material-ui/icons';
import {
  TextInput as DumpsterText,
  TextInputProps as DumpsterTextProps,
  HTMLInputTypeAttribute,
  TextInputValue,
} from '@mutiny-pkg/dumpster-ui';
import { useRedesign } from '@mutiny-pkg/dumpster-ui/contexts/RedesignContext';
import Button from '../Button';
import Search from '../../icons/Search';

import WithStyles, { IStyleProps } from '../../higher_order/WithStyles';

export type TextColor = 'secondary' | 'gray' | 'purple' | 'green' | 'blue';

interface ITextProps {
  color?: TextColor;
  error?: string | null;
  helperText?: string;
  helperTextProps?: string;
  /** @deprecated use button and text combo instead */
  inlineSave?: boolean;
  isClearable?: boolean;
  onEnter?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  saving?: boolean;
  setValue?: (value: any) => void;
  size?: 'small' | 'medium';
  StartAdornmentIcon?: React.ComponentType;
  EndAdornmentIcon?: React.ComponentType;
  inputRootClassName?: string;
  dataTestId?: string;
}

export type IProps = ITextProps &
  Omit<GetComponentProps<typeof TextField>, 'error' | 'color'> & {
    label?: DumpsterTextProps['label'];
    type?: DumpsterTextProps['type'];
    subtype?: DumpsterTextProps['subtype'];
    value?: DumpsterTextProps['value'] | boolean;
    containerClass?: DumpsterTextProps['containerClass'];
    inputClass?: DumpsterTextProps['inputClass'];
    defaultValue?: DumpsterTextProps['defaultValue'];
  };

const InnerText = (props: IProps & IStyleProps) => {
  const {
    color,
    className,
    inlineSave,
    isClearable,
    saving,
    size = 'medium',
    dataTestId,
    classes: {
      adornedEnd,
      adornedStart,
      clearableButton,
      endAdornment: endAdornmentClass,
      focused,
      focusedSecondary,
      focusedGray,
      focusedGreen,
      focusedPurple,
      formControl,
      input,
      inputGray,
      inputGreen,
      inputPurple,
      inputAdornedStart,
      inputRoot,
      inputRootSecondary,
      inputRootGray,
      inputRootGreen,
      inputRootPurple,
      large,
      medium,
      multiline,
      noLeftPad,
      small,
      startAdornment: startAdornmentClass,
      ...classes
    },
    error,
    helperText,
    helperTextProps,
    onEnter,
    margin = 'normal',
    multiline: isMultiline,
    setValue,
    EndAdornmentIcon,
    StartAdornmentIcon,
    InputProps,
    InputLabelProps,
    inputRootClassName,
    ...restProps
  } = props;

  const endAdornment =
    inlineSave || saving ? (
      <InputAdornment position="end">
        <Button color="transparent" type="submit" className="link-text" loading={saving} disabled={saving}>
          Save
        </Button>
      </InputAdornment>
    ) : isClearable ? (
      <InputAdornment position="end">
        <Button
          className={clearableButton}
          color="transparent"
          iconOnly
          Icon={Clear}
          onClick={(ev: React.MouseEvent) => {
            if (setValue) {
              ev.preventDefault();
              setValue('');
            }
          }}
        />
      </InputAdornment>
    ) : (
      EndAdornmentIcon && (
        <InputAdornment className={endAdornmentClass} position="end">
          <EndAdornmentIcon />
        </InputAdornment>
      )
    );
  const dataProps = {
    'data-test-id': dataTestId,
  } as any; // we don't allow `@_ts-ignore`s and MaterialUI doesn't support dataset attrs
  return (
    <TextField
      margin={margin}
      InputProps={{
        classes: {
          adornedEnd,
          adornedStart,
          focused: cx(focused, {
            [focusedSecondary]: color === 'secondary',
            [focusedGray]: color === 'gray',
            [focusedGreen]: color === 'green',
            [focusedPurple]: color === 'purple',
          }),
          input: cx(input, {
            [inputGray]: color === 'gray',
            [inputGreen]: color === 'green',
            [inputPurple]: color === 'purple',
          }),
          inputAdornedStart,
          multiline,
          root: cx(
            inputRoot,
            {
              disabled: restProps.disabled,
              [inputRootSecondary]: color === 'secondary',
              [inputRootGray]: color === 'gray',
              [inputRootGreen]: color === 'green',
              [inputRootPurple]: color === 'purple',
            },
            inputRootClassName,
          ),
        },
        endAdornment,
        notched: false,
        onKeyPress:
          onEnter &&
          ((ev: any) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              onEnter(ev);
            }
          }),
        startAdornment: StartAdornmentIcon && (
          <InputAdornment className={startAdornmentClass} position="start">
            <StartAdornmentIcon />
          </InputAdornment>
        ),
        ...dataProps,
        ...(InputProps || {}),
      }}
      InputLabelProps={{
        classes: { formControl },
        shrink: true,
        ...(InputLabelProps || {}),
      }}
      FormHelperTextProps={{
        className: helperTextProps,
      }}
      className={cx(className, {
        [small]: size === 'small',
        [medium]: size === 'medium',
        [noLeftPad]: !!StartAdornmentIcon,
      })}
      classes={classes}
      multiline={isMultiline}
      {...restProps}
      variant="outlined"
      error={!!error}
      helperText={error || helperText}
    />
  );
};

const StyleText = WithStyles((theme) => ({
  adornedEnd: {
    paddingRight: '11px',
  },
  adornedStart: {
    paddingLeft: '11px',
  },
  clearableButton: {
    marginRight: '-10px',
    paddingRight: '0px',
  },
  endAdornment: {
    color: theme.colors.grayAlphaMedium,
    fontSize: theme.typography.body1.fontSize,
  },
  formControl: {
    border: 'none',
    color: `${theme.colors.black} !important`,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',
    left: '-10px',
    letterSpacing: '0.4px',
    marginTop: '8px',
    position: 'relative',
    textTransform: 'uppercase',
  },
  input: {
    '&::placeholder': {
      color: theme.colors.grayAlphaMedium,
      fontStyle: 'italic',
      opacity: '1',
    },
    '&:disabled': {
      color: theme.colors.grayAlphaDark,
      cursor: 'not-allowed',
    },
    color: theme.colors.black,
    fontSize: '13px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: 'auto',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    zIndex: 1,
  },
  inputAdornedStart: {
    paddingLeft: '8px !important',
  },
  large: {
    '& input': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  },
  medium: {
    '& input': {
      padding: '10.5px 14px',
    },
  },
  multiline: {
    padding: '12px !important',
    lineHeight: '20px',
  },
  noLeftPad: {
    '& input': {
      paddingLeft: '0px',
    },
  },
  root: {
    '& .disabled': {
      backgroundColor: theme.colors.grayMedium,
      cursor: 'not-allowed',
    },
  },
  small: {
    '& input': {
      padding: '7px 10px',
    },
  },
  startAdornment: {
    marginRight: '0px',
  },

  // Base Styles
  focused: {
    // https://github.com/mui-org/material-ui/issues/12192
    '&&&& fieldset': {
      border: `1px solid ${theme.colors.electricPurple} !important`,
      borderRadius: '6px',
      boxShadow: '0 0 2px 0 rgba(47,157,214,0.26), inset 0 1px 2px 0 rgba(0,0,0,0.05) !important',
    },
  },
  inputRoot: {
    '& fieldset': {
      border: `1px solid ${theme.colors.grayAlphaLight}`,
      borderColor: `${theme.colors.grayAlphaLight} !important`,
      borderRadius: '6px',
      boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.05)',
      transition: 'all 0.1s ease-in-out',
      zIndex: 2,
    },
    '& svg': {
      zIndex: 3,
    },
    '&:hover fieldset': {
      borderColor: `${theme.colors.grayAlphaLight} !important`,
    },
    backgroundColor: theme.colors.white,
    borderRadius: '6px',
    color: `${theme.colors.black} !important`,
    fontFamily: 'inherit',
    width: 'auto',
    zIndex: 1,
  },

  // Secondary Color Styles
  focusedSecondary: {
    // https://github.com/mui-org/material-ui/issues/12192
    '&&&& fieldset': {
      backgroundColor: '#e2e5ee',
      border: 'none !important',
    },
  },
  inputRootSecondary: {
    '& fieldset': {
      backgroundColor: theme.colors.grayMedium,
      border: 'none',
    },
    '&:hover fieldset': {
      backgroundColor: '#e2e5ee',
    },
  },
}))(InnerText) as React.ComponentType<IProps>; // TODO: why do i have to do this?

export const parseValue = (value: IProps['value']): TextInputValue => {
  if (Array.isArray(value)) {
    return value.map(String);
  }
  if (typeof value === 'boolean') {
    return value.toString();
  }
  return value;
};

const TextWrapper = (props: IProps) => {
  const { redesignEnabled } = useRedesign();

  if (redesignEnabled) {
    let type;
    const shouldBeSearchType = props.StartAdornmentIcon === MuiSearch || props.StartAdornmentIcon === Search;
    if (props.type === undefined && shouldBeSearchType) {
      type = 'search';
    } else {
      type = props.type;
    }

    return (
      <DumpsterText
        id={props.id}
        name={props.name}
        disabled={props.disabled}
        label={props.label}
        error={!!props.error}
        errorMessage={props.error ?? undefined}
        placeholder={props.placeholder}
        type={type as HTMLInputTypeAttribute}
        subtype={props.subtype}
        onChange={props.onChange}
        onEnter={props.onEnter}
        value={parseValue(props.value)}
        defaultValue={props.defaultValue}
        dataTestId={props.dataTestId}
        fullWidth={props.fullWidth}
        message={props.helperText}
        containerClass={props.containerClass}
        inputClass={props.inputClass}
        onBlur={props.onBlur}
        autoFocus={props.autoFocus}
        loading={props.saving}
        onMouseDown={props.onMouseDown}
      />
    );
  }
  return <StyleText {...props} />;
};

export const LegacyText = (props: IProps) => {
  const { redesignEnabled } = useRedesign();

  if (redesignEnabled) {
    return (
      <StyleText
        {...props}
        InputProps={{
          ...props.InputProps,
          style: {
            ...props.InputProps?.style,
            height: props.multiline ? 'auto' : '36px',
          },
        }}
      />
    );
  }

  return <StyleText {...props} />;
};

export default TextWrapper;
