import { sourceToDisplayName } from '@mutiny-pkg/dumpster-ui/components/SourceIcon';
import { GroupedVariable, Variable } from './types';

export type KeySourcesMap = Map<string, string[]>;
export const formatToKeySourcesMap = <Attribute extends Variable>(attributes: Attribute[]) => {
  return attributes.reduce(
    (acc, option) => acc.set(option.value, option.valueSources ?? []),
    new Map<string, string[]>(),
  );
};
const MULTIPLE_SOURCES_LABEL = 'Multiple Sources';
const OTHER_LABEL = 'Other';

interface GroupedOption<Attribute extends Variable> {
  label: string;
  displayName?: string;
  options: Attribute[];
}

export const formatToGroupedOptions = <Attribute extends Variable>(
  attributes: Attribute[],
): {
  keySourcesMap: KeySourcesMap;
  groupedOptions: GroupedOption<Attribute>[];
} => {
  const filteredAttributes = attributes.filter((attribute) => !attribute.showOutsideCollapsibleSection);
  const keySourcesMap = formatToKeySourcesMap<Attribute>(filteredAttributes);
  const sortedOptions = filteredAttributes.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (b.name > a.name) {
      return -1;
    }
    return 0;
  });
  const labelToOptions = sortedOptions.reduce<Record<string, typeof sortedOptions>>((acc, el) => {
    const sources = keySourcesMap.get(el.value);
    if (!sources || sources.length === 0) {
      if (!acc[OTHER_LABEL]) {
        acc[OTHER_LABEL] = [];
      }
      acc[OTHER_LABEL].push(el);
      return acc;
    }
    const label = sources.length > 1 ? MULTIPLE_SOURCES_LABEL : sourceToDisplayName(sources.at(0));
    if (!acc[label]) {
      acc[label] = [];
    }
    acc[label]!.push(el); // ! because it was set two lines above
    return acc;
  }, {});

  const groupedOptions = Object.entries(labelToOptions)
    .map(([label, options]) => {
      let displayName;
      if (label === OTHER_LABEL) {
        displayName = 'Other';
      }
      return {
        label,
        displayName,
        options,
      };
    })
    .sort((a, b) => {
      if (a.label === MULTIPLE_SOURCES_LABEL) {
        return -1;
      }
      if (b.label === MULTIPLE_SOURCES_LABEL) {
        return 1;
      }

      if (a.label === OTHER_LABEL) {
        return 1;
      }
      if (b.label === OTHER_LABEL) {
        return -1;
      }

      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  return { keySourcesMap, groupedOptions };
};

export const groupListVariables = (variables: Variable[]) => {
  const hasContactVariables = variables.some((e) => e.value.startsWith('contact.'));
  const hasAccountVariables = variables.some((e) => e.value.startsWith('account.'));
  if (!hasAccountVariables || !hasContactVariables) {
    return variables;
  }
  const groups: GroupedVariable[] = [
    {
      name: 'Contacts',
      value: 'contacts',
      variables: variables.filter((variable) => !variable.value.startsWith('account.') && variable.value !== 'account'),
    },
    {
      name: 'Accounts',
      value: 'accounts',
      variables: variables.filter((variable) => variable.value.startsWith('account.') || variable.value === 'account'),
    },
  ];
  return groups;
};
