import * as React from 'react';
import { Checkbox } from '../Checkbox';
import { Button } from '../Button';
import * as M from './Modal';
import { Spacing } from '../Spacing';

export interface ConfirmModalProps {
  open: boolean;
  title?: React.ReactNode;
  body: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
  confirmLabel?: string;
  cancelLabel?: string;
  /**
   * If set, this confirmation modal may be suppressed by users. The key is
   * used to index into a local storage item.
   */
  suppressionKey?: string;
}

export const ConfirmModal = ({
  open,
  title,
  body,
  onClose,
  onConfirm,
  confirmLabel,
  cancelLabel,
  suppressionKey,
}: ConfirmModalProps) => {
  const [loading, setLoading] = React.useState(false);
  const [suppress, setSuppress] = React.useState(false);
  const localStorageKey = `mutiny.suppress_confirmation.${suppressionKey}`;
  const suppressed = localStorage.getItem(localStorageKey);

  React.useEffect(() => {
    if (!suppressionKey) {
      return;
    }

    if (open && suppressed) {
      onConfirm();
    }
  }, [suppressionKey, open, onConfirm, suppressed]);

  return (
    <M.Modal open={open && !suppressed}>
      <M.ModalContainer>
        {title != null && (
          <M.ModalHeader hideClose>
            <M.ModalTitle>{title}</M.ModalTitle>
          </M.ModalHeader>
        )}
        <M.ModalBody>{body}</M.ModalBody>
        <M.ModalFooter>
          <M.ModalFooterActions>
            {suppressionKey && (
              <>
                <Checkbox
                  label="Don't warn me again"
                  checked={suppress}
                  onCheckedChange={(checked) => {
                    setSuppress(!!checked);
                  }}
                />
                <Spacing flex={1} />
              </>
            )}
            <M.ModalClose>
              <Button
                variant="secondary"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClose();
                }}
              >
                {cancelLabel ?? 'Cancel'}
              </Button>
            </M.ModalClose>
            <Button
              variant="primary"
              loading={loading}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                try {
                  setLoading(true);
                  await onConfirm();
                  if (suppressionKey && suppress) {
                    localStorage.setItem(localStorageKey, 'true');
                  }
                  onClose();
                } catch (error) {
                  console.error(error);
                } finally {
                  setLoading(false);
                }
              }}
            >
              {confirmLabel ?? 'Confirm'}
            </Button>
          </M.ModalFooterActions>
        </M.ModalFooter>
      </M.ModalContainer>
    </M.Modal>
  );
};
