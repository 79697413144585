import * as React from 'react';
import { SVGProps } from 'react';
const SvgPaintbrush2 = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m12.789 8.736 3.408-3.408c.72-.72 1.857-.749 2.54-.065.684.683.655 1.82-.065 2.54l-3.435 3.434"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m15.666 11.666-3.332-3.333a2 2 0 0 0-2.743-.08L8.75 9 15 15.25l.747-.84a2 2 0 0 0-.08-2.744ZM9 10l-4.25 2.75 6.5 6.5L14 15M7 15l1.25-1.25"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPaintbrush2;
