import React, { SVGProps } from 'react';

const Eclipse = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 0C6.41775 -1.88681e-08 4.87103 0.469192 3.55544 1.34824C2.23984 2.22729 1.21446 3.47672 0.608964 4.93853C0.00346269 6.40034 -0.154964 8.00887 0.153718 9.56072C0.4624 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8H14.4C14.4 9.2658 14.0246 10.5032 13.3214 11.5556C12.6182 12.6081 11.6186 13.4284 10.4492 13.9128C9.27973 14.3972 7.9929 14.524 6.75142 14.277C5.50994 14.0301 4.36957 13.4205 3.47452 12.5255C2.57946 11.6304 1.96992 10.4901 1.72297 9.24858C1.47603 8.0071 1.60277 6.72027 2.08717 5.55083C2.57157 4.38138 3.39188 3.38184 4.44435 2.67859C5.49683 1.97535 6.7342 1.6 8 1.6L8 0Z"
      fill="currentColor"
    />
  </svg>
);

export default Eclipse;
