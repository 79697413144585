import React from 'react';

interface Props {
  className?: string;
}

const Sidebar = ({ className }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M9.37484 16.0417L5.62484 16.0417C4.70436 16.0417 3.95817 15.2955 3.95817 14.375L3.95817 5.62502C3.95817 4.70455 4.70436 3.95835 5.62484 3.95835L9.37484 3.95835M16.0415 5.62502L16.0415 14.375C16.0415 15.2955 15.2953 16.0417 14.3748 16.0417L12.2915 16.0417L12.2915 3.95835L14.3748 3.95835C15.2953 3.95835 16.0415 4.70455 16.0415 5.62502Z"
      stroke="#1E0036"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Sidebar;
