import React from 'react';
import cx from 'classnames';
import * as styles from './Whoosher.css';

export type WhoosherProps = {
  /** any valid CSS width. if omitted the whoosher will be 100% wide */
  width?: string;
  height?: string;
  animationDelay?: string;
  className?: string;
  transparentBackground?: boolean;
};

export const Whoosher = ({
  width,
  height,
  animationDelay,
  className,
  transparentBackground = false,
}: WhoosherProps) => {
  return (
    <span className={cx(styles.root({ transparentBackground }), className)} style={{ width, height }}>
      <span className={styles.whoosher} style={{ animationDelay }} />
    </span>
  );
};
