import * as React from 'react';
import { SVGProps } from 'react';
const SvgRedo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.75 4.75 19.25 9l-4.5 4.25"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.25 9H8.75a4 4 0 0 0-4 4v6.25"
    />
  </svg>
);
export default SvgRedo;
