import React, { CSSProperties, useEffect, useRef, Ref, useImperativeHandle } from 'react';
import cx from 'classnames';
import { useId } from '@mutiny-pkg/dumpster-ui/utils/useId';
import { Label } from '../Label/Label';
import { TextInputProps, IconLabelProps } from '../TextInput/TextInput';
import { Message } from '../Message/Message';
import { Spinner } from '../Loaders/Spinner';
import * as InputStyles from '../TextInput/TextInput.css';
import * as style from './TextArea.css';
import { AiEmbeddedAction } from '../AI/AiEmbeddedAction';
import { Variable, VariableDropdown } from '../Dropdown/VariableDropdown';
import { useInsertVariable } from '../../utils';

export type TextAreaValue = string | ReadonlyArray<string> | number | undefined;
export type TextAreaProps = Omit<
  TextInputProps,
  keyof React.InputHTMLAttributes<HTMLInputElement> | 'subtype' | 'onChange' | 'onEnter'
> & {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  onEnter?: React.KeyboardEventHandler<HTMLTextAreaElement>;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    showAiTrigger?: boolean;
    onAiTriggerClick?: React.MouseEventHandler<HTMLButtonElement>;
    resize?: Pick<CSSProperties, 'resize'>['resize'];
    variables?: Variable[];
  };

export type TextAreaIconProps = {
  children: React.ReactNode;
  loading?: boolean;
  showAiTrigger?: boolean;
  onAiTriggerClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const EndIconLabel = ({ children, icon: Icon }: IconLabelProps) => (
  <>
    {Icon && (
      <span className={InputStyles.endIconLabel}>
        <Icon className={InputStyles.iconLabel} />
      </span>
    )}
    {children}
  </>
);

const TextAreaIcons = ({ loading, showAiTrigger, onAiTriggerClick, children }: TextAreaIconProps) => {
  let trailingIcon;
  if (loading) {
    trailingIcon = Spinner;
  } else if (showAiTrigger && onAiTriggerClick) {
    return (
      <div className={InputStyles.iconContainer}>
        <AiEmbeddedAction onClick={onAiTriggerClick} variant="aiSecondary" label="Rewrite with AI">
          {children}
        </AiEmbeddedAction>
      </div>
    );
  }

  return (
    <div className={InputStyles.iconContainer}>
      <EndIconLabel icon={trailingIcon}>{children}</EndIconLabel>
    </div>
  );
};

export const TextArea = React.forwardRef(
  (
    {
      label,
      message,
      disabled,
      error,
      errorMessage,
      onChange,
      onValueChange,
      onEnter,
      required,
      fullWidth = false,
      id: idProp,
      containerClass,
      autoFocus,
      loading,
      showAiTrigger,
      onAiTriggerClick,
      resize = 'vertical',
      variables,
      ...props
    }: TextAreaProps,
    forwardedRef: Ref<HTMLTextAreaElement>,
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const id = useId(idProp);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onValueChange?.(e.target.value);
      onChange?.(e);
    };

    useImperativeHandle(
      forwardedRef,
      () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return textAreaRef.current!;
      },
      [],
    );

    useEffect(() => {
      if (autoFocus) {
        textAreaRef.current?.focus();
      }
    }, [autoFocus]);

    const insertVariable = useInsertVariable(textAreaRef, handleChange);

    return (
      <div className={cx(InputStyles.containerFullWidth[`${fullWidth}`], containerClass)}>
        {label && (
          <Label htmlFor={id} disabled={disabled} required={required} fullWidth>
            {label}
          </Label>
        )}
        <TextAreaIcons loading={loading} showAiTrigger={showAiTrigger} onAiTriggerClick={onAiTriggerClick}>
          <textarea
            ref={textAreaRef}
            id={id}
            key={id}
            className={style.text({
              error,
              loading,
              showAiTrigger,
              withVariables: (variables?.length ?? 0) > 0,
            })}
            disabled={disabled}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && onEnter) {
                e.preventDefault();
                onEnter(e);
              }
            }}
            style={{ resize }}
            {...props}
          />
          {variables && (variables.length ?? 0) > 0 && (
            <div className={InputStyles.variableDropdownContainer}>
              <VariableDropdown variables={variables} onVariableSelected={insertVariable} />
            </div>
          )}
        </TextAreaIcons>
        <Message variant="error" active={error && !disabled} disabled={disabled}>
          {errorMessage}
        </Message>
        <Message active={!error && !!message} disabled={disabled}>
          {message}
        </Message>
      </div>
    );
  },
);
