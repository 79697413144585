import React, { SVGProps } from 'react';

const Outreach = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fill="#5951FF"
      d="M10.8939 3C7.76983 3 5.4175 3.79921 4.0514 5.18198C2.57803 6.67333 2 8.46929 2 10.5201C2 13.7727 3.62533 17.2606 5.89572 19.5571C6.48864 20.1573 8.53557 22 11.2156 22C13.8957 22 15.9963 20.3051 17.2775 19.0083C19.7564 16.499 22 12.1728 22 9.35294C22 7.76659 21.2864 6.6854 20.7397 6.13198C18.5855 3.95151 13.9568 3 10.8939 3ZM14.6853 14.2447C13.6514 15.2912 12.6518 15.6848 11.6819 15.6848C10.7121 15.6848 9.75121 15.2264 9.13892 14.6066C8.47598 13.9356 7.86816 12.8212 7.86816 11.6661C7.86816 10.511 8.31955 9.69825 8.84246 9.17048C9.50838 8.49643 10.5095 8.01992 11.9978 8.01992C13.1732 8.01992 14.2533 8.29889 15.2276 9.28659C15.8637 9.93048 15.9978 10.6844 15.9978 11.1987C15.9978 11.9089 15.7192 13.1982 14.6853 14.2447Z"
    />
  </svg>
);

export default Outreach;
