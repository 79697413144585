import React from 'react';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import { Badge } from './Badge';

type CheckboxBadgeProps = {
  checked: boolean;
  children: React.ReactNode;
  onClick: () => void;
};

export const CheckboxBadge = ({ checked, children, onClick }: CheckboxBadgeProps) => {
  const icon = checked ? Icons.CheckCircle : Icons.Circle;
  return (
    <Badge iconLeft={icon} rounded variant={checked ? 'highlight' : 'default'} onClick={onClick} asButton>
      {children}
    </Badge>
  );
};
