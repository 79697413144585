import * as React from 'react';
import { Card } from './Card';
import * as style from './TitledCard.css';

type TitledCardProps = {
  children: React.ReactNode;
  title: string;
  description: string;
};

export const TitledCard: React.FC<TitledCardProps> = ({ children, title, description }) => (
  <Card>
    <div className={style.slot}>
      <div className={style.content}>
        <div className={style.title}>{title}</div>
        <div className={style.description}>{description}</div>
      </div>
      {children}
    </div>
  </Card>
);
