import React, { useState } from 'react';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import * as styles from './Checkbox.css';
import { Label, LabelProps } from '../Label/Label';

export type CheckboxVariantTypes = NonNullable<styles.CheckboxVariants>;

type SharedCheckboxProps = RadixCheckbox.CheckboxProps & {
  id?: string;
  label?: string;
  required?: boolean;
  noHoverHighlight?: boolean;
  radioStyle?: boolean;
  closeIndicator?: boolean;
  children?: React.ReactNode;
};

export type UncontrolledCheckboxProps = SharedCheckboxProps & {
  checked?: never;
  onCheckedChange?: never;
};

type ControlledCheckboxProps = SharedCheckboxProps & {
  checked: RadixCheckbox.CheckedState;
  onCheckedChange(checked: RadixCheckbox.CheckedState): void;
};

export type CheckboxProps = UncontrolledCheckboxProps | ControlledCheckboxProps;

type CheckboxLabelProps = LabelProps & {
  label?: string;
};

type CheckboxIndicatorProps = Pick<CheckboxProps, 'checked' | 'radioStyle' | 'closeIndicator'>;

const checkboxEventHandlers = {
  onMouseDown: (e: React.MouseEvent) => e.preventDefault(),
};

const CheckboxLabel = ({ children, label, ...props }: CheckboxLabelProps) => {
  if (!label) {
    return <>{children}</>;
  }

  return (
    <Label {...props}>
      <div className={styles.container}>
        {children}
        {label}
      </div>
    </Label>
  );
};

const CheckboxIndicator = ({ checked, radioStyle, closeIndicator }: CheckboxIndicatorProps) => {
  let Icon = Icons.Checkmark;
  if (checked === 'indeterminate') {
    Icon = Icons.Minus;
  }

  return (
    <>
      {closeIndicator && (
        <Icons.Close
          className={styles.closeIndicator({
            checked,
            closeIndicator,
            radioStyle,
          })}
        />
      )}
      <RadixCheckbox.Indicator
        className={styles.indicator({
          checked,
          closeIndicator,
          radioStyle,
        })}
      >
        {!radioStyle && <Icon width="100%" height="100%" />}
      </RadixCheckbox.Indicator>
    </>
  );
};

export const Checkbox = ({
  checked: propChecked,
  defaultChecked,
  disabled = false,
  id,
  label,
  children,
  noHoverHighlight = false,
  onCheckedChange,
  required,
  radioStyle,
  closeIndicator,
  ...props
}: CheckboxProps) => {
  const [innerChecked, setInnerChecked] = useState<RadixCheckbox.CheckedState>(defaultChecked ?? false);
  const checked = propChecked ?? innerChecked;

  const handleCheckedChange = (checkedChange: RadixCheckbox.CheckedState) => {
    if (onCheckedChange) {
      onCheckedChange(checkedChange);
    }
    setInnerChecked(checkedChange);
  };

  return (
    <CheckboxLabel label={label} aria-disabled={disabled} htmlFor={id} required={required}>
      <RadixCheckbox.Root
        checked={checked}
        className={styles.checkbox({
          checked,
          disabled,
          noHoverHighlight,
          radioStyle,
          closeIndicator,
        })}
        disabled={disabled}
        id={id}
        onCheckedChange={handleCheckedChange}
        {...checkboxEventHandlers}
        {...props}
      >
        <CheckboxIndicator checked={checked} radioStyle={radioStyle} closeIndicator={closeIndicator} />
      </RadixCheckbox.Root>
      {children}
    </CheckboxLabel>
  );
};
