import * as React from 'react';

import {
  Button as DumpsterButton,
  ButtonProps as DumpsterButtonProps,
  NoticeButton,
  Link as DumpsterLink,
} from '@mutiny-pkg/dumpster-ui';
import { mapLegacyButtonSize, mapLegacyButtonVariant } from '../helpers/propResolution';

type IElementProps = {
  'data-test-id'?: string;
  children?: React.ReactNode;
  color?:
    | 'primary'
    | 'primary-light'
    | 'secondary'
    | 'secondary-danger'
    | 'transparent'
    | 'transparent-danger'
    | 'transparent-active'
    | 'danger'
    | 'dark'
    | 'gray'
    | 'purple'
    | 'purple-dark'
    | 'green'
    | 'blue'
    | 'magic'
    | 'white'
    | 'white-transparent-hover'
    | 'tertiary';
  disabled?: boolean;
  EndIcon?: React.ComponentType<any>;
  href?: string;
  Icon?: React.ComponentType<any>;
  iconOnly?: boolean;
  loading?: boolean;
  noticeText?: string;
  size?: 'small' | 'medium' | 'large' | 'minimal';
} & Pick<DumpsterButtonProps, 'className' | 'fullWidth' | 'onChange' | 'onClick' | 'onMouseDown' | 'type'>;

export type IProps = IElementProps;

export type { IProps as ButtonProps };

const ButtonWrapper = React.forwardRef<HTMLButtonElement, IElementProps>(function ButtonWrapper(props, ref) {
  const variant = mapLegacyButtonVariant(props.color);
  const size = mapLegacyButtonSize(props.size);
  const DumpsterButtonComponent = props.noticeText ? NoticeButton : DumpsterButton;

  if (props.href) {
    return (
      <DumpsterLink
        variant="asBaseLink"
        onClick={props.onClick}
        href={props.href}
        asButton
        buttonSize={size}
        buttonVariant={variant}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
      >
        {props.children}
      </DumpsterLink>
    );
  }

  return (
    <DumpsterButtonComponent
      disabled={props.disabled}
      onChange={props.onChange}
      variant={variant}
      size={size}
      fullWidth={props.fullWidth}
      iconOnly={props.iconOnly}
      iconLeft={props.Icon}
      iconRight={props.EndIcon}
      onClick={props.onClick}
      loading={props.loading}
      onMouseDown={props.onMouseDown}
      type={props.type}
      ref={ref}
      notice={props.noticeText ?? null}
      className={props.className}
      data-test-id={props['data-test-id']}
    >
      {props.children}
    </DumpsterButtonComponent>
  );
});

export default ButtonWrapper;
