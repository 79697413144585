import * as React from 'react';
import { SVGProps } from 'react';
const SvgSalesforce = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 17.25c.679 0 1.31-.208 1.83-.564a2.75 2.75 0 0 0 4.111-.92 2.75 2.75 0 0 0 4.28-1.877 2.25 2.25 0 0 0-2.038-3.987 2.75 2.75 0 0 0-4.387-1.56 3.25 3.25 0 0 0-5.734 3.05A3.25 3.25 0 0 0 8 17.25Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgSalesforce;
