import React from 'react';
import { Flex, Typography } from '@mutiny-pkg/dumpster-ui/components';

export const NoResults = () => {
  return (
    <Flex.Container alignItems="center" justifyContent="center">
      <Typography font="body1">No results</Typography>
    </Flex.Container>
  );
};
