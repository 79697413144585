import React from 'react';
import { BaseOption } from './types';

interface UseOptionSelectionInput<Option extends BaseOption> {
  selectedOptions: Option[];
  setSelectedOptions: (options: Option[]) => void;
}

export const useOptionSelection = <Option extends BaseOption>({
  selectedOptions,
  setSelectedOptions,
}: UseOptionSelectionInput<Option>) => {
  return React.useCallback(
    (option: Option & { selected: boolean }) => {
      if (option.selected) {
        setSelectedOptions(selectedOptions.filter((column) => column.key !== option.key));
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    },
    [selectedOptions, setSelectedOptions],
  );
};
