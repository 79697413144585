import * as React from 'react';
import { SVGProps } from 'react';
const SvgFilters = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.75 7a.75.75 0 0 0 0 1.5V7Zm14.5 1.5a.75.75 0 0 0 0-1.5v1.5Zm-14.5 0h14.5V7H4.75v1.5ZM6.75 11a.75.75 0 0 0 0 1.5V11Zm10.5 1.5a.75.75 0 0 0 0-1.5v1.5Zm-10.5 0h10.5V11H6.75v1.5ZM8.75 15a.75.75 0 0 0 0 1.5V15Zm6.5 1.5a.75.75 0 0 0 0-1.5v1.5Zm-6.5 0h6.5V15h-6.5v1.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgFilters;
