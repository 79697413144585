import * as React from 'react';
import { SVGProps } from 'react';
const SvgWriteNote = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.25 15.75v.5a3 3 0 0 1-3 3h-6.5a3 3 0 0 1-3-3v-8.5a3 3 0 0 1 3-3h2.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m18.74 7.728.53.53-.53-.53Zm-3.284 3.283.53.53-.53-.53Zm-.99.56.183.727-.182-.727Zm-2.716.679-.728-.182a.75.75 0 0 0 .91.91l-.182-.728Zm.68-2.717.727.182-.728-.182Zm.56-.989.53.53-.53-.53Zm3.282-3.283-.53-.53.53.53Zm2.467 0-.53.53.53-.53Zm-.53 1.936-3.283 3.283 1.06 1.061 3.284-3.283-1.061-1.06Zm-3.924 3.646-2.717.68.364 1.455 2.717-.68-.364-1.455Zm-1.807 1.589.679-2.717-1.455-.364-.68 2.717 1.456.364Zm1.042-3.358 3.283-3.283-1.061-1.06-3.283 3.283 1.06 1.06Zm-.363.641c.06-.242.186-.464.363-.64l-1.061-1.061a2.88 2.88 0 0 0-.757 1.337l1.455.364Zm1.769.765a1.379 1.379 0 0 1-.641.363l.364 1.455a2.88 2.88 0 0 0 1.337-.757l-1.06-1.06Zm3.283-4.689a.994.994 0 0 1 0 1.406l1.06 1.061a2.494 2.494 0 0 0 0-3.527l-1.06 1.06Zm1.06-1.06a2.494 2.494 0 0 0-3.527 0l1.06 1.06a.994.994 0 0 1 1.407 0l1.06-1.06Z"
      fill="currentColor"
    />
    <path
      d="M7.75 15.25h6.5M7.75 12.25h1.5M7.75 9.25h1.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgWriteNote;
