import * as React from 'react';
import { SVGProps } from 'react';
const SvgLightning = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.25 15.25c-1.25 0-2.5-1.455-2.5-3.25a3.25 3.25 0 0 1 3.007-3.241 4.25 4.25 0 0 1 8.486 0A3.25 3.25 0 0 1 19.25 12c0 1.795-1.25 3.25-2.5 3.25"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m12.25 12.75-1.5 3.5h2.5l-1.5 3"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLightning;
