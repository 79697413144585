import * as React from 'react';
import { SVGProps } from 'react';
const SvgUndo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.25 4.75 4.75 9l4.5 4.25"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.5 9h9.75a4 4 0 0 1 4 4v6.25"
    />
  </svg>
);
export default SvgUndo;
