// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Inter-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Inter-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-display: 'swap';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-display: 'swap';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-display: 'swap';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-display: 'swap';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
}
`, "",{"version":3,"sources":["webpack://./packages/dumpster-ui/theme/fonts.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,oBAAoB;EACpB,+DAAwD;AAC1D;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,oBAAoB;EACpB,+DAAuD;AACzD;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,oBAAoB;EACpB,+DAAyD;AAC3D;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,oBAAoB;EACpB,+DAAqD;AACvD","sourcesContent":["@font-face {\n  font-family: 'Inter';\n  font-weight: 400;\n  font-display: 'swap';\n  src: url('./fonts/Inter-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Inter';\n  font-weight: 500;\n  font-display: 'swap';\n  src: url('./fonts/Inter-Medium.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Inter';\n  font-weight: 600;\n  font-display: 'swap';\n  src: url('./fonts/Inter-SemiBold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Inter';\n  font-weight: 700;\n  font-display: 'swap';\n  src: url('./fonts/Inter-Bold.ttf') format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
