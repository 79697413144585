import React, { useState } from 'react';
import * as Table from '.';
import { Checkbox } from '../Checkbox';
import { Flex } from '..';

type SelectableRowProps = Table.RowProps & {
  value: string;
  selected: boolean;
  onSelectRow: (value: string) => void;
  hideUnselectIndicator?: boolean;
};

const noop = () => {};

export const SelectableRow = React.forwardRef<HTMLTableRowElement, SelectableRowProps>(
  (
    { children, value, selected = false, onSelectRow, hideUnselectIndicator, ...props }: SelectableRowProps,
    forwardedRef,
  ) => {
    const [hovered, setHovered] = useState<boolean>(false);

    const handleSelectRow = () => {
      onSelectRow(value);
    };

    return (
      <Table.Row
        {...props}
        ref={forwardedRef}
        selected={selected}
        onMouseEnter={() => setHovered(selected)}
        onMouseLeave={() => setHovered(false)}
        onMouseDown={() => handleSelectRow()}
      >
        {children}
        <Table.Cell alignText="center">
          <Flex.Container justifyContent="center" alignItems="center">
            <Checkbox
              value={value}
              checked={selected}
              onCheckedChange={noop}
              closeIndicator={hovered && selected && !hideUnselectIndicator}
              radioStyle
            />
          </Flex.Container>
        </Table.Cell>
      </Table.Row>
    );
  },
);
