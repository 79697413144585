export const TEST_IDS = {
  LoadingIndicator: 'LoadingIndicator',
  LoginPage: {
    EmailInput: 'EmailInput',
    PasswordInput: 'PasswordInput',
    LoginButton: 'LoginButton',
  },
  SegmentListPage: {
    CreateExperienceButton: 'CreateExperienceButton',
    SegmentRow: 'SegmentRow',
  },
  CypressDummyFeedbackSurveyReplacementElement: 'CypressDummyFeedbackSurveyReplacementElement',
  SegmentBuilderPage: {
    SegmentBuilder: 'SegmentBuilderPage.SegmentBuilder',
    AttributeLineItem: 'SegmentBuilderPage.AttributeLineItem',
    getClauseContainer: (index: number) => `SegmentBuilderPage.ClauseContainer-${index}`,
    BooleanOrIndicator: 'SegmentBuilderPage.BooleanOrIndicator',
    BooleanAndIndicator: 'SegmentBuilderPage.BooleanAndIndicator',
    AttributeSelectorDropdown: 'SegmentBuilderPage.AttributeSelectorDropdown',
    AddAttributeButton: 'SegmentBuilderPage.AddAttributeButton',
    AddContainerButton: 'SegmentBuilderPage.AddContainerButton',
  },
  Elements: {
    Inputs: {
      Select: {
        Menu: 'Elements.Input.Select.Menu',
        Placeholder: 'Elements.Input.Select.Placeholder',
      },
    },
  },
  Editor: {
    LoadingPage: 'LoadingPage',
    TextEditor: 'TextEditor',
    ImageSourceInput: 'ImageSourceEditor',
    AnchorTextInput: 'AnchorTextInput',
    AnchorLocationInput: 'AnchorLocationInput',
    InteractionToggle: {
      PersonalizeButton: 'PersonalizeButton',
      InteractButton: 'InteractButton',
    },
    RefreshButton: 'RefreshButton',
    DeviceDropdown: 'DeviceDropdown',
    FindElementButton: 'FindElementButton',
    CustomJSButton: 'CustomJSButton',
    UndoButton: 'UndoButton',
    RedoButton: 'RedoButton',
    PersonalizationsDropdownButton: 'PersonalizationsDropdownButton',
    PersonalizationSummary: 'PersonalizationSummary',
    GroupedPersonalizationSummary: 'GroupedPersonalizationSummary',
    ConfirmSaveModal: {
      Body: 'ConfirmSaveModalBody',
      SaveButton: 'ConfirmSaveModalSaveButton',
      DiscardButton: 'ConfirmSaveModalDiscardButton',
      CancelButton: 'ConfirmSaveModalCancelButton',
    },
    VariationsButton: 'VariationsButton',
    VariationMenu: {
      ManageButton: 'VariationMenuManageButton',
      VariationItem: 'VariationMenuVariationItem',
    },
    TrafficDistributionModal: {
      AddNewVariationButton: 'TrafficDistributionModalAddNewVariationButton',
      SaveButton: 'TrafficDistributionModalSaveButton',
    },
    AddVariationModal: {
      NewVariationNameInput: 'NewVariationNameInput',
      CancelButton: 'AddVariationModalCancelButton',
      CreateButton: 'AddVariationModalCreateButton',
    },
    AddVariationButton: 'AddVariationButton',
    SidebarSaveAndAddNewVariationButton: 'SidebarSaveAndAddNewVariationButton',
    SidebarSaveButton: 'SidebarSaveButton',
    SidebarRevertButton: 'SidebarRevertButton',
    RevertDialog: {
      RevertButton: 'RevertDialogRevertButton',
      CancelButton: 'RevertDialogCancelButton',
    },
    DomNavigator: {
      PreviousPage: 'DomNavigatorPreviousPage',
      NextPage: 'DomNavigatorNextPage',
      ElementLink: 'DomNavigatorElementLink',
      ChildrenDropdownTrigger: 'DomNavigatorChildrenDropdownTrigger',
      ChildrenDropdownItem: 'DomNavigatorChildrenDropdownItem',
    },
    ExperienceEditorHeader: {
      SaveAndExitButton: 'SaveAndExitButton',
      LaunchButton: 'ExperienceEditorHeaderLaunchButton',
    },
    LaunchABMModal: {
      ReturnToOverviewButton: 'ExperienceEditorLaunchModal.ReturnToOverviewButton',
    },
  },
  Demo: {
    WebsiteStep: {
      UrlInput: 'DemoWebsiteStepUrlInput',
      EmailInput: 'DemoWebsiteStepEmailInput',
      StartPersonalizingButton: 'DemoWebsiteStepStartPersonalizingButton',
    },
  },
  Outbound: {
    ImportingRecordLoader: 'Outbound.ImportingRecordLoader',
    Create: {
      NameInput: 'Outbound.Create.NameInput',
      UrlInput: 'Outbound.Create.UrlInput',
      ContinueButton: 'Outbound.Create.ContinueButton',
    },
    Home: {
      NewCampaignButton: 'Outbound.Home.NewCampaignButton',
      getRowTestId: (name: string) => `Outbound.Home.Row.${name}`,
    },
    Campaign: {
      EditExperienceButton: 'Outbound.Capaign.EditExperienceButton',
    },
  },
};
