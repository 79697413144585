import React from 'react';
import { BaseGroupOptionSelected } from './types';

export const groupOptions = <Option extends BaseGroupOptionSelected>(availableOptions: Option[]) => {
  const notSelectedMapped = availableOptions.filter((option) => !option.selected);
  return notSelectedMapped.reduce<Record<string, Option[]>>((acc, option) => {
    if (!acc[option.groupKey]) {
      acc[option.groupKey] = [];
    }
    acc[option.groupKey]!.push(option);
    return acc;
  }, {});
};

export const formatGroupsForRendering = <Option extends BaseGroupOptionSelected>(
  groupedOptions: Record<string, Option[]>,
) =>
  Object.values(groupedOptions)
    .map((group) => {
      const firstOption = group.find(Boolean);
      if (firstOption) {
        return {
          options: group.map((option) => ({ ...option, selected: false })),
          groupDisplayName: firstOption.groupDisplayName,
          groupKey: firstOption.groupKey,
        };
      }
      return undefined;
    })
    .filter(Boolean);

export const useGroupedOptions = <Option extends BaseGroupOptionSelected>(availableOptions: Option[]) => {
  const groups = React.useMemo(() => {
    const groupedOptions = groupOptions(availableOptions);
    return formatGroupsForRendering(groupedOptions);
  }, [availableOptions]);

  const mappedSelectedOptions = React.useMemo(
    () => availableOptions.filter((option) => option.selected),
    [availableOptions],
  );
  return {
    selectedOptionsGroup: mappedSelectedOptions,
    groups,
  };
};
