import React, { useState } from 'react';
import * as PrimitiveNavigationMenu from '@radix-ui/react-navigation-menu';
import { NavLink as RouterLink, useRouteMatch } from 'react-router-dom';
import { Icons, IconType } from '@mutiny-pkg/dumpster-ui/icons';
import { Badge } from '../Badge/Badge';
import * as styles from './NavMenu.css';
import { Avatar, AvatarProps } from '../Avatar/Avatar';

export const NAV_MENU_WIDTH = styles.NAV_MENU_WIDTH + 1; // 1 extra pixel for border

export type Props = PrimitiveNavigationMenu.NavigationMenuProps & {
  variant?: 'default' | 'inline';
};
export type ListProps = PrimitiveNavigationMenu.NavigationMenuListProps;
export type ItemAlign = keyof typeof styles.itemAlign;
export type ItemProps = PrimitiveNavigationMenu.NavigationMenuItemProps & {
  align?: ItemAlign;
};
export type HeaderItemProps = PrimitiveNavigationMenu.NavigationMenuItemProps;
export interface LinkProps extends PrimitiveNavigationMenu.NavigationMenuLinkProps {
  href: string;
  routerLinkHref?: string;
  align?: ItemAlign;
  icon?: IconType;
}
type LinkContentProps = Pick<LinkProps, 'children' | 'icon'>;
export type CollapsibleGroupProps = LinkContentProps & { text: string };
export type CollapsibleContentProps = { children: React.ReactNode; open: boolean };
export type AvatarLinkProps = Omit<LinkProps, 'align' | 'icon'> & AvatarProps;

export const Root = ({ children, variant = 'default' }: Props) => (
  <PrimitiveNavigationMenu.Root className={styles.root[variant]} orientation="vertical">
    <PrimitiveNavigationMenu.List className={styles.list[variant]}>{children}</PrimitiveNavigationMenu.List>
  </PrimitiveNavigationMenu.Root>
);

export const Group = ({ children }: ListProps) => {
  return <div className={styles.group}>{children}</div>;
};

export const Item = ({ align = 'default', children }: ItemProps) => {
  return <PrimitiveNavigationMenu.Item className={styles.itemAlign[align]}>{children}</PrimitiveNavigationMenu.Item>;
};

const LinkContent = ({ children, icon }: LinkContentProps) => {
  const Icon = icon;

  return (
    <>
      {Icon && <Icon className={styles.icon} />}
      {children}
    </>
  );
};

export const Link = ({
  align,
  children,
  href,
  icon,
  routerLinkHref,
  beta,
  ...props
}: LinkProps & { beta?: boolean }) => {
  const routeMatch = useRouteMatch(href);
  const isActive = routeMatch?.path === '/' ? routeMatch.isExact : !!routeMatch;

  return (
    <Item align={align}>
      <PrimitiveNavigationMenu.Link className={styles.link} active={isActive} asChild {...props}>
        <RouterLink to={routerLinkHref || href}>
          <LinkContent icon={icon}>
            {children}{' '}
            {beta ? (
              <Badge size="small" variant="highlight">
                Beta
              </Badge>
            ) : null}
          </LinkContent>
        </RouterLink>
      </PrimitiveNavigationMenu.Link>
    </Item>
  );
};

export const ExternalLink = ({ children, href, icon, target = '_blank', ...props }: LinkProps) => {
  return (
    <Item>
      <PrimitiveNavigationMenu.Link className={styles.link} href={href} target={target} rel="noreferrer" {...props}>
        <LinkContent icon={icon}>{children}</LinkContent>
        {target === '_blank' && <Icons.ArrowUpRight className={styles.externalIcon} />}
      </PrimitiveNavigationMenu.Link>
    </Item>
  );
};

export const Header = ({ children }: HeaderItemProps) => {
  return (
    <PrimitiveNavigationMenu.Item className={styles.header}>
      <PrimitiveNavigationMenu.Link className={styles.linkBase} asChild>
        <RouterLink to="/">
          <Icons.MutinyTypePurple className={styles.headerLogo} />
        </RouterLink>
      </PrimitiveNavigationMenu.Link>
      {children}
    </PrimitiveNavigationMenu.Item>
  );
};

export const SectionHeader = ({ children }: HeaderItemProps) => {
  return <PrimitiveNavigationMenu.Item className={styles.sectionHeader}>{children}</PrimitiveNavigationMenu.Item>;
};

export const CollapsibleGroup = ({ children, icon, text }: CollapsibleGroupProps) => {
  const [open, setOpen] = useState(false);
  const openFlag = open ? 'open' : 'closed';
  const handleClick = () => setOpen(!open);

  return (
    <ul className={styles.collapsibleGroup}>
      <PrimitiveNavigationMenu.Item className={styles.itemBase}>
        <PrimitiveNavigationMenu.Trigger onClick={handleClick} className={styles.trigger}>
          <LinkContent icon={icon}>{text}</LinkContent>
          <Icons.ChevronDown className={styles.collapsibleIcon[openFlag]} />
        </PrimitiveNavigationMenu.Trigger>
      </PrimitiveNavigationMenu.Item>
      <div className={styles.collapsibleContent[openFlag]}>{children}</div>
    </ul>
  );
};

export const AvatarLink = ({ href, imageSrc, fallback }: AvatarLinkProps) => {
  return (
    <PrimitiveNavigationMenu.Link className={styles.linkBase} asChild>
      <RouterLink to={href}>
        <Avatar imageSrc={imageSrc} fallback={fallback} />
      </RouterLink>
    </PrimitiveNavigationMenu.Link>
  );
};
