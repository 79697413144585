import * as React from 'react';
import { SVGProps } from 'react';
const SvgMaximize2 = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.75 14.75v4.5h4.5M19.25 9.25v-4.5h-4.5M5 19l5.25-5.25M19 5l-5.25 5.25"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgMaximize2;
