import React from 'react';
import { AccessibleIcon } from '@radix-ui/react-accessible-icon';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import * as style from './Tag.css';

export type TagProps = Omit<React.HTMLAttributes<HTMLElement>, 'className'> & {
  onDelete: React.MouseEventHandler<HTMLElement>;
};

const buttonEventHandlers = {
  onMouseDown: (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  },
};

export const Tag = ({ children, onDelete, ...props }: TagProps) => {
  return (
    <div {...props} className={style.tag}>
      {children}
      {!!onDelete && (
        <button type="button" className={style.button} onClick={onDelete} {...buttonEventHandlers}>
          <AccessibleIcon label="delete">
            <Icons.Close className={style.icon} />
          </AccessibleIcon>
        </button>
      )}
    </div>
  );
};
