/* eslint-disable react/function-component-definition */
import React from 'react';
import ReactSelectComponent, * as ReactSelect from 'react-select5';
import Creatable, { CreatableProps } from 'react-select5/creatable';
import type {} from 'react-select5/base';
import { Checkbox } from '../Checkbox/Checkbox';
import * as style from './MultiSelect.css';
import { SelectPlaceholder } from '../Select';
import { Flex, Label, Typography, Whoosher } from '..';
import { Control, DropdownIndicator, ClearIndicator } from './MultiSelect';

declare module 'react-select5/base' {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  export interface Props<
    OptionType,
    IsMulti extends boolean,
    Group extends ReactSelect.GroupBase<OptionType>,
    GroupType extends ReactSelect.GroupBase<OptionType>,
  > {
    footer?: React.ReactNode;
  }
}

export type ColumnHeading = {
  value: string;
  size: 0 | 1 | 2 | 4 | 3 | 5 | undefined;
};

export type ColumnValue = {
  value: any;
  size: 0 | 1 | 2 | 4 | 3 | 5 | undefined;
};

export type TableOption = {
  columnValues?: ColumnValue[];
  value: string;
  label: string;
};

export type MultiSelectProps<OptionType> = Omit<
  ReactSelect.Props<OptionType, true>,
  'isMulti' | 'unstyled' | 'hideSelectedOptions' | 'components' | 'classNames'
> & {
  footer?: React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CLASS_NAMES_CONFIG: ReactSelect.ClassNamesConfig<any, true> = {
  control: () => style.control,
  placeholder: () => style.placeholder,
  valueContainer: () => style.valueContainer,
  multiValue: () => style.multiValue,
  multiValueLabel: () => style.multiValueLabel,
  multiValueRemove: () => style.multiValueRemove,
  clearIndicator: () => style.clearIndicator,
  indicatorSeparator: () => style.indicatorSeparator,
  menuPortal: () => style.menuPortal,
  dropdownIndicator: () => style.dropdownIndicator,
  menu: () => style.menu,
  noOptionsMessage: () => style.noOptionsMessage,
};

const ReactSelectMenu = ReactSelect.components.Menu;

export function Menu<OptionType>({ children, selectProps, ...props }: ReactSelect.MenuProps<OptionType, true>) {
  return (
    <ReactSelectMenu<OptionType, true, ReactSelect.GroupBase<OptionType>> selectProps={selectProps} {...props}>
      {children}
      {selectProps.footer && selectProps.footer}
    </ReactSelectMenu>
  );
}

const ReactSelectMenuList = ReactSelect.components.MenuList;
export function MenuList<OptionType>({ children, selectProps, ...props }: ReactSelect.MenuListProps<OptionType, true>) {
  return (
    <ReactSelectMenuList<OptionType, true, ReactSelect.GroupBase<OptionType>> selectProps={selectProps} {...props}>
      <Flex.Container className={style.option}>
        <Flex.Item xs={1} />
        {selectProps.columnHeadings?.map((heading: { value: string; size: 0 | 1 | 2 | 4 | 3 | 5 | undefined }) => {
          const { size, value } = heading;
          return (
            <Flex.Item xs={size}>
              <Typography color="secondary" font="body1" as="span">
                {value}
              </Typography>
            </Flex.Item>
          );
        })}
      </Flex.Container>

      {children}
    </ReactSelectMenuList>
  );
}

export function Option({
  innerProps,
  clearValue,
  selectOption,
  isSelected,
  data,
  isFocused,
  children,
}: ReactSelect.OptionProps<TableOption, true>) {
  if (!data.columnValues?.length) {
    return (
      <Flex.Container
        className={style.option}
        data-highlighted={isFocused || undefined}
        data-state={(isSelected && 'checked') || undefined}
        {...innerProps}
      >
        <Label>{children}</Label>
      </Flex.Container>
    );
  }
  return (
    <Flex.Container
      className={style.option}
      data-highlighted={isFocused || undefined}
      data-state={(isSelected && 'checked') || undefined}
      {...innerProps}
    >
      <Flex.Item xs={1}>
        <Checkbox
          checked={isSelected}
          onCheckedChange={(nextChecked) => (nextChecked ? selectOption(data) : clearValue())}
        />
      </Flex.Item>
      {data.columnValues?.map(({ value, size }) => (
        <Flex.Item xs={size}>
          <Typography color="secondary" font="body1" as="span">
            {value}
          </Typography>
        </Flex.Item>
      ))}
    </Flex.Container>
  );
}

const ReactSelectLoadingMessage = ReactSelect.components.LoadingMessage;

export function LoadingMessage<OptionType>({ selectProps, ...props }: ReactSelect.MenuProps<OptionType, true>) {
  return (
    <ReactSelectLoadingMessage<OptionType, true, ReactSelect.GroupBase<OptionType>>
      selectProps={selectProps}
      {...props}
    >
      <Flex.Container className={style.option}>
        <Whoosher />
      </Flex.Container>
    </ReactSelectLoadingMessage>
  );
}

export function TableMultiSelect<OptionType>({ ...props }: MultiSelectProps<OptionType>) {
  return (
    <div aria-expanded={props.menuIsOpen}>
      <ReactSelectComponent<OptionType, true>
        {...props}
        aria-label="Select..."
        menuShouldScrollIntoView
        menuPortalTarget={document.body}
        isMulti
        unstyled
        openMenuOnClick
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        components={{
          Control,
          Placeholder: SelectPlaceholder,
          DropdownIndicator,
          ClearIndicator,
          LoadingMessage,
          Option,
          MenuList,
          Menu,
        }}
        selectProps={{ columnHeadings: props.columnHeadings }}
        classNames={CLASS_NAMES_CONFIG}
      />
    </div>
  );
}

type CreatableMultiSelectProps<OptionType, IsMulti extends boolean = true> = CreatableProps<
  OptionType,
  IsMulti,
  ReactSelect.GroupBase<OptionType>
>;

export function CreatableTableMultiSelect<OptionType>({ ...props }: CreatableMultiSelectProps<OptionType>) {
  return (
    <div aria-expanded={props.menuIsOpen}>
      <Creatable<OptionType, true>
        {...props}
        aria-label="Select..."
        menuShouldScrollIntoView
        menuPortalTarget={document.body}
        isMulti
        unstyled
        closeMenuOnSelect={false}
        components={{
          Control,
          Placeholder: SelectPlaceholder,
          DropdownIndicator,
          ClearIndicator,
          Option,
          MenuList,
          LoadingMessage,
          Menu,
        }}
        selectProps={{ columnHeadings: props.columnHeadings }}
        classNames={CLASS_NAMES_CONFIG}
      />
    </div>
  );
}
