import * as React from 'react';
import { SVGProps } from 'react';
const SvgDatabasePlus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.25 7c0 1.105-3.384 2.25-7.25 2.25S4.75 8.105 4.75 7 8.134 4.75 12 4.75 19.25 5.895 19.25 7ZM12.25 14.25c-3.866 0-7.5-1.145-7.5-2.25M19.25 12.25V7"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 19.25c-3.866 0-7.5-1.145-7.5-2.25V7M17 14.75v4.5M19.25 17h-4.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgDatabasePlus;
