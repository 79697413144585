import React from 'react';
import * as PrimitiveSwitch from '@radix-ui/react-switch';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import * as styles from './Switch.css';

export type SwitchProps = PrimitiveSwitch.SwitchProps;

export const Switch = ({ ...props }: SwitchProps) => {
  return (
    <PrimitiveSwitch.Root {...props} className={styles.root}>
      <PrimitiveSwitch.Thumb className={styles.thumb}>
        <Icons.Checkmark className={styles.icon} />
      </PrimitiveSwitch.Thumb>
    </PrimitiveSwitch.Root>
  );
};
