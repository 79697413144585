import React from 'react';
import * as PrimitiveToast from '@radix-ui/react-toast';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import * as style from './Toast.css';
import { IconButton } from '../Button/Button';

export type ToastProps = PrimitiveToast.ToastProps &
  IconVariant & { hideCloseButton?: boolean; altText?: string; asStandalone?: boolean };

type ActionProps = PrimitiveToast.ToastActionProps;

type IconVariant = {
  variant?: keyof typeof style.toastIconSection;
};

type IconSectionProps = Required<IconVariant>;

const Action = ({ altText }: ActionProps) => {
  return (
    <div className={style.toastAction}>
      <PrimitiveToast.Action asChild altText={altText}>
        <IconButton
          variant="close"
          size="small"
          icon={Icons.Close}
          label={altText}
          className={style.toastActionButton}
        />
      </PrimitiveToast.Action>
    </div>
  );
};

const IconSection = ({ variant }: IconSectionProps) => {
  const iconMap = {
    info: Icons.Info,
    success: Icons.CheckCircle,
    warning: Icons.Alert,
    error: Icons.CloseCircle,
  };

  const Icon = iconMap[variant];
  return (
    <div className={style.toastIconSection[variant]}>
      <Icon />
    </div>
  );
};

export const Toast = ({
  open,
  onOpenChange,
  hideCloseButton = false,
  altText = 'Close notification',
  variant = 'info',
  children,
  asStandalone,
  ...props
}: ToastProps) => {
  return (
    <>
      <PrimitiveToast.Root className={style.toastRoot} open={open} onOpenChange={onOpenChange} {...props}>
        <IconSection variant={variant} />
        <PrimitiveToast.Description className={style.toastDescription}>{children}</PrimitiveToast.Description>
        {!hideCloseButton && <Action altText={altText} />}
      </PrimitiveToast.Root>
      {asStandalone && <PrimitiveToast.Viewport className={style.toastViewport} aria-modal />}
    </>
  );
};
