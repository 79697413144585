import React from 'react';
import { TextInputValue, Typography } from '@mutiny-pkg/dumpster-ui';
import { ArrowKeysT, SelectSearchContainer } from '../SearchContainer';
import * as style from '../ComboBox.css';
import { SingleSelectOption } from '../SingleSelect';

type Props = {
  displayedOptions: SingleSelectOption[];
  onSearchArrowNavigation: (key: ArrowKeysT) => void;
  handleSearch: (value: TextInputValue) => void;
};

export const SearchOptions = ({ displayedOptions, handleSearch, onSearchArrowNavigation }: Props) => {
  return (
    <SelectSearchContainer onSearchNavigation={onSearchArrowNavigation} onValueChange={handleSearch} fullWidth>
      {!displayedOptions.length && (
        <Typography font="body1" className={style.noResults}>
          No results
        </Typography>
      )}
    </SelectSearchContainer>
  );
};
