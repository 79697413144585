import React from 'react';
import { Flex, Link, Typography, Spacing } from '@mutiny-pkg/dumpster-ui/components';
import { Search } from './Search';

type Props = {
  resetSelection?: () => void;
  selectionDescription?: string;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
};

export const OptionControls = ({ resetSelection, selectionDescription, searchTerm, setSearchTerm }: Props) => {
  return (
    <Spacing paddingTop="6u" paddingX="8u" paddingBottom="4u">
      <Flex.Container flexDirection="column" gap="4u">
        {selectionDescription || resetSelection ? (
          <Flex.Container justifyContent="space-between" alignItems="center">
            {selectionDescription ? (
              <Typography font="body1" as="span">
                {selectionDescription}
              </Typography>
            ) : null}

            {resetSelection ? (
              <Link size="large" onClick={() => resetSelection()}>
                Reset
              </Link>
            ) : null}
          </Flex.Container>
        ) : null}
        <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </Flex.Container>
    </Spacing>
  );
};
