import React, { useMemo } from 'react';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import { ButtonVariantEnum, IconButton } from '../Button/Button';
import * as styles from './Pagination.css';

export enum TextLocation {
  left = 'left',
  flexLeft = 'flexLeft',
  right = 'right',
  none = 'none',
}

type BasePaginationProps = {
  currentPage: number;
  disabled?: boolean;
  hasMorePages?: boolean;
  onNext: () => void;
  onPrevious: () => void;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
  buttonVariant?: ButtonVariantEnum;
  textLocation?: TextLocation;
  pageText?: string;
};

interface ItemTotalProps extends BasePaginationProps {
  hasMorePages: boolean;
  pageSize: number;
  totalItems: number;
}

export type PaginationProps = ItemTotalProps | BasePaginationProps;

const nf = new Intl.NumberFormat('en-US');

const usePagination = ({
  currentPage,
  hasMorePages,
  disabled,
  pageSize,
  totalItems,
  totalPages,
  pageText = 'Page',
}: Pick<PaginationProps, 'currentPage'> & Partial<PaginationProps>) => {
  return useMemo(() => {
    if (totalPages) {
      return {
        indicatorText: `${pageText} ${nf.format(currentPage)} of ${nf.format(totalPages)}`,
        nextDisabled: disabled === true || totalPages === 1 || currentPage === totalPages,
        previousDisabled: disabled === true || totalPages === 1 || currentPage === 1,
      };
    }

    if (pageSize && totalItems) {
      const start = (currentPage - 1) * pageSize + 1;
      const end = Math.min(currentPage * pageSize, totalItems);
      return {
        indicatorText: `${nf.format(start)} - ${nf.format(end)} of ${nf.format(totalItems)}`,
        nextDisabled: disabled === true || !hasMorePages,
        previousDisabled: disabled === true || currentPage === 1,
      };
    }

    return {
      indicatorText: `${pageText} ${nf.format(currentPage)}`,
      nextDisabled: disabled === true,
      previousDisabled: disabled === true || currentPage === 1,
    };
  }, [currentPage, disabled, hasMorePages, pageSize, pageText, totalItems, totalPages]);
};

export const Pagination = ({
  onNext,
  onPrevious,
  buttonVariant = ButtonVariantEnum.secondary,
  textLocation = TextLocation.flexLeft,
  ...props
}: PaginationProps) => {
  const pagination = usePagination(props);
  const paginationVariant = textLocation === TextLocation.right ? 'right' : 'default';
  const buttonsVariant = textLocation === TextLocation.flexLeft ? 'flexLeft' : 'default';

  return (
    <div className={styles.pagination[paginationVariant]}>
      {(textLocation === TextLocation.left || textLocation === TextLocation.flexLeft) && (
        <span>{pagination.indicatorText}</span>
      )}
      <div className={styles.buttons[buttonsVariant]}>
        <IconButton
          variant={buttonVariant}
          label="Previous"
          icon={Icons.ChevronLeft}
          onClick={onPrevious}
          disabled={pagination.previousDisabled}
        />
        <IconButton
          variant={buttonVariant}
          label="Next"
          icon={Icons.ChevronRight}
          onClick={onNext}
          disabled={pagination.nextDisabled}
        />
      </div>
      {textLocation === TextLocation.right && <span>{pagination.indicatorText}</span>}
    </div>
  );
};
