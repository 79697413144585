import React from 'react';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import { IconButton, IconButtonProps } from '../Button/Button';
import * as style from './AiEmbeddedAction.css';
import { Tooltip } from '../Tooltip';

export type AiEmbeddedActionProps = React.PropsWithChildren<
  Pick<IconButtonProps, 'onClick'> & {
    label: string;
    variant?: 'aiPrimary' | 'aiSecondary';
  }
>;

export const AiEmbeddedAction = ({ onClick, label, variant = 'aiPrimary', children }: AiEmbeddedActionProps) => {
  return (
    <>
      <span className={style.aiEmbeddedActionLabel}>
        <Tooltip content={label} variant="dark" asChild>
          <IconButton
            variant={variant}
            size="small"
            onClick={onClick}
            icon={Icons.AiBolt}
            label={label}
            className={style.aiButton}
          />
        </Tooltip>
      </span>
      {children}
    </>
  );
};
