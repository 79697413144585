import cx from 'classnames';
import React, { useCallback, useMemo, useState, CSSProperties, useEffect } from 'react';
import { debounce } from 'lodash';
import StickyColGroupWidthHelper from './StickyColGroupWidthHelper';
import * as style from './Table.css';

export type NativeTableProps = React.HTMLAttributes<HTMLTableElement>;
export type TableWrapperVariants = NonNullable<style.TableWrapperVariants>;
export interface RootPropsI extends NativeTableProps {
  stickyColumnsLeft?: 1 | 2 | 3;
  stickyColumnsRight?: 1;
  layout?: keyof typeof style.tableLayout;
  maxHeight?: CSSProperties['height'];
  fetchMoreData?: () => void;
  hasMoreData?: boolean;
  loading?: boolean;
}

export type RootProps = RootPropsI & style.TableWrapperVariants;

export type StickyColumnContextValues = {
  leftColumns?: number;
  rightColumns?: number;
};

export const TableStickyColumnContext = React.createContext<StickyColumnContextValues>({});

const calculateScrollRight = (currentTarget: EventTarget & HTMLDivElement) => {
  return currentTarget.scrollWidth - currentTarget.scrollLeft - currentTarget.clientWidth;
};

export const Root = ({
  stickyColumnsLeft,
  stickyColumnsRight,
  borderDisplay,
  innerBorderDisplay,
  borderRadiusDisplay,
  layout = 'default',
  children,
  maxHeight = 'auto',
  loading,
  fetchMoreData,
  hasMoreData,
  ...props
}: RootProps) => {
  const [hasTableLeftScroll, setTableLeftScroll] = useState<boolean>(false);
  const [hasTableRightScroll, setTableRightScroll] = useState<boolean>(false);
  const [hasTableTopScroll, setTableTopScroll] = useState<boolean>(false);
  const isElementAtBottom = (currentTarget: EventTarget & HTMLDivElement) => {
    const threshold = 0.8;
    return currentTarget.scrollTop + currentTarget.clientHeight >= threshold * currentTarget.scrollHeight;
  };

  const debouncedFetchMoreData = useMemo(() => {
    return debounce(() => {
      fetchMoreData?.();
    }, 500);
  }, [fetchMoreData]);

  useEffect(() => {
    return () => {
      debouncedFetchMoreData.cancel();
    };
  }, [debouncedFetchMoreData]);

  const maybeFetchMoreData = useCallback(() => {
    if (hasMoreData) {
      debouncedFetchMoreData();
    }
  }, [hasMoreData, debouncedFetchMoreData]);

  /* Used to toggle classes on and off for the box-shadow. We want sticky columns to look normal
  if they are in their original position in the table. */
  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const { currentTarget } = event;

      setTableTopScroll((tts) => {
        if (currentTarget.scrollTop === 0 && tts) {
          return false;
        }
        if (currentTarget.scrollTop > 0 && !tts) {
          return true;
        }
        return tts;
      });

      setTableRightScroll((trs) => {
        if (!stickyColumnsRight || (calculateScrollRight(currentTarget) < 2 && trs)) {
          return false;
        }
        if (calculateScrollRight(currentTarget) > 2 && !trs) {
          return true;
        }
        return trs;
      });

      setTableLeftScroll((tls) => {
        if (!stickyColumnsLeft || (currentTarget.scrollLeft === 0 && tls)) {
          return false;
        }
        if (currentTarget.scrollLeft > 0 && !tls) {
          return true;
        }
        return tls;
      });

      if (isElementAtBottom(currentTarget)) {
        maybeFetchMoreData();
      }
    },
    [stickyColumnsRight, stickyColumnsLeft, maybeFetchMoreData],
  );

  const contextValues = useMemo(
    () => ({
      leftColumns: stickyColumnsLeft ?? 0,
      rightColumns: stickyColumnsRight ?? 0,
    }),
    [stickyColumnsLeft, stickyColumnsRight],
  );

  const scrollMethods = useMemo(() => ({ onScroll: handleScroll }), [handleScroll]);

  const tableClasses = useMemo(() => {
    const classesToAdd = [style.tableLayout[layout]];
    if (!stickyColumnsLeft && !stickyColumnsRight) {
      return classesToAdd;
    }

    if (stickyColumnsLeft) {
      switch (stickyColumnsLeft) {
        case 1:
          classesToAdd.push(style.oneLeftColumn);
          break;
        case 2:
          classesToAdd.push(style.twoLeftColumn);
          break;
        case 3:
          classesToAdd.push(style.threeLeftColumn);
          break;
        default:
          break;
      }
    }

    if (stickyColumnsRight) {
      classesToAdd.push(style.oneRightColumn);
    }

    if (stickyColumnsLeft && hasTableLeftScroll) {
      switch (stickyColumnsLeft) {
        case 1:
          classesToAdd.push(style.oneLeftColumnScroll);
          break;
        case 2:
          classesToAdd.push(style.twoLeftColumnScroll);
          break;
        case 3:
          classesToAdd.push(style.threeLeftColumnScroll);
          break;
        default:
          break;
      }
    }

    if (stickyColumnsRight && hasTableRightScroll) {
      classesToAdd.push(style.oneRightColumnScroll);
    }
    return classesToAdd;
  }, [hasTableLeftScroll, hasTableRightScroll, stickyColumnsLeft, stickyColumnsRight, layout]);

  const tableRef = React.useRef<HTMLTableElement>(null);

  const StickyColumnHelperComponent = React.useMemo(() => {
    if (stickyColumnsLeft && stickyColumnsLeft > 1 && tableRef) {
      return <StickyColGroupWidthHelper stickyColumnsLeft={stickyColumnsLeft} tableRef={tableRef} />;
    }
    return null;
  }, [stickyColumnsLeft, tableRef]);

  return (
    <div
      className={`${style.tableWrapper({
        borderDisplay,
        borderRadiusDisplay,
        innerBorderDisplay,
      })} ${(hasTableTopScroll && style.hasHeaderTopScroll) || ''}`}
      style={{ maxHeight }}
    >
      <div className={style.tableScroll} style={{ maxHeight }} {...scrollMethods}>
        <TableStickyColumnContext.Provider value={contextValues}>
          <table {...props} className={cx(tableClasses)} ref={tableRef}>
            {children}
            {stickyColumnsLeft && stickyColumnsLeft > 1 ? StickyColumnHelperComponent : null}
          </table>
        </TableStickyColumnContext.Provider>
      </div>
    </div>
  );
};
