import React, { SVGProps } from 'react';

const Hubspot = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <rect width="20" height="20" rx="4" fill="#FF7A59" />
    <path
      d="M12.3807 8.48245V7.42395C12.5271 7.35858 12.6511 7.25481 12.7382 7.12475C12.8253 6.99468 12.872 6.84365 12.8727 6.68925V6.66496C12.8727 6.21478 12.4906 5.84983 12.0194 5.84983H11.9939C11.5226 5.84983 11.1405 6.21478 11.1405 6.66496V6.68925C11.1413 6.84365 11.1879 6.99468 11.275 7.12475C11.3621 7.25481 11.4861 7.35858 11.6326 7.42395V8.48245C11.2117 8.54402 10.8153 8.71072 10.4835 8.96568L7.44434 6.70453C7.46601 6.62981 7.47731 6.55296 7.47859 6.47583C7.47896 6.29417 7.42292 6.11648 7.31755 5.96525C7.21219 5.81401 7.06223 5.69602 6.88665 5.6262C6.71107 5.55638 6.51776 5.53787 6.33116 5.57301C6.14457 5.60814 5.97307 5.69535 5.83837 5.8236C5.70367 5.95185 5.61182 6.11537 5.57443 6.29349C5.53704 6.47161 5.55579 6.65632 5.62832 6.82425C5.70084 6.99219 5.82388 7.13581 5.98187 7.23694C6.13986 7.33808 6.3257 7.39218 6.51589 7.39242C6.68227 7.39167 6.84553 7.34924 6.98937 7.26937L9.98211 9.49382C9.4319 10.2877 9.44662 11.3225 10.0192 12.1019L9.10894 12.9716C9.03533 12.9491 8.95872 12.9372 8.88146 12.9362C8.44553 12.9365 8.09239 13.2742 8.0925 13.6906C8.09261 14.1069 8.44599 14.4444 8.88187 14.4446C9.3178 14.4447 9.67133 14.1073 9.6717 13.6909C9.67067 13.6172 9.65817 13.544 9.63459 13.4737L10.5351 12.6133C11.3397 13.2049 12.4447 13.2559 13.3052 12.741C14.1658 12.2262 14.6 11.2542 14.3938 10.3045C14.1876 9.35484 13.3846 8.62803 12.3807 8.48245ZM12.0074 11.9638C11.8411 11.9681 11.6755 11.9405 11.5205 11.8826C11.3655 11.8247 11.2242 11.7378 11.1049 11.6269C10.9857 11.5161 10.8909 11.3835 10.8262 11.237C10.7615 11.0906 10.7281 10.9332 10.7281 10.7743C10.7281 10.6153 10.7615 10.458 10.8262 10.3115C10.8909 10.1651 10.9857 10.0325 11.1049 9.92161C11.2242 9.81073 11.3655 9.7238 11.5205 9.66594C11.6755 9.60809 11.8411 9.58048 12.0074 9.58476C12.6778 9.60718 13.2092 10.1326 13.2096 10.7734C13.2099 11.4141 12.6791 11.9401 12.0087 11.9632"
      fill="white"
    />
    <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#1E0036" strokeOpacity="0.05" />
  </svg>
);

export default Hubspot;
