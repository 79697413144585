import React from 'react';
import * as TogglePrimitive from '@radix-ui/react-toggle-group';
import * as style from './SegmentedControl.css';

export type SegmentedControlProps = TogglePrimitive.ToggleGroupSingleProps | TogglePrimitive.ToggleGroupMultipleProps;

const togglePrimitiveEventHandlers = {
  onMouseDown: (e: React.MouseEvent) => e.preventDefault(),
};

export const SegmentedControl = ({ orientation = 'horizontal', children, ...props }: SegmentedControlProps) => {
  return (
    <TogglePrimitive.Root {...props} className={style.segmentedControlGroup[orientation]}>
      {children}
    </TogglePrimitive.Root>
  );
};

export const SegmentedControlItem = ({ children, ...props }: TogglePrimitive.ToggleGroupItemProps) => {
  return (
    <TogglePrimitive.Item {...togglePrimitiveEventHandlers} {...props} className={style.segmentedControlItem.label}>
      {children}
    </TogglePrimitive.Item>
  );
};
