import * as React from 'react';
import { SVGProps } from 'react';
const SvgUserCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.5 12a6.5 6.5 0 0 1-6.5 6.5V20a8 8 0 0 0 8-8h-1.5ZM12 18.5A6.5 6.5 0 0 1 5.5 12H4a8 8 0 0 0 8 8v-1.5ZM5.5 12A6.5 6.5 0 0 1 12 5.5V4a8 8 0 0 0-8 8h1.5ZM12 5.5a6.5 6.5 0 0 1 6.5 6.5H20a8 8 0 0 0-8-8v1.5Z"
      fill="currentColor"
    />
    <path
      d="M13.5 10a1.5 1.5 0 0 1-1.5 1.5V13a3 3 0 0 0 3-3h-1.5ZM12 11.5a1.5 1.5 0 0 1-1.5-1.5H9a3 3 0 0 0 3 3v-1.5ZM10.5 10A1.5 1.5 0 0 1 12 8.5V7a3 3 0 0 0-3 3h1.5ZM12 8.5a1.5 1.5 0 0 1 1.5 1.5H15a3 3 0 0 0-3-3v1.5ZM6.621 16.52a.75.75 0 1 0 1.153.96l-1.153-.96Zm9.606.96a.75.75 0 1 0 1.152-.96l-1.152.96Zm-8.453 0A5.487 5.487 0 0 1 12 15.5V14a6.987 6.987 0 0 0-5.379 2.52l1.153.96ZM12 15.5c1.698 0 3.216.769 4.227 1.98l1.152-.96A6.987 6.987 0 0 0 12 14v1.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgUserCircle;
