import React from 'react';
import { Box } from '../Box';

type BoxProps = React.ComponentProps<typeof Box>;

type Props = {
  style?: BoxProps['style'];
};

export const Divider = (props: Props) => <Box border="default" {...props} />;
