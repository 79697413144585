import * as React from 'react';

import { useRedesign } from '@mutiny-pkg/dumpster-ui/contexts/RedesignContext';
import Button from './Button';

import WithStyles, { IStyleProps } from '../higher_order/WithStyles';
import googleLogo from '../images/icons/google.png';

const GoogleButton: React.FC<IStyleProps & GetComponentProps<typeof Button> & { authenticityToken: string | null }> = ({
  classes,
  children,
  authenticityToken,
  ...props
}) => {
  const { redesignEnabled } = useRedesign();
  return (
    <form action="/auth/google_oauth2" method="post" className={classes.form}>
      <input type="hidden" name="authenticity_token" defaultValue={authenticityToken || ''} />
      <Button
        {...props}
        size="small"
        className={classes.googleButton}
        color={redesignEnabled ? 'tertiary' : 'secondary'}
        fullWidth
        type="submit"
      >
        <img className={classes.img} src={googleLogo} alt="Google logo" />
        {children}
      </Button>
    </form>
  );
};

export default WithStyles({
  googleButton: {
    '& img': {
      marginRight: '10px',
      width: '18px',
    },
    marginTop: '8px',
  },
  img: {
    marginRight: '10px',
    width: '18px',
  },
  form: {
    display: 'flex',
  },
})(GoogleButton);
