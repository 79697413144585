import React, { SVGProps } from 'react';

const AiBolt = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.81176 4.9471L5.8037 4.94567C5.39953 4.86921 5.19492 4.4408 5.40154 4.10501L7.3972 0.865837C7.63405 0.481601 7.33975 0 6.86855 0H2.27955C1.99885 0 1.75443 0.180007 1.6864 0.436481L0.456248 5.06346L0.0218403 6.57523C-0.0986045 6.99462 0.298007 7.38455 0.747533 7.28766C1.2404 7.18175 1.77409 7.1808 2.27854 7.24681C2.68725 7.30001 3.08638 7.39215 3.484 7.49189C3.80653 7.57311 4.00005 7.88325 3.91891 8.18817L2.47256 13.6492C2.38891 13.965 2.8465 14.1403 3.02036 13.8586L6.70174 7.88562L7.91627 5.91505C8.16169 5.51657 7.83614 5.01882 7.34831 5.05064C6.82722 5.08483 6.28699 5.03686 5.81176 4.9471Z"
      fill="currentColor"
    />
  </svg>
);

export default AiBolt;
