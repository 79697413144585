import * as React from 'react';
import { SVGProps } from 'react';
const SvgBlockquote = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.25 11.25a2 2 0 0 1-2 2h-2.5a2 2 0 0 1-2-2V9.063a3 3 0 0 1 .965-2.204L8 4.75v3h1.25a2 2 0 0 1 2 2v1.5ZM19.25 17.25a2 2 0 0 1-2 2h-2.5a2 2 0 0 1-2-2v-2.187a3 3 0 0 1 .965-2.204L16 10.75v3h1.25a2 2 0 0 1 2 2v1.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgBlockquote;
