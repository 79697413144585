import cx from 'classnames';
import * as React from 'react';

import WithStyles, { IStyleProps } from '../higher_order/WithStyles';

import darkLogo from '../images/dark-logo.svg';
import logo from '../images/logo.svg';

type Size = 'sm' | 'lg';

interface IProps extends IStyleProps {
  size?: Size;
  color?: 'light' | 'dark';
}

const Logo: React.FC<IProps> = ({ classes, color = 'light', size }) => (
  <div className={cx(classes.logoContainer, classes[size || 'lg'], 'margin-center')}>
    <img className={classes.img} src={color === 'light' ? logo : darkLogo} alt="Mutiny logo" />
  </div>
);

export default WithStyles({
  img: {
    display: 'inline-block',
  },
  lg: {
    width: '135px',
  },
  logoContainer: {
    padding: '24px 8px 0 0',
  },
  sm: {
    width: '95px',
  },
})(Logo);
