import React from 'react';
import * as PrimitiveCollapsible from '@radix-ui/react-collapsible';
import { Icons } from '../../icons';
import * as styles from './Collapsible.css';

type TriggerProps = PrimitiveCollapsible.CollapsibleTriggerProps & styles.TriggerVariants;

export const TriggerIcon = () => <Icons.ChevronDown className={styles.triggerIcon} />;

export const Trigger = ({ children, iconPosition = 'left', fullWidth, ...props }: TriggerProps) => {
  return (
    <PrimitiveCollapsible.Trigger className={styles.trigger({ iconPosition, fullWidth })} {...props}>
      {iconPosition === 'right' && children}
      {iconPosition !== 'none' ? <TriggerIcon /> : children}
      {iconPosition === 'left' && children}
    </PrimitiveCollapsible.Trigger>
  );
};

export const Content = ({ children, ...props }: PrimitiveCollapsible.CollapsibleContentProps) => {
  return (
    <PrimitiveCollapsible.Content className={styles.content} {...props}>
      {children}
    </PrimitiveCollapsible.Content>
  );
};

export const { Root } = PrimitiveCollapsible;
