import cx from 'classnames';
import React, { SVGProps } from 'react';
import * as style from './ExpandIndicator.css';

export const ExpandIndicator = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, style.svg)}
    {...props}
  >
    <path
      className={style.up}
      d="M10.0005 4.85859L12.0505 6.90859C12.3755 7.23359 12.9005 7.23359 13.2255 6.90859C13.5505 6.58359 13.5505 6.05859 13.2255 5.73359L10.5839 3.08359C10.2589 2.75859 9.73385 2.75859 9.40885 3.08359L6.76719 5.73359C6.44219 6.05859 6.44219 6.58359 6.76719 6.90859C7.09219 7.23359 7.61719 7.23359 7.94219 6.90859L10.0005 4.85859Z"
      fill="currentColor"
    />
    <path
      className={style.down}
      d="M10.0005 15.1419L7.95052 13.0919C7.62552 12.7669 7.10052 12.7669 6.77552 13.0919C6.45052 13.4169 6.45052 13.9419 6.77552 14.2669L9.41719 16.9169C9.74219 17.2419 10.2672 17.2419 10.5922 16.9169L13.2339 14.2753C13.5589 13.9503 13.5589 13.4253 13.2339 13.1003C12.9089 12.7753 12.3839 12.7753 12.0589 13.1003L10.0005 15.1419Z"
      fill="currentColor"
    />
  </svg>
);
