import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import { ReactEditor } from 'slate-react';
import * as style from './TableCell.css';
import { Container, Item } from '../Flex/Flex';
import { CellProps } from './TableCell';
import { VariablesTextInput, useVariablesTextInput } from '../TextInput/VariablesTextInput';

export type ContentGenerationCellProps = CellProps & {
  initialText: string;
  placeholder?: string;
  onValueChange?: (value?: string) => void;
  onBlur?: (event: any) => void;
  onFocus?: () => void;
  setInputRef?: (ref: React.RefObject<HTMLTableCellElement>) => void;
};

export const ContentGenerationCell = React.forwardRef<HTMLTableCellElement, ContentGenerationCellProps>(
  (
    { alignText = 'left', selectable = true, initialText, onValueChange, children, setInputRef, placeholder, ...props },
    forwardedRef,
  ) => {
    const tableCellRef = useRef<HTMLTableCellElement | null>(null);
    const { editor, getPlainText } = useVariablesTextInput();

    const [textValue, setTextValue] = useState<string | null>(null);
    const pendingValue = useRef<string | null>(null);
    const debouncedValue = useRef<string | null>(null);
    useEffect(() => {
      if (textValue === null) {
        return () => {};
      }

      pendingValue.current = textValue;
      const timeout = setTimeout(() => {
        debouncedValue.current = textValue;
        onValueChange?.(textValue || undefined);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }, [textValue]);

    useEffect(() => {
      return () => {
        if (pendingValue.current && debouncedValue.current !== pendingValue.current) {
          onValueChange?.(pendingValue.current);
        }
      };
    }, []);

    React.useEffect(() => {
      setInputRef?.(React.createRef());
    }, []);

    useImperativeHandle(forwardedRef, () => ({
      ...(tableCellRef.current as HTMLTableCellElement),
      focus: () => {
        ReactEditor.focus(editor);
      },
    }));
    return (
      <td
        ref={forwardedRef ?? tableCellRef}
        className={style.tableCell({ alignText, selectable, input: true })}
        onBlurCapture={props.onBlur}
        {...props}
      >
        <Container flexWrap="nowrap" flexDirection="row" gap="2u" alignItems="center">
          <Item style={{ flexGrow: 1, maxWidth: '100%', fontSize: '14px' }}>
            <VariablesTextInput
              editor={editor}
              variables={[]}
              placeholder={placeholder}
              initialValue={[
                {
                  type: 'paragraph',
                  children: [{ text: initialText }],
                },
              ]}
              onValueChange={(value) => setTextValue(getPlainText(value))}
            />
          </Item>
          <Item>{children}</Item>
        </Container>
      </td>
    );
  },
);
