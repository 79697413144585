import { MuiThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import LoginPage from '../views/Pages/LoginPage';

import theme from '../MuiTheme';
import '../stylesheets/mutiny-app.scss';
import PageTitle from '../elements/PageTitle';

const renderApp = () => {
  const container = document.getElementById('app');
  const root = createRoot(container!);
  root.render(
    <MuiThemeProvider theme={theme}>
      <PageTitle>Mutiny</PageTitle>
      <LoginPage />
    </MuiThemeProvider>,
  );
};

if (document.readyState !== 'loading') {
  renderApp();
} else {
  document.addEventListener('DOMContentLoaded', renderApp);
}
