import React from 'react';
import * as RadixLabel from '@radix-ui/react-label';
import * as styles from './Label.css';

export type LabelVariantTypes = NonNullable<styles.LabelVariants>;

export type LabelProps = RadixLabel.LabelProps & {
  size?: LabelVariantTypes['size'];
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
};

export const Label = ({
  children,
  disabled: disabledProp,
  'aria-disabled': ariaDisabled,
  htmlFor,
  required,
  size,
  fullWidth,
}: LabelProps) => {
  const disabled = disabledProp || ariaDisabled === true || ariaDisabled === 'true';

  return (
    <RadixLabel.Root className={styles.label({ disabled, fullWidth, size })} htmlFor={htmlFor}>
      {children}
      {required && <span className={styles.subtextLabel({ disabled })}>Required</span>}
    </RadixLabel.Root>
  );
};
