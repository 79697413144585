import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import React, { MouseEventHandler } from 'react';
import * as style from './RadioGroup.css';
import { RadioOption } from './RadioOption';
import { Variants } from './RadioOption.css';

export interface RadioGroupOption {
  value: string;
  label?: React.ReactNode | string;
  description?: string;
  disabled?: boolean;
  onHover?: MouseEventHandler<HTMLDivElement>;
  tooltipContent?: React.ReactNode;
}

interface IProps {
  options: RadioGroupOption[];
  value: string;
  onValueChange: (newValue: string) => void;
  indicatorSide?: 'left' | 'right';
}

export type RadioGroupProps = RadioGroupPrimitive.RadioGroupProps & Variants & IProps;

export const RadioGroup = ({
  options,
  variant,
  value: selectedValue,
  orientation = 'vertical',
  indicatorSide = 'left',
  ...props
}: RadioGroupProps) => (
  <RadioGroupPrimitive.Root
    {...props}
    className={style.group({ orientation, spacing: variant === 'compact' ? 'compact' : 'default' })}
  >
    {options.map(({ value, label, description, disabled, tooltipContent }) => (
      <RadioOption
        tooltipContent={tooltipContent}
        orientation={orientation}
        key={value}
        variant={variant}
        value={value}
        checked={value === selectedValue}
        disabled={disabled || props.disabled}
        label={label ?? value}
        description={description}
        indicatorSide={indicatorSide}
      />
    ))}
  </RadioGroupPrimitive.Root>
);
