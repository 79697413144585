import React from 'react';

import { BaseGroupOption } from './types';
import { CheckboxOption } from './CheckboxOption';
import { OptionControls } from './OptionControls';
import { useOptionSelection } from './useOptionSelection';
import { NoResults } from './NoResults';
import { BaseOptionDropdownLayout } from './BaseOptionDropdownLayout';
import { useDebouncedSearchOptions } from './useDebouncedSearchOptions';
import { GroupSection } from './GroupSection';
import { useGroupedOptions } from './useGroupedOptions';

interface Props<Option extends BaseGroupOption> {
  availableOptions: Option[];
  setSelectedOptions: (options: Option[]) => void;
  selectedOptions: Option[];
  resetSelection?: () => void;
  selectionDescription?: string;
  children: React.ReactNode;
  renderCheckboxIcon?: (option: Option) => React.ReactNode;
}

export const GroupMultiOptionDropdown = <Option extends BaseGroupOption>({
  availableOptions,
  selectedOptions,
  setSelectedOptions,
  children,
  resetSelection,
  selectionDescription,
  renderCheckboxIcon,
}: Props<Option>) => {
  const { searchTerm, setSearchTerm, options } = useDebouncedSearchOptions<typeof availableOptions[0]>(
    availableOptions,
    selectedOptions,
  );
  const onSelect = useOptionSelection<typeof selectedOptions[0]>({ selectedOptions, setSelectedOptions });
  const { selectedOptionsGroup, groups } = useGroupedOptions(options);

  return (
    <BaseOptionDropdownLayout setSearchTerm={setSearchTerm} trigger={children}>
      <OptionControls
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        resetSelection={resetSelection}
        selectionDescription={selectionDescription}
      />
      {selectedOptionsGroup.filter((option) => !option.hidden).length < 1 ? null : (
        <GroupSection groupName="Selected columns">
          {selectedOptionsGroup.map((option) => (
            <CheckboxOption<typeof option>
              key={option.key}
              option={option}
              onClick={onSelect}
              optionIcon={renderCheckboxIcon?.(option)}
            />
          ))}
        </GroupSection>
      )}
      {groups.map((group) => {
        if (!group?.groupDisplayName || !group.groupKey) {
          return null;
        }
        return (
          <GroupSection groupName={group.groupDisplayName} key={group.groupKey}>
            {group.options.map((option, index) => (
              <CheckboxOption<typeof option>
                key={`${option.key}-${index}`}
                option={option}
                onClick={onSelect}
                optionIcon={renderCheckboxIcon?.(option)}
              />
            ))}
          </GroupSection>
        );
      })}
      {!options.length && <NoResults />}
    </BaseOptionDropdownLayout>
  );
};
