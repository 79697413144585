import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

export const useNavClickCallback = <T extends HTMLElement>(href?: string, onClick?: React.MouseEventHandler<T>) => {
  const history = useHistory();

  return useMemo(() => {
    if (!href && !onClick) {
      return undefined;
    }
    return (event: React.MouseEvent<T>) => {
      if (href) {
        if (event.metaKey || event.ctrlKey) {
          window.open(href, '_blank');
        } else {
          history.push(href);
        }
      }
      if (onClick) {
        return onClick(event);
      }
      return undefined;
    };
  }, [href, onClick, history]);
};

export const useInsertVariable = <T extends HTMLTextAreaElement | HTMLInputElement>(
  inputRef: React.RefObject<T>,
  handleChange: (e: React.ChangeEvent<T>) => void,
) => {
  return useCallback(
    (variable: string) => {
      if (!inputRef.current) {
        return;
      }

      const templatedValue = `{{${variable}}}`;

      const selectionStart = inputRef.current.selectionStart ?? 0;
      const selectionEnd = inputRef.current.selectionEnd ?? 0;
      const newValue = [
        inputRef.current.value.slice(0, selectionStart),
        templatedValue,
        inputRef.current.value.slice(selectionEnd),
      ].join('');

      inputRef.current.value = newValue;
      inputRef.current.setSelectionRange(
        selectionStart + templatedValue.length,
        selectionStart + templatedValue.length,
      );
      inputRef.current.focus();
      handleChange?.({ target: inputRef.current } as React.ChangeEvent<T>);
    },
    [inputRef, handleChange],
  );
};

/**
 * It's a copy from the src/hooks/useDebounced.ts.
 * Don't have time to introduce a new shared package, but I believe it's a correct way forward.
 */
const DEFAULT_DEBOUNCE = 500;

export const useDebounced = <T,>(value: T, debounceValue = DEFAULT_DEBOUNCE, leading = true) => {
  const [debouncedValue, updateDebouncedValue] = useState(value);
  const setDebouncedValue = useMemo(
    () => debounce(updateDebouncedValue, debounceValue, { leading }),
    [debounceValue, updateDebouncedValue],
  );

  useEffect(() => {
    setDebouncedValue(value);
  }, [value, setDebouncedValue]);

  return debouncedValue;
};
