import React, { useCallback } from 'react';
import { GenericContainerComponentProps } from '@mutiny-pkg/dumpster-ui/utils/sharedTypes';
import * as style from './SearchContainer.css';
import { TextInput } from '../TextInput/TextInput';

export type ArrowKeysT = 'ArrowUp' | 'ArrowDown' | 'ArrowLeft' | 'ArrowRight';
type SelectSearchContainerProps = GenericContainerComponentProps &
  React.ComponentProps<typeof TextInput> & {
    onSearchNavigation?: (key: ArrowKeysT) => void;
    placeholder?: string;
  };

export const SelectSearchContainer = ({
  onSearchNavigation,
  children,
  placeholder = 'Search options',
  ...searchBarProps
}: SelectSearchContainerProps) => {
  const keyDownHandler = useCallback(
    (e: React.KeyboardEvent) => {
      const arrowKeys: ArrowKeysT[] = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
      if (arrowKeys.includes(e.key as ArrowKeysT)) {
        onSearchNavigation?.(e.key as ArrowKeysT);
      }
      e.stopPropagation();
    },
    [onSearchNavigation],
  );

  return (
    <div className={style.container} onKeyDown={keyDownHandler}>
      <TextInput placeholder={placeholder} fullWidth autoFocus {...searchBarProps} type="search" />
      {children}
    </div>
  );
};
