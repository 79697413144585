import React from 'react';
import { Sprinkles } from '@mutiny-pkg/dumpster-ui/theme/sprinkles.css';
import { Box } from '../Box';
import * as style from './ButtonGroup.css';

type ButtonGroupSprinkleProps = Pick<
  Sprinkles,
  | 'display'
  | 'gap'
  | 'padding'
  | 'paddingX'
  | 'paddingY'
  | 'alignItems'
  | 'justifyContent'
  | 'margin'
  | 'marginBottom'
  | 'marginLeft'
  | 'marginRight'
  | 'marginTop'
>;

export type ButtonGroupProps = ButtonGroupSprinkleProps &
  Omit<React.HTMLAttributes<HTMLDivElement>, keyof NonNullable<Sprinkles>> &
  NonNullable<style.ButtonGroupVariants>;

export const ButtonGroup = ({
  orientation = 'horizontal',
  buttonGap = 'standard',
  display = 'inline-flex',
  wrappable = false,
  children,
  ...props
}: React.PropsWithChildren<ButtonGroupProps>) => {
  return (
    <Box className={style.buttonGroup({ orientation, buttonGap, wrappable })} display={display} {...props}>
      {children}
    </Box>
  );
};
