import withStyles, { StyleRules, StyleRulesCallback } from '@material-ui/core/styles/withStyles';

import { IMutinyTheme } from '../MuiTheme';

function styleFactory(stylesFn: any) {
  const stylesResult = (theme: any, props: any) => {
    const styles = stylesFn(theme, props);
    return styles as StyleRules;
  };

  return stylesResult as StyleRulesCallback<any, any>;
}

interface IStyledComponent {
  innerRef?: React.Ref<any> | React.RefObject<any>;
}

interface IStyleObjectWithClass {
  [k: string]: {
    [k: string]: any;
  };
}
type StylesWithTheme = (theme: IMutinyTheme) => IStyleObjectWithClass;

type StylesArg = StylesWithTheme | IStyleObjectWithClass;

/** @deprecated Use classnames via vanilla-extract instead */
export default (styles: StylesArg) =>
  function Styles<T>(
    Component: T,
  ): React.ComponentType<
    Pick<GetComponentProps<T>, Exclude<keyof GetComponentProps<T>, keyof IStyleProps>> &
      IStyledComponent & { classes?: IStyleClasses }
  > {
    if (typeof styles === 'function') {
      return withStyles(styleFactory(styles))(Component as any) as any;
    }
    return withStyles(() => styles as IStyleObjectWithClass)(Component as any) as any;
  };

/** @deprecated Use classnames via vanilla-extract instead */
export interface IStyleClasses {
  [k: string]: string;
}

/** @deprecated Use classnames via vanilla-extract instead */
export interface IStyleProps {
  classes: IStyleClasses;
}
