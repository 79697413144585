import { ButtonVariantTypes, ButtonVariantEnum, ButtonSizeEnum } from '@mutiny-pkg/dumpster-ui';

export const mapLegacyButtonVariant = (buttonVariant?: string): ButtonVariantTypes['variant'] => {
  let variant: ButtonVariantTypes['variant'];
  switch (buttonVariant) {
    case 'danger':
    case 'secondary-danger':
    case 'transparent-danger':
      variant = ButtonVariantEnum.destructive;
      break;
    case 'primary':
    case 'purple-dark':
    case 'primary-light':
      variant = ButtonVariantEnum.primary;
      break;
    case 'tertiary':
      variant = ButtonVariantEnum.tertiary;
      break;
    case 'secondary':
    case 'white':
    case 'gray':
    case 'blue':
    case 'purple':
    case 'green':
      variant = ButtonVariantEnum.secondary;
      break;
    case 'transparent':
    case 'transparent-active':
      variant = ButtonVariantEnum.text;
      break;
    default:
      variant = ButtonVariantEnum.primary;
  }
  return variant;
};

export const mapLegacyButtonSize = (buttonSize?: string): ButtonVariantTypes['size'] => {
  let size: ButtonVariantTypes['size'];
  if (buttonSize === 'small') {
    size = ButtonSizeEnum.small;
  } else if (buttonSize === 'minimal') {
    size = ButtonSizeEnum.minimal;
  } else {
    size = ButtonSizeEnum.large;
  }
  return size;
};
