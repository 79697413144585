import * as React from 'react';
import { SVGProps } from 'react';
const SvgMicrophone2 = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.25 8.5a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM8.591 18.591a2.25 2.25 0 1 1-3.182-3.182M11.5 9.32l-5.992 5.992M14.68 12.5l-6.055 6.055M13 6l5 5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgMicrophone2;
