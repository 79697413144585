import React from 'react';
import * as PrimitiveSlider from '@radix-ui/react-slider';
import * as styles from './Slider.css';

export type SliderProps = PrimitiveSlider.SliderProps;

export const Slider = ({ ...props }: SliderProps) => {
  return (
    <PrimitiveSlider.Root {...props} className={styles.root}>
      <PrimitiveSlider.Track className={styles.track}>
        <PrimitiveSlider.Range className={styles.range} />
      </PrimitiveSlider.Track>

      <PrimitiveSlider.Thumb className={styles.thumb} />
    </PrimitiveSlider.Root>
  );
};
