import React from 'react';
import { Sprinkles } from '@mutiny-pkg/dumpster-ui/theme/sprinkles.css';
import { Box } from '../Box';

type SpacingSprinklesProps = Pick<
  Sprinkles,
  | 'margin'
  | 'marginTop'
  | 'marginRight'
  | 'marginBottom'
  | 'marginLeft'
  | 'marginX'
  | 'marginY'
  | 'padding'
  | 'paddingTop'
  | 'paddingRight'
  | 'paddingBottom'
  | 'paddingLeft'
  | 'paddingX'
  | 'paddingY'
>;
type SpacingProps = SpacingSprinklesProps & {
  children?: React.ReactNode;
  height?: string;
  width?: string;
  flex?: string | number;
};

export const Spacing = ({ children, height, width, flex, ...props }: SpacingProps) => {
  return (
    <Box {...props} style={{ height, width, flex }}>
      {children}
    </Box>
  );
};
