import React from 'react';
import { SelectInput } from '../Select/SelectInput';
import * as Dropdown from './Dropdown';
import { ItemLayout } from './Dropdown.css';
import { Flex } from '../Flex';
import * as style from './SingleSelect.css';
import { IconType } from '../../icons';

export interface SingleSelectOption {
  value: string;
  label?: string;
  description?: string;
  icon?: IconType;
}

export interface SingleSelectProps extends Dropdown.ContentProps {
  options: SingleSelectOption[];
  value: string | null | undefined;
  onValueChange: (newValue: string) => void;
  label?: string;
  placeholder?: string;
  asChild?: boolean;
  disabled?: boolean;
  itemLayout?: ItemLayout;
}

export const SingleSelect = ({
  children,
  asChild = children == null,
  options,
  value: selectedValue,
  onValueChange,
  label,
  placeholder,
  disabled,
  itemLayout = 'column',
  ...contentProps
}: SingleSelectProps) => {
  const selectedOption = children == null ? options.find((o) => o.value === selectedValue) : null;

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild={asChild} disabled={disabled}>
        {children ?? (
          <SelectInput
            label={label}
            disabled={disabled}
            hasSelection={!!selectedOption}
            data-testid="single-select-trigger"
          >
            <Flex.Container justifyContent="flex-start" alignItems="center" gap="4u">
              {selectedOption?.icon && <selectedOption.icon className={style.icon} />}
              {selectedOption ? selectedOption.label ?? selectedOption.value : placeholder}
            </Flex.Container>
          </SelectInput>
        )}
      </Dropdown.Trigger>
      <Dropdown.Content {...contentProps}>
        <Dropdown.RadioGroup value={selectedValue ?? undefined} onValueChange={onValueChange}>
          {options.map(({ value, label: optionLabel, description, icon }) => {
            const Icon = icon;
            return (
              <Dropdown.RadioItem key={value} value={value} layout={itemLayout}>
                <Flex.Container justifyContent="flex-start" alignItems="center" gap="4u">
                  {Icon && <Icon className={style.icon} />}
                  <div className={(!!description && style.titleWithDescription) || style.title}>
                    {optionLabel ?? value}
                  </div>
                </Flex.Container>
                {!!description && <div className={style.description}>{description}</div>}
              </Dropdown.RadioItem>
            );
          })}
        </Dropdown.RadioGroup>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
