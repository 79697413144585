import * as React from 'react';
import { SVGProps } from 'react';
const SvgBold = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.75 4.75h5.75a3.75 3.75 0 1 1 0 7.5H6.75v-7.5ZM6.75 12.25h7a3.5 3.5 0 1 1 0 7h-7v-7Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgBold;
