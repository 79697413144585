import React from 'react';
import * as styles from './Message.css';

export type MessageVariant = styles.MessageVariant;

export type MessageProps = {
  children?: React.ReactNode;
  active?: boolean;
  variant?: MessageVariant;
  disabled?: boolean;
};

export const Message = ({ active, variant = 'default', disabled, children }: MessageProps) => {
  if (!active || !children) {
    return null;
  }

  const styleVariant = disabled ? 'disabled' : variant;

  return <span className={styles.messageVariants[styleVariant]}>{children}</span>;
};
