import * as React from 'react';

const Search: React.FC = () => (
  <svg
    width="15px"
    height="15px"
    color="#221436"
    viewBox="0 0 15 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Artboard" fill="currentcolor" fillRule="nonzero">
      <path
        d="M14.4185741,12.3595032 L11.5601126,9.57250318 L11.5601124,9.57250354 C13.4963708,6.74653954 12.716377,2.92522354 9.81795235,1.03738354 C6.91952774,-0.850468458 3.00022927,-0.0899744583 1.06398312,2.73598954 C-0.872275342,5.56195354 -0.0922814957,9.38326954 2.80614312,11.2711095 C4.92909081,12.6538635 7.69736158,12.6533835 9.81980466,11.2699012 L12.6782662,14.0563012 L12.6782662,14.0563012 C13.1622951,14.5166518 13.9345431,14.5166518 14.4185739,14.0563012 L14.4185739,14.0563012 C14.8990441,13.5877012 14.8990447,12.8281012 14.4185742,12.3595012 L14.4185741,12.3595032 Z M6.31765106,1.80790318 L6.31765086,1.80790318 C8.78168778,1.80790318 10.7791893,3.75546718 10.7791893,6.15790318 C10.7791893,8.56033918 8.78168778,10.5079032 6.31765086,10.5079032 C3.85361394,10.5079032 1.85611235,8.56033918 1.85611235,6.15790318 L1.85611235,6.15794548 C1.85880153,3.75660148 3.85469081,1.81057348 6.31760774,1.80790318 L6.31765106,1.80790318 Z"
        id="Shape"
      />
    </g>
  </svg>
);

export default Search;
