/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useRef, useEffect, useState, ReactNode, useMemo } from 'react';
import { Editor, Transforms, Range, Descendant, BaseElement, createEditor, Node } from 'slate';
import { Slate, Editable, ReactEditor, useSelected, useFocused, withReact } from 'slate-react';
import ReactDOM from 'react-dom';
import { vars } from '@mutiny-pkg/dumpster-ui/theme/theme.css';
import * as styles from './VariablesTextInput.css';
import { Variable } from '../Dropdown/VariableDropdown';

import * as dropdownStyles from '../Dropdown/Dropdown.css';

const VARIABLES_TRIGGER = '@';

export type CustomText = {
  bold?: boolean;
  italic?: boolean;
  code?: boolean;
  text: string;
};

export type VariableElement = BaseElement & {
  type: 'variable';
  variable: string;
  key: string;
  children: Descendant[];
};

export type VariablesTextInputProps = {
  editor: ReactEditor;
  variables: Variable[];
  initialValue: DescendantType[];
  placeholder?: string;
  onValueChange?: (value: Descendant[]) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  handleSubmit?: () => void;
};

export const Portal = ({ children }: { children?: ReactNode }) => {
  return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null;
};

export const VariablesTextInput = ({
  editor,
  variables,
  initialValue,
  placeholder,
  onValueChange,
  onBlur,
  onFocus,
  handleSubmit,
}: VariablesTextInputProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [target, setTarget] = useState<Range | undefined>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const chars = variables.filter((c) => c.name.toLowerCase().startsWith(search.toLowerCase())).map((c) => c.name);
  const getVariableKey = (variableLabel: string) => {
    const variable = variables.find((v) => v.name === variableLabel);
    return variable ? variable.value : '';
  };

  const onChangeEditor = (newChildren: Descendant[]) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);
      // support middle of the text
      const wordBeforePoint = Editor.before(editor, start, { unit: 'word' });
      const beforeWord = wordBeforePoint && Editor.before(editor, wordBeforePoint);
      const beforeWordRange = beforeWord && Editor.range(editor, beforeWord, start);
      const beforeWordText = beforeWordRange && Editor.string(editor, beforeWordRange).trim();

      // support first character of the text
      const charBeforePoint = Editor.before(editor, start, { unit: 'character' });
      const charRange = charBeforePoint && Editor.range(editor, charBeforePoint, start);
      const charBefore = charRange && Editor.string(editor, charRange);

      const beforeMatch =
        (beforeWordText &&
          beforeWordText.match(new RegExp(`(?:^|[^${VARIABLES_TRIGGER}\\w])?${VARIABLES_TRIGGER}(\\w+)?`))) ||
        (!beforeWordText && charBefore && charBefore.match(new RegExp(VARIABLES_TRIGGER)));

      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if (beforeMatch && afterMatch) {
        setTarget(beforeWordRange || charRange);
        setSearch(beforeMatch[1] ?? '');
        setIndex(0);
        return;
      }
    }

    setTarget(undefined);

    const ops = editor.operations.filter((o) => {
      if (o) {
        return o.type !== 'set_selection';
      }
      return false;
      // dont want to trigger onChange on set_selection
    });

    if (ops && Array.isArray(ops) && ops.length > 0) {
      onValueChange?.(editor.children);
      editor.children = newChildren;
    }
  };

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.metaKey && event.key === 'Enter' && handleSubmit) {
        event.preventDefault();
        handleSubmit?.();
      }
      if (target && chars.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case 'Tab':
          case 'Enter':
            event.preventDefault();
            Transforms.select(editor, target);
            insertVariable(editor, chars[index] ?? '', getVariableKey(chars[index] ?? ''));
            setTarget(undefined);
            break;
          case 'Escape':
            event.preventDefault();
            setTarget(undefined);
            break;
          default:
            break;
        }
      }
    },
    [chars, editor, index, target],
  );

  useEffect(() => {
    if (target && chars.length > 0) {
      const el = ref.current;
      if (!el) {
        return;
      }
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [chars.length, editor, index, search, target]);

  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      onChange={(value: Descendant[]) => {
        onChangeEditor(value);
      }}
    >
      <Editable
        className={styles.textStyles}
        renderElement={renderElement}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {target && chars.length > 0 && (
        <Portal>
          <div
            ref={ref}
            className={dropdownStyles.content}
            style={{
              top: '-9999px',
              left: '-9999px',
              position: 'absolute',
              zIndex: 9999,
            }}
          >
            {chars.map((char, i) => (
              <div
                key={char}
                onClick={() => {
                  Transforms.select(editor, target);
                  insertVariable(editor, chars[i] ?? '', getVariableKey(chars[i] ?? ''));
                  setTarget(undefined);
                }}
                className={dropdownStyles.item({ colorTheme: 'default' })}
                style={{
                  background: i === index ? vars.background.accent : 'transparent',
                  color: vars.color.default,
                }}
              >
                {char}
              </div>
            ))}
          </div>
        </Portal>
      )}
    </Slate>
  );
};

export const withVariables = (editor: ReactEditor) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element: any) => {
    return element.type === 'variable' ? true : isInline(element);
  };

  editor.isVoid = (element: any) => {
    return element.type === 'variable' ? true : isVoid(element);
  };

  editor.markableVoid = (element: any) => {
    return element.type === 'variable' || markableVoid(element);
  };

  return editor;
};

export const insertVariable = (editor: ReactEditor, variableLabel: string, variableKey: string) => {
  const variable: VariableElement = {
    type: 'variable',
    variable: variableLabel,
    key: variableKey,
    children: [{ text: `{${variableKey}}` }],
  };
  Transforms.insertNodes(editor, variable);
  Transforms.move(editor);
};

type ElementProps = {
  attributes: any;
  children: ReactNode[];
  element: VariableElement;
};

const Element = (props: ElementProps) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'variable':
      return <VariableComponent {...props} />;
    default:
      return (
        <p {...attributes} className="m-0">
          {children}
        </p>
      );
  }
};

const VariableComponent = ({ attributes, children, element }: ElementProps) => {
  const selected = useSelected();
  const focused = useFocused();
  const style: React.CSSProperties = {
    padding: '2px 4px',
    margin: '0 1px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    borderRadius: '4px',
    background: '#e3e0e4',
    color: vars.color.muted,
    fontWeight: vars.fontWeight.semibold,
    fontSize: vars.fontSize.caption1,
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
    userSelect: 'none',
  };
  return (
    <span {...attributes} contentEditable={false} style={style}>
      {element.variable}
      {children}
    </span>
  );
};

type DescendantElement = Descendant | VariableElement;

type ParagraphElement = {
  type: 'paragraph';
  children: DescendantElement[];
};

export type DescendantType = ParagraphElement | VariableElement;

export const useVariablesTextInput = () => {
  const editor = useMemo(() => withVariables(withReact(createEditor())), []);
  const getPlainText = (value: Descendant[]) => {
    return value.map((node) => Node.string(node)).join('\n');
  };

  const clearEditor = () => {
    const point = { path: [0, 0], offset: 0 };
    editor.selection = { anchor: point, focus: point };
    editor.children = [
      {
        children: [{ text: '' }],
      },
    ];
  };
  const replaceText = (text: string) => {
    const point = { path: [0, 0], offset: 0 };
    editor.selection = { anchor: point, focus: point };
    editor.children = [
      {
        children: [{ text }],
      },
    ];
  };
  const setText = (text: string) => {
    editor.children = [
      {
        children: [{ text }],
      },
    ];
  };
  return { editor, insertVariable, getPlainText, clearEditor, replaceText, setText };
};
