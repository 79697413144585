import * as React from 'react';
import { SVGProps } from 'react';
const SvgEraser = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 15.25 4.75 13l6.543-6.543a1 1 0 0 1 1.414 0l2.836 2.836a1 1 0 0 1 0 1.414L11 15.25H7ZM12.75 19.25h6.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgEraser;
