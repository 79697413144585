import * as React from 'react';
import { SVGProps } from 'react';
const SvgAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 14.25a.75.75 0 0 0 0 1.5v-1.5Zm.01 1.5a.75.75 0 0 0 0-1.5v1.5Zm-.01 0h.01v-1.5H12v1.5ZM10.403 5.411l.53.53-.53-.53Zm-4.992 4.992-.53-.53.53.53Zm0 3.194.53-.53-.53.53Zm4.992 4.992.53-.53-.53.53Zm3.194 0 .53.53-.53-.53Zm4.992-8.186.53-.53-.53.53Zm-4.992-4.992.53-.53-.53.53Zm-3.724-.53L4.881 9.873l1.06 1.06 4.993-4.991L9.873 4.88Zm-4.992 9.246 4.992 4.992 1.06-1.06-4.991-4.993-1.061 1.061Zm9.246 4.992 4.992-4.992-1.06-1.06-4.993 4.991 1.061 1.061Zm4.992-9.246-4.992-4.992-1.06 1.06 4.991 4.993 1.061-1.061Zm0 4.254a3.008 3.008 0 0 0 0-4.254l-1.06 1.06a1.508 1.508 0 0 1 0 2.133l1.06 1.061Zm-9.246 4.992a3.008 3.008 0 0 0 4.254 0l-1.06-1.06a1.508 1.508 0 0 1-2.133 0l-1.061 1.06ZM4.881 9.873a3.008 3.008 0 0 0 0 4.254l1.06-1.06a1.508 1.508 0 0 1 0-2.133l-1.06-1.061Zm6.053-3.931a1.508 1.508 0 0 1 2.132 0l1.061-1.061a3.008 3.008 0 0 0-4.254 0l1.06 1.06Z"
      fill="currentColor"
    />
    <path d="M12 8.75v3.5" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default SvgAlert;
