import React from 'react';

import { Flex, ToggleGroup, ToggleGroupItem } from '@mutiny-pkg/dumpster-ui';
import { Group } from './types';

type Props = {
  groups: Group[];
  selectedGroup: string;
  setSelectedGroup: (group: string) => void;
};

export const VariableToggleGroup = ({ groups, selectedGroup, setSelectedGroup }: Props) => {
  const hasGroups = groups.length > 0;
  if (!hasGroups) {
    return null;
  }
  return (
    <Flex.Container paddingX="8u">
      <ToggleGroup value={selectedGroup} onValueChange={(value) => setSelectedGroup(value)} fullWidth>
        {groups.map((group) => (
          <ToggleGroupItem key={group.value} value={group.value} fullWidth>
            {group.name}
          </ToggleGroupItem>
        ))}
      </ToggleGroup>
    </Flex.Container>
  );
};
