import React from 'react';
import { Typography, Flex, Spacing } from '@mutiny-pkg/dumpster-ui/components';

type Props = {
  children: React.ReactNode;
  groupName: string;
};

export const GroupSection = ({ children, groupName }: Props) => {
  return (
    <Flex.Container flexDirection="column">
      <Spacing paddingTop="6u" paddingX="8u" paddingBottom="2u">
        <Typography as="span" font="caption1" color="secondary">
          {groupName}
        </Typography>
      </Spacing>
      {children}
    </Flex.Container>
  );
};
