import * as React from 'react';
import { SVGProps } from 'react';
const SvgTrophy = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.75 4.75h8.5V11a4.25 4.25 0 0 1-8.5 0V4.75ZM16.5 6.75h.104a2.646 2.646 0 0 1 .904 5.133l-1.008.367M7.5 6.75h-.104a2.646 2.646 0 0 0-.904 5.133l1.008.367M12 15.5V19M8.75 19.25h6.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgTrophy;
