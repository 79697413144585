import * as React from 'react';
import { SVGProps } from 'react';
const SvgBuildings = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.75 7.775h1.5m-1.5 3h1.5m5.5 3h1.5m-1.5-3h1.5m-4 8.475V5.75a1 1 0 0 0-1-1h-5.5a1 1 0 0 0-1 1v12.5a1 1 0 0 0 1 1h6.5Zm0 0h6a1 1 0 0 0 1-1v-9.5a1 1 0 0 0-1-1h-5.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgBuildings;
