import cx from 'classnames';
import { useRedesign } from '@mutiny-pkg/dumpster-ui/contexts/RedesignContext';
import * as React from 'react';
import { Box } from '@mutiny-pkg/dumpster-ui/components';
import { Root, Content, Body } from '@mutiny-pkg/dumpster-ui/components/FeedbackBlock';

import WithStyles, { IStyleClasses, IStyleProps } from '../higher_order/WithStyles';

interface IProps extends IStyleProps {
  color?: 'primary' | 'error' | 'success';
  children?: React.ReactNode;
}

type IAlertProps = React.HTMLAttributes<HTMLDivElement> & IProps;

const Alert: React.FC<IAlertProps> = ({ classes, children, color, ...props }) => {
  const finalColor = color || 'error';
  return (
    <div {...props} className={cx(classes.alert, classes[finalColor], props.className)}>
      {children}{' '}
    </div>
  );
};

const StyledAlert = WithStyles((theme) => ({
  alert: {
    borderRadius: '5px',
    color: theme.colors.white,
    fontSize: '13px',
    margin: '10px 0',
    padding: '12px',
  },
  error: {
    backgroundColor: theme.colors.error,
  },
  primary: {
    backgroundColor: theme.colors.action,
  },
  success: {
    backgroundColor: theme.colors.success,
  },
}))(Alert);

export default function (
  props: Pick<IAlertProps, Exclude<keyof IAlertProps, 'classes'>> & { classes?: IStyleClasses },
) {
  const { redesignEnabled } = useRedesign();

  if (redesignEnabled) {
    // Default to error to maintain old behavior
    const type = props.color === 'primary' ? 'info' : props.color === 'success' ? 'confirmation' : 'error';

    return (
      <Box marginBottom="8u">
        <Root variant="card" fullWidth type={type} hideClose>
          <Content>
            <Body>{props.children}</Body>
          </Content>
        </Root>
      </Box>
    );
  }

  return <StyledAlert {...props} />;
}
