import React from 'react';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import { ReactEditor } from 'slate-react';
import cx from 'classnames';
import { Card } from '../Card';
import { DescendantType, VariablesTextInput, useVariablesTextInput } from './VariablesTextInput';
import { Button, IconButton } from '../Button';
import { Container, Item } from '../Flex/Flex';
import { Typography } from '../Typography';
import { vars } from '../../theme/theme.css';
import { Variable, VariableDropdown } from '../Dropdown/VariableDropdown';
import { styles } from './TweakPromptInput.css';

export type TweakPromptInputProps = {
  editor: ReactEditor;
  variables: Variable[];
  onSubmit: () => void;
  children?: React.ReactNode;
  initialValue?: DescendantType[];
  animateIn?: boolean;
};

export const TweakPromptInput = ({
  editor,
  variables,
  onSubmit,
  initialValue,
  children,
  animateIn = true,
}: TweakPromptInputProps) => {
  const { insertVariable } = useVariablesTextInput();
  return (
    <div className={cx(styles({ animateIn }))}>
      <Container flexWrap="wrap" flexDirection="row" alignItems="center" gap="2u">
        <Icons.AiBolt style={{ color: vars.color.electricPurple }} />
        <Typography font="body1" fontWeight="semibold">
          What else would you change?
        </Typography>
      </Container>
      <Card variant="shadowed" style={{ padding: '12px', columnGap: '8px', rowGap: '8px', borderRadius: '8px' }}>
        <Container style={{ width: '100%' }} gap="4u" flexDirection="row" alignItems="flex-end">
          {children}
        </Container>
        <Container
          flexWrap="wrap"
          style={{ width: '100%' }}
          gap="1px"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Item style={{ flexGrow: 1, maxWidth: '100%' }}>
            <VariablesTextInput
              placeholder="Ask follow-up, mention account properties with @"
              editor={editor}
              variables={variables}
              initialValue={initialValue ?? [{ type: 'paragraph', children: [{ text: '' }] }]}
              handleSubmit={onSubmit}
            />
          </Item>
          <Item>
            <Container flexWrap="nowrap" gap="2u" flexDirection="row" alignItems="center">
              <Item xs={1}>
                <VariableDropdown
                  variables={variables}
                  onVariableSelected={(variable) => {
                    insertVariable(editor, variable, variable);
                  }}
                >
                  <IconButton variant="transparent" icon={Icons.Plus} size="small" label="" />
                </VariableDropdown>
              </Item>
              <Item xs={2}>
                <Button
                  variant="transparent"
                  size="small"
                  iconLeft={Icons.Send}
                  style={{ width: '100%' }}
                  onClick={onSubmit}
                >
                  Submit
                </Button>
              </Item>
            </Container>
          </Item>
        </Container>
      </Card>
    </div>
  );
};
