import React from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import * as style from './Badge.css';
import { IconType, Icons } from '../../icons';

export type BadgeVariantTypes = NonNullable<style.BadgeVariants>;

export type BadgeProps = Omit<React.HTMLAttributes<HTMLElement>, 'className'> &
  style.BadgeVariants & {
    disabled?: boolean;
    iconLeft?: IconType;
    iconRight?: IconType;
    iconOnly?: boolean;
  };

export type IconBadgeProps = Omit<BadgeProps, 'iconLeft' | 'iconRight' | 'iconOnly'> & {
  label: string;
  icon: IconType;
  children?: never;
};

const spacingValue = (variant: BadgeProps['variant'], iconOnly?: BadgeProps['iconOnly']) => {
  if (variant === 'numerical') {
    return 'small';
  }
  return iconOnly ? 'extraSmall' : 'medium';
};

const buttonEventHandlers = {
  onMouseDown: (e: React.MouseEvent) => e.preventDefault(),
};

export const Badge = React.forwardRef<HTMLButtonElement, BadgeProps>(
  (
    {
      variant,
      rounded,
      size,
      iconOnly = false,
      asButton,
      iconLeft,
      iconRight,
      disabled = false,
      children,
      ...props
    }: BadgeProps,
    forwardedRef,
  ) => {
    const sizeWithDefault = variant === 'numerical' ? 'extraSmall' : size;
    const LeadingIcon = !iconOnly && (iconLeft ?? (variant === 'recommended' && Icons.AiBolt));
    const TrailingIcon = !iconOnly && iconRight;
    const spacing = spacingValue(variant, iconOnly);
    const iconVariant = variant === 'recommended' ? 'recommended' : 'default';

    if (asButton) {
      return (
        <button
          type="button"
          disabled={disabled}
          ref={forwardedRef}
          {...buttonEventHandlers}
          {...props}
          className={style.badge({ variant, size: sizeWithDefault, spacing, asButton, rounded })}
        >
          {LeadingIcon && <LeadingIcon className={style.badgeIcon[iconVariant]} />}
          {children}
          {TrailingIcon && <TrailingIcon className={style.badgeIcon.default} />}
        </button>
      );
    }
    return (
      <div {...props} className={style.badge({ variant, size: sizeWithDefault, spacing, rounded })}>
        {LeadingIcon && <LeadingIcon className={style.badgeIcon[iconVariant]} />}
        {children}
        {TrailingIcon && <TrailingIcon className={style.badgeIcon.default} />}
      </div>
    );
  },
);

export const IconBadge = React.forwardRef<HTMLButtonElement, IconBadgeProps>(
  ({ icon, variant, rounded, label, ...props }: IconBadgeProps, forwardedRef): JSX.Element => {
    const Icon = icon;
    return (
      <Badge {...props} ref={forwardedRef} iconOnly>
        <AccessibleIcon.Root label={label}>
          <Icon className={style.badgeIcon.default} />
        </AccessibleIcon.Root>
      </Badge>
    );
  },
);
