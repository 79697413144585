import React from 'react';
import { Flex, Icons, Tooltip, Typography } from '../..';
import * as styles from './VariableDropdown.css';
import { Variable } from './types';

type Props = { scopedVariables: Variable[] };

export const AttributesHeader = ({ scopedVariables }: Props) => {
  const showCompleteness = React.useMemo(
    () => scopedVariables.some((v) => v.completeness !== undefined),
    [scopedVariables],
  );
  return (
    <Flex.Container justifyContent="space-between" className={styles.headerContainer}>
      <Typography color="secondary" font="caption1">
        Attributes
      </Typography>
      {showCompleteness && (
        <Tooltip
          align="start"
          content="For account/contact attributes, the percentage of members within the list that have this attribute present."
        >
          <Flex.Container gap="2u">
            <Typography color="secondary" font="caption1">
              Data completeness
            </Typography>
            <Icons.Info />
          </Flex.Container>
        </Tooltip>
      )}
    </Flex.Container>
  );
};
