import React from 'react';
import { Icons, IconType } from '@mutiny-pkg/dumpster-ui/icons';
import { Button, ButtonProps, IconButton } from '../Button/Button';
import * as Dropdown from './Dropdown';

export interface MenuProps extends Dropdown.ContentProps {
  label: string;
  icon?: IconType;
  children: React.ReactNode;
  disabled?: boolean;
  iconOnly?: boolean;
  buttonVariant?: ButtonProps['variant'];
  container?: HTMLElement;
  defaultOpen?: boolean;
  buttonProps?: Omit<ButtonProps, 'children'>;
  modal?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  open?: boolean;
}

export const Menu = ({
  label,
  icon,
  children,
  disabled,
  iconOnly = true,
  buttonVariant = 'secondary',
  container,
  defaultOpen = false,
  modal = true,
  buttonProps = {},
  ...contentProps
}: MenuProps) => {
  return (
    <Dropdown.Root
      defaultOpen={defaultOpen}
      modal={modal}
      open={contentProps.open}
      onOpenChange={contentProps.onOpenChange}
    >
      <Dropdown.Trigger asChild disabled={disabled}>
        {icon && iconOnly ? (
          <IconButton icon={icon} label={label} variant={buttonVariant} display="inline" {...buttonProps} />
        ) : (
          <Button variant={buttonVariant} iconLeft={icon} iconRight={Icons.ChevronDown} {...buttonProps}>
            {label}
          </Button>
        )}
      </Dropdown.Trigger>
      <Dropdown.Content container={container} {...contentProps}>
        {children}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export const { Item } = Dropdown;
