import * as Primitive from '@radix-ui/react-dropdown-menu';
import cx from 'classnames';
import React from 'react';
import { IconType } from '@mutiny-pkg/dumpster-ui/icons';
import * as style from './Dropdown.css';

export const { Root, Trigger, RadioGroup, Separator } = Primitive;

export type RootProps = Primitive.DropdownMenuProps;

export interface ContentProps extends Primitive.DropdownMenuContentProps {
  matchTriggerWidth?: boolean;
  container?: HTMLElement;
}

export const Content = React.forwardRef<HTMLDivElement, ContentProps>(
  ({ children, matchTriggerWidth, className, container, ...props }, forwardedRef) => {
    return (
      <Primitive.Portal container={container}>
        <Primitive.Content
          align="end"
          sideOffset={4}
          {...props}
          ref={forwardedRef}
          className={cx(style.content, matchTriggerWidth && style.contentTriggerWidth, className)}
        >
          {children}
        </Primitive.Content>
      </Primitive.Portal>
    );
  },
);

export type ItemProps = Primitive.DropdownMenuItemProps & {
  icon?: IconType;
  variant?: 'destructive';
};

export const Item = React.forwardRef<HTMLDivElement, ItemProps>(
  ({ children, onClick, icon: Icon, variant, disabled, ...props }, forwardedRef) => {
    return (
      <Primitive.Item
        {...props}
        ref={forwardedRef}
        className={style.item({ variant })}
        disabled={disabled}
        onClick={(event) => {
          event.stopPropagation();
          if (!disabled) {
            // do not preventDefault - that would stop the menu from automatically closing on item selection.
            // stopPropagation makes sure that we do not click on a parent element by clicking an item.
            onClick?.(event);
          }
        }}
      >
        {Icon && <Icon className={style.icon} />}
        {children}
      </Primitive.Item>
    );
  },
);

export interface RadioItemProps extends Primitive.DropdownMenuRadioItemProps {
  layout: style.ItemLayout;
}

export const RadioItem = React.forwardRef<HTMLDivElement, RadioItemProps & style.ItemVariants>(
  ({ children, layout, colorTheme = 'default', ...props }, forwardedRef) => {
    return (
      <Primitive.RadioItem {...props} ref={forwardedRef} className={style.item({ colorTheme })}>
        <div className={style.itemContent[layout]}>{children}</div>
      </Primitive.RadioItem>
    );
  },
);
