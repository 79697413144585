import cx from 'classnames';
import React from 'react';
import * as style from './NoticeDot.css';

interface Props {
  border: style.BorderVariant;
}

export const NoticeDot = ({ border }: Props) => {
  return <div className={cx(style.dot, style.border[border])} />;
};
