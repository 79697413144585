import React from 'react';
import { Dropdown } from '@mutiny-pkg/dumpster-ui/components';

type Props = {
  children: React.ReactNode;
  setSearchTerm: (searchTerm: string) => void;
  trigger?: React.ReactNode;
};

export const BaseOptionDropdownLayout = ({ children, setSearchTerm, trigger }: Props) => {
  return (
    <Dropdown.Root
      onOpenChange={() => {
        setSearchTerm('');
      }}
    >
      <Dropdown.Trigger asChild>{trigger}</Dropdown.Trigger>
      <Dropdown.Content style={{ width: '360px', overflowX: 'hidden' }} collisionPadding={80}>
        {children}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
