import React from 'react';
import * as styles from './FileUpload.css';
import Dropzone, { Accept, FileRejection } from 'react-dropzone';

import cx from 'classnames';
import { FileUploadContent } from './FileUploadContent';

type IProps = {
  onUpload: (file: File) => void;
  acceptedTypes: Accept;
};

export const FileUpload = ({ onUpload, acceptedTypes }: IProps) => {
  const [dragActive, setDragActive] = React.useState(false);
  const [error, setError] = React.useState('');
  const [inProgressFilePath, setInProgressFilePath] = React.useState('');

  const acceptedFilePathString = React.useCallback(() => {
    const allExtensions = Object.values(acceptedTypes).flat();

    if (allExtensions.length > 1) {
      return `${allExtensions.slice(0, -1).join(', ')}, and ${allExtensions.slice(-1)}`;
    }
    return allExtensions[0];
  }, [acceptedTypes]);

  const onDrop = async (acceptedFiles: File[], rejected: FileRejection[]) => {
    setError('');
    setDragActive(false);

    if (acceptedFiles.length > 1) {
      setError('Only one file can be uploaded at a time.');
      return;
    }

    if (rejected.length > 0) {
      setError(`Only ${acceptedFilePathString()} file extensions are supported.`);
      return;
    }

    const file = acceptedFiles[0];

    if (file) {
      try {
        setInProgressFilePath(file.name);
        await onUpload(file);
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setInProgressFilePath('');
      }
    }
  };

  return (
    <Dropzone
      onDrop={onDrop}
      onDragEnter={() => setDragActive(true)}
      onDragLeave={() => setDragActive(false)}
      accept={acceptedTypes}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <div
            className={cx(styles.dropzone, dragActive && styles.dropzoneActive, error && styles.dropZoneError)}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <FileUploadContent error={error} inProgressFilePath={inProgressFilePath} />
          </div>
        );
      }}
    </Dropzone>
  );
};
