import * as React from 'react';
import { SVGProps } from 'react';
const SvgUploadCloud = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.25 14.25S4.75 14 4.75 12a3.25 3.25 0 0 1 3.007-3.241 4.25 4.25 0 0 1 8.486 0A3.25 3.25 0 0 1 19.25 12c0 2-1.5 2.25-1.5 2.25"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.25 15.25 12 12.75l-2.25 2.5M12 19.25v-5.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgUploadCloud;
