import React from 'react';
import { BaseOption } from '@mutiny-pkg/dumpster-ui/components/Dropdown/OptionDropdown/types';
import _ from 'lodash';
import { useDebounced } from '@mutiny-pkg/dumpster-ui/utils';

export const filterOptionsBySearchTerm = <Option extends BaseOption>(options: Option[], searchTerm: string): Option[] =>
  options.filter((column) => column.displayName.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0);

export const mapAndSortEditOptions = <Option extends BaseOption>(allOptions: Option[], selectedOptions: Option[]) => {
  const selectedKeys = new Set(selectedOptions.map((column) => column.key));
  const notSelectedMapped = allOptions.filter((option) => !selectedKeys.has(option.key));
  return [...selectedOptions, ...notSelectedMapped].map((option) => ({
    ...option,
    selected: selectedKeys.has(option.key),
  }));
};

export const useDebouncedSearchOptions = <Option extends BaseOption>(
  availableOptions: Option[],
  selectedOptions: Option[],
) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounced(searchTerm);

  const editOptions = React.useMemo(
    () => mapAndSortEditOptions(availableOptions, selectedOptions),
    [availableOptions, selectedOptions],
  );

  const options = React.useMemo(
    () => filterOptionsBySearchTerm(editOptions, debouncedSearchTerm),
    [debouncedSearchTerm, editOptions],
  );
  return {
    searchTerm,
    setSearchTerm,
    options,
  };
};
