import * as React from 'react';
import { SVGProps } from 'react';
const SvgGalleryView = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.75 7.75a2 2 0 0 1 2-2h4.5a2 2 0 0 1 2 2v8.5a2 2 0 0 1-2 2h-4.5a2 2 0 0 1-2-2v-8.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.25 8.5a.75.75 0 0 0 0-1.5v1.5Zm0 9.5a.75.75 0 0 0 0-1.5V18Zm-.5-9.5h.5V7h-.5v1.5Zm.5 8h-.5V18h.5v-1.5ZM8.5 15.25v-5.5H7v5.5h1.5Zm1.25 1.25c-.69 0-1.25-.56-1.25-1.25H7A2.75 2.75 0 0 0 9.75 18v-1.5Zm0-9.5A2.75 2.75 0 0 0 7 9.75h1.5c0-.69.56-1.25 1.25-1.25V7Z"
      fill="currentColor"
    />
    <path
      d="M7.25 9.5a.75.75 0 0 0 0-1.5v1.5Zm0 7.5a.75.75 0 0 0 0-1.5V17Zm-.5-7.5h.5V8h-.5v1.5Zm.5 6h-.5V17h.5v-1.5ZM5.5 14.25v-3.5H4v3.5h1.5Zm1.25 1.25c-.69 0-1.25-.56-1.25-1.25H4A2.75 2.75 0 0 0 6.75 17v-1.5Zm0-7.5A2.75 2.75 0 0 0 4 10.75h1.5c0-.69.56-1.25 1.25-1.25V8Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgGalleryView;
