import * as React from 'react';
import { SVGProps } from 'react';
const SvgKeys = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.25 13.063A4.252 4.252 0 0 0 9 4.75a4.25 4.25 0 0 0-1.25 8.313V18a1.25 1.25 0 1 0 2.5 0v-4.937Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m12 12 5.345 3.074a1.247 1.247 0 0 0 1.734-.48c.35-.628.142-1.431-.465-1.793L13.5 9.5M9 8v.01"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgKeys;
