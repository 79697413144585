import * as React from 'react';
import { SVGProps } from 'react';
const SvgWrench = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.464 8.95a1 1 0 0 1 0-1.414l2.37-2.37a4.25 4.25 0 0 0-5.67 5.67l-6 6a1.414 1.414 0 1 0 2 2l6.001-6.001a4.25 4.25 0 0 0 5.68-5.648L16.48 9.55a1 1 0 0 1-1.414 0l-.602-.601Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgWrench;
