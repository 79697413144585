import * as React from 'react';
import { SVGProps } from 'react';
const SvgText = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.25 7.25v-1.5H5.75v1.5M12 6v12.25m0 0h-1.25m1.25 0h1.25"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgText;
