import React from 'react';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import { Tooltip } from '../Tooltip';
import { Card } from './Card';
import * as style from './MetricsCard.css';

type SubheadingProps = { subheader: string; infoTooltip?: string };
const Subheading = ({ subheader, infoTooltip }: SubheadingProps) => (
  <div className={style.subheader}>
    <div>{subheader}</div>
    {infoTooltip ? (
      <Tooltip content={infoTooltip}>
        <Icons.Info className={style.icon} fontSize={16} />
      </Tooltip>
    ) : null}
  </div>
);

type MetricsCardProps = {
  children: React.ReactNode;
  metricsData: React.ReactNode;
  description?: string;
  bordered?: boolean;
} & Pick<SubheadingProps, 'subheader' | 'infoTooltip'>;

export const MetricsCard: React.FC<MetricsCardProps> = ({
  subheader,
  infoTooltip,
  metricsData,
  description,
  bordered = 'true',
  children,
}) => {
  return (
    <Card variant={bordered ? 'bordered' : 'minimal'}>
      <div className={style.slot}>
        <div className={style.content}>
          <Subheading subheader={subheader} infoTooltip={infoTooltip} />
          <div className={style.mainLabel}>{metricsData}</div>
          {description ? <div className={style.description}>{description}</div> : null}
        </div>
        <div>{children}</div>
      </div>
    </Card>
  );
};
