import React, { RefObject, createRef, useCallback, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

export interface IStickyColumnsHelpeWidthrProps {
  tableRef: RefObject<HTMLTableElement>;
  stickyColumnsLeft?: number;
}

interface IStickyColumnWidthHelperProps {
  columnRef: RefObject<HTMLTableColElement>;
  setStickyColumnWidth: (columnWidth: number) => void;
}

const StickyColWidthHelper = ({ columnRef, setStickyColumnWidth }: IStickyColumnWidthHelperProps) => {
  // Set the CSS "left" variables upon initial render and update them if <col> size for changes.
  useEffect(() => {
    if (!columnRef.current) return undefined;
    const resizeObserver = new ResizeObserver(() => {
      const width = columnRef?.current?.getBoundingClientRect().width;
      if (width !== undefined) {
        setStickyColumnWidth(width);
      }
    });

    // Note: ResizeObserver will fire when the table is resized, but it will also fire upon this inital call of .observe()
    resizeObserver.observe(columnRef?.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [columnRef, setStickyColumnWidth]);

  return <col ref={columnRef} />;
};

const StickyColGroupWidthHelper = ({ stickyColumnsLeft = 0, tableRef }: IStickyColumnsHelpeWidthrProps) => {
  const stickyColumnsRefs: RefObject<HTMLTableColElement>[] = useMemo(() => {
    return Array(stickyColumnsLeft)
      .fill(null)
      .map(() => createRef<HTMLTableColElement>());
  }, [stickyColumnsLeft]);

  const stickyColumnsWidths: number[] = useMemo(() => Array(stickyColumnsLeft), [stickyColumnsLeft]);

  const setStickyColumnWidth = useCallback(
    (columnIndex: number, columnWidth: number) => {
      if (stickyColumnsWidths[columnIndex] === columnWidth) return;
      stickyColumnsWidths[columnIndex] = columnWidth;
      // update the CSS variables for the sticky columns
      stickyColumnsWidths.reduce((acc, width, index) => {
        tableRef?.current?.style.setProperty(`--sticky-left-${index}`, `${acc}px`);
        return acc + width;
      }, 0);
    },
    [stickyColumnsWidths, tableRef],
  );

  const StickyColumns = useMemo(() => {
    return stickyColumnsRefs.map((ref: RefObject<HTMLTableColElement>, index: number) => {
      return (
        <StickyColWidthHelper
          key={`sticky-cols-${uuid()}`}
          columnRef={ref}
          setStickyColumnWidth={(width: number) => setStickyColumnWidth(index, width)}
        />
      );
    });
  }, [setStickyColumnWidth, stickyColumnsRefs]);

  return <colgroup>{StickyColumns}</colgroup>;
};

export default StickyColGroupWidthHelper;
