import React from 'react';
import { Helmet } from 'react-helmet';

export type PageTitleProps = {
  children: string;
};

/**
 * Sets a page's <title> attribute. Helmet sets the title to the most deeply
 * nested instance of the component
 */
const PageTitle = ({ children }: PageTitleProps) => (
  <Helmet>
    <title>{children}</title>
  </Helmet>
);

export default PageTitle;
