import * as React from 'react';
import { SVGProps } from 'react';
const SvgDataTransfer = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m2.499 1.91-.95.95c-.1.1-.217.149-.35.145a.503.503 0 0 1-.35-.157.527.527 0 0 1-.144-.35.458.458 0 0 1 .144-.35l1.8-1.8a.439.439 0 0 1 .162-.106A.553.553 0 0 1 3 .211c.067 0 .13.01.187.031.059.021.113.056.163.106l1.8 1.8a.466.466 0 0 1 .137.344.51.51 0 0 1-.137.356c-.1.1-.219.15-.356.15a.487.487 0 0 1-.357-.15L3.5 1.911v7.087c0 .142-.048.26-.144.357A.484.484 0 0 1 3 9.498a.484.484 0 0 1-.356-.143.484.484 0 0 1-.144-.357V1.911Zm4 6.176V.998c0-.141.048-.26.144-.356a.484.484 0 0 1 .356-.144c.142 0 .26.048.356.144A.484.484 0 0 1 7.5.998v7.088l.95-.95c.1-.1.217-.148.35-.144.133.004.25.056.35.156.092.1.14.217.144.35a.458.458 0 0 1-.144.35l-1.8 1.8a.439.439 0 0 1-.163.107.554.554 0 0 1-.187.03.553.553 0 0 1-.188-.03.439.439 0 0 1-.162-.107l-1.8-1.8a.466.466 0 0 1-.138-.343.51.51 0 0 1 .138-.357c.1-.1.219-.15.356-.15.138 0 .256.05.356.15l.938.938Z"
      fill="#8B7D96"
    />
  </svg>
);
export default SvgDataTransfer;
