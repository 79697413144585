import React from 'react';
import { Flex } from '..';
import { SourceIcon, SourceIconProps } from './SourceIcon';

export const SourceIcons = ({ sources, ...sourceIconProps }: SourceIconProps) => {
  if (!sources || sources.length === 0) {
    return null;
  }
  return (
    <Flex.Container gap="2u">
      {sources.map((source) => (
        <SourceIcon key={source} sources={[source]} {...sourceIconProps} />
      ))}
    </Flex.Container>
  );
};
