import * as React from 'react';
import { SVGProps } from 'react';
const SvgTransposeMatrix = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.6} width={20} height={20} rx={4} fill="#FCEAFF" />
    <path
      d="M9.254 6.345V3.332h6.667c.383 0 .703.129.96.385.257.257.386.578.386.961v1.667H9.254ZM5.28 16.665c-.384 0-.704-.128-.961-.385a1.304 1.304 0 0 1-.386-.96V8.651h3.013v8.013H5.28ZM3.933 6.345V4.678c0-.383.129-.704.386-.96.257-.257.577-.386.96-.386h1.667v3.013H3.933Zm7.084 11.154L8.26 14.742l2.757-2.756.59.59-1.74 1.75h2.977c.575 0 1.067-.204 1.473-.61.407-.407.61-.898.61-1.474V9.255l-1.75 1.75-.59-.59 2.757-2.756 2.756 2.756-.59.59-1.75-1.75v2.987c0 .81-.283 1.499-.85 2.066a2.813 2.813 0 0 1-2.066.85H9.866l1.74 1.75-.59.59Z"
      fill="#7200B3"
    />
  </svg>
);
export default SvgTransposeMatrix;
