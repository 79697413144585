import * as React from 'react';

import Alert from '../../elements/Alert';
import Background from '../../elements/Background';
import Button from '../../elements/Button';
import GoogleButton from '../../elements/GoogleButton';
import Text from '../../elements/inputs/Text';
import PageTitle from '../../elements/PageTitle';
import { TEST_IDS } from '../../test_ids';

const LoginPage: React.FC = () => {
  const metaTag = document.querySelector('meta[name="csrf-token"]');
  const authenticityToken = metaTag && metaTag.getAttribute('content');

  const { alert, notice } = (window as any).flash;

  return (
    <Background>
      <PageTitle>Sign In - Mutiny</PageTitle>
      <h2 className="m-0">Sign In</h2>
      <br />
      {alert && <Alert>{alert}</Alert>}
      {notice && <Alert color="primary">{notice}</Alert>}
      <form action="/login" method="post" style={{ width: '100%' }}>
        <input type="hidden" name="authenticity_token" defaultValue={authenticityToken || ''} />
        <Text
          label="Email"
          name="user[email]"
          id="Email"
          fullWidth
          autoFocus
          dataTestId={TEST_IDS.LoginPage.EmailInput}
        />
        <br />
        <br />
        <Text
          label="Password"
          name="user[password]"
          id="Password"
          type="password"
          fullWidth
          dataTestId={TEST_IDS.LoginPage.PasswordInput}
        />
        <a href="/forgot_password" className="link-text text-md">
          Forgot password?
        </a>
        <br />
        <br />
        <Button
          type="submit"
          color="primary"
          aria-label="Sign In"
          fullWidth
          data-test-id={TEST_IDS.LoginPage.LoginButton}
        >
          Sign In
        </Button>
        <GoogleButton aria-label="Sign In with Google" authenticityToken={authenticityToken}>
          Sign In with Google
        </GoogleButton>
      </form>
    </Background>
  );
};

export default LoginPage;
