import React from 'react';
import * as style from './SeenDot.css';
import { Tooltip } from '../Tooltip';

interface IProps {
  hoverText: string;
  onClick?: () => void;
}

export const SeenDot = ({ hoverText, onClick }: IProps): React.ReactElement => {
  return (
    <Tooltip content={hoverText} side="top" variant="dark" asChild>
      <div className={style.seenDotContainer} onClick={onClick} />
    </Tooltip>
  );
};
