import React, { MouseEventHandler, ReactNode } from 'react';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import { Label } from '../Label';
import { ExpandIndicator } from './ExpandIndicator';
import * as styles from './SelectInput.css';
import { Message } from '../Message/Message';
import { SelectVariantEnum } from './interfaces';

type ButtonProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface SelectInputProps extends ButtonProps {
  children: ReactNode;
  label?: string;
  disabled?: boolean;
  editable?: boolean;
  onClear?: MouseEventHandler;
  showIndicator?: boolean;
  error?: boolean;
  errorMessage?: string;
  message?: string;
  multi?: boolean;
  hasSelection?: boolean;
  menuIsOpen?: boolean;
  fullWidth?: boolean;
  variant?: SelectVariantEnum;
}

export const SelectPlaceholder = ({ children }: { children: ReactNode }) => {
  return <div className={styles.placeholder}>{children}</div>;
};

export const SelectInput = React.forwardRef<HTMLDivElement, SelectInputProps>(
  (
    {
      label,
      children,
      disabled = false,
      editable = true,
      onClear,
      showIndicator = true,
      error,
      errorMessage,
      message,
      multi = false,
      hasSelection = true,
      menuIsOpen,
      variant = SelectVariantEnum.default,
      fullWidth = true,
      ...props
    },
    forwardedRef,
  ) => {
    const selectionType = multi ? 'multi' : 'single';

    return (
      <div className={styles.container({ fullWidth })}>
        {label && <Label aria-disabled={disabled}>{label}</Label>}
        <div className={styles.container({ fullWidth })}>
          <div
            contentEditable={editable} // This prop is really important for inputs that live inside a draggable item.
            suppressContentEditableWarning={editable}
            data-optionselected={!multi && hasSelection}
            ref={forwardedRef}
            role="combobox"
            aria-expanded={menuIsOpen}
            aria-controls={undefined}
            aria-disabled={disabled}
            className={styles.input({ selectionType, variant })}
            data-erroneous={error ? 'true' : undefined}
            {...props}
          >
            {children}
            <div className={styles.controls} data-disabled={disabled ? 'true' : undefined}>
              {!!onClear && (
                <>
                  <div onClick={onClear}>
                    <Icons.Close />
                  </div>
                  <div className={styles.controlDivider} />
                </>
              )}
              {showIndicator && <ExpandIndicator />}
            </div>
          </div>
        </div>
        <Message variant="error" active={error && !disabled} disabled={disabled}>
          {errorMessage ?? message}
        </Message>
        <Message active={!error && !!message} disabled={disabled}>
          {message}
        </Message>
      </div>
    );
  },
);

export { ExpandIndicator };
