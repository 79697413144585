import React, { useRef } from 'react';
import { Slot } from '@radix-ui/react-slot';
import cx from 'classnames';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import * as style from './TableHeaderCell.css';

type NativeHeaderCellProps = Omit<React.ThHTMLAttributes<HTMLTableCellElement>, 'className'>;
interface HeaderCellCommonProps extends NativeHeaderCellProps {
  alignText?: 'left' | 'right' | 'center';
  width?: string | number;
}

export interface HeaderCellButtonProps extends HeaderCellCommonProps {
  buttonRef?: React.Ref<HTMLButtonElement> | null;
  onHeaderClick: React.MouseEventHandler<HTMLButtonElement>;
}

export interface HeaderCellStandardProps extends HeaderCellCommonProps {
  asChild?: boolean;
}

export type HeaderCellProps = HeaderCellButtonProps | HeaderCellStandardProps;

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type HeaderSortIconProps = Omit<React.HTMLAttributes<SVGElement>, 'className'> & {
  sortDirection?: SortDirection | null;
  asChild?: boolean;
  label?: string;
};

const onMouseEventPreventDefault = (e: React.MouseEvent) => e.preventDefault();

const HeaderClickableCell = ({
  alignText = 'left',
  buttonRef,
  onHeaderClick,
  children,
  ...props
}: HeaderCellButtonProps) => {
  const headerCellRef = useRef<HTMLTableCellElement>(null);

  return (
    <th
      ref={headerCellRef}
      className={cx(style.tableHeaderCell.clickable, style.headerAlignments[alignText])}
      {...props}
    >
      <button
        type="button"
        ref={buttonRef}
        onMouseDown={onMouseEventPreventDefault}
        onClick={onHeaderClick}
        className={style.tableHeaderCellButton}
      >
        {children}
      </button>
    </th>
  );
};

const HeaderCellStandard = ({ alignText = 'left', asChild, children, ...props }: HeaderCellStandardProps) => {
  const CellContentComponent = (asChild && Slot) || 'span';
  const headerCellRef = useRef<HTMLTableCellElement>(null);

  return (
    <th ref={headerCellRef} className={cx(style.tableHeaderCell.default, style.headerAlignments[alignText])} {...props}>
      <CellContentComponent className={style.tableHeaderCellContent}>{children}</CellContentComponent>
    </th>
  );
};

export const HeaderCell = ({ width, ...props }: HeaderCellProps) => {
  if ('onHeaderClick' in props && props.onHeaderClick !== undefined) {
    return <HeaderClickableCell style={{ width }} {...props} />;
  }

  return <HeaderCellStandard {...props} />;
};

export const HeaderSortIcon = ({ sortDirection, children, label = 'sort', ...props }: HeaderSortIconProps) => {
  const SortIcon = (sortDirection === SortDirection.ASC && Icons.ChevronUp) || Icons.ChevronDown;
  const iconStyleClass = (!!sortDirection && style.arrowIcons.sorted) || style.arrowIcons.unsorted;

  return (
    <SortIcon className={iconStyleClass} height="2em" width="2em" aria-label={label} {...props}>
      {children}
    </SortIcon>
  );
};
