import React, { ReactNode } from 'react';
import * as styles from './KeyboardShortcutIndicator.css';

interface Props {
  hint?: string;
  children: ReactNode;
}

export const KeyboardShortcutIndicator = ({ children, hint }: Props) => {
  return (
    <div className={styles.root} title={hint}>
      {children}
    </div>
  );
};
