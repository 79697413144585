import React, { SVGProps } from 'react';

const Marketo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="#5c4c9f"
    viewBox="1 1 65 61"
    preserveAspectRatio="none"
    {...props}
  >
    <path d="M59.4 50.972V8.892L43.055 0v64zM22.276 5.563l13.17 3.9v40.56l-13.17 5.753zm-7.608 8.274L4.636 12.22l-.048 36.327 10.08-2.663z" />
  </svg>
);

export default Marketo;
