import React, { SVGProps } from 'react';

import { Tooltip, TooltipProps, Icons } from '@mutiny-pkg/dumpster-ui';

const DATA_SOURCE_DISPLAY_NAMES: Record<string, string> = {
  csv: 'CSV',
  hubspot_accounts: 'Hubspot',
  hubspot_contacts: 'Hubspot',
  hubspot: 'Hubspot',
  marketo: 'Marketo',
  identify: 'Mutiny',
  mutiny: 'Mutiny',
  owners: 'Account owner',
  outreach_accounts: 'Outreach',
  outreach_prospects: 'Outreach',
  outreach: 'Outreach',
  sfdc_accounts: 'Salesforce',
  sfdc_opportunities: 'Salesforce',
  sfdc_contacts: 'Salesforce',
  sfdc_leads: 'Salesforce',
  sfdc: 'Salesforce',
  salesforce: 'Salesforce',
};

const DISPLAY_NAME_PRIORITY = ['CSV', 'Salesforce', 'Hubspot', 'Marketo', 'Outreach', 'Mutiny'];

export const sourceToDisplayName = (source?: string) => {
  if (!source) {
    return '';
  }
  return DATA_SOURCE_DISPLAY_NAMES[source] || 'Mutiny';
};

const formatDataSources = (sources: string[]): string => {
  const displayNames = [...new Set(sources.map((source) => sourceToDisplayName(source)))];
  const sortedDisplayNames = displayNames.sort((a, b) => {
    const priorityA = DISPLAY_NAME_PRIORITY.length - DISPLAY_NAME_PRIORITY.indexOf(a);
    const priorityB = DISPLAY_NAME_PRIORITY.length - DISPLAY_NAME_PRIORITY.indexOf(b);
    if (priorityA > priorityB) {
      return -1;
    }
    if (priorityB > priorityA) {
      return 1;
    }
    return 0;
  });
  return sortedDisplayNames.join(', ');
};

type SourceSVGProps = Omit<SVGProps<SVGSVGElement>, 'className' | 'src'>;
export type SourceIconProps = {
  sources?: string[];
  tooltipProps?: Partial<TooltipProps>;
  displayName?: string;
} & SourceSVGProps;

const defaultIconSize = {
  width: '20px',
  height: '20px',
};

export const SourceSVGIcon = ({ source, ...svgProps }: { source?: string } & SourceSVGProps) => {
  switch (source) {
    case 'owners':
      return <Icons.Users {...defaultIconSize} {...svgProps} />;
    case 'csv':
      return <Icons.Csv {...defaultIconSize} {...svgProps} />;
    case 'sfdc_accounts':
    case 'sfdc_opportunities':
    case 'sfdc_contacts':
    case 'sfdc_leads':
    case 'sfdc':
    case 'salesforce':
      return <Icons.Salesforce {...defaultIconSize} {...svgProps} />;
    case 'hubspot_accounts':
    case 'hubspot_contacts':
    case 'hubspot':
      return <Icons.Hubspot {...defaultIconSize} {...svgProps} />;
    case 'marketo':
      return <Icons.Marketo {...defaultIconSize} {...svgProps} />;
    case 'outreach_accounts':
    case 'outreach_prospects':
    case 'outreach':
      return <Icons.Outreach {...defaultIconSize} {...svgProps} />;
    case 'mutiny':
      return <Icons.Achoo {...defaultIconSize} {...svgProps} />;
    default:
      return <Icons.Settings2 {...defaultIconSize} {...svgProps} />;
  }
};

export const SourceIcon = ({ sources, displayName, tooltipProps, ...svgProps }: SourceIconProps) => {
  if (sources?.length === 1) {
    const source = sources.at(0);
    return (
      <Tooltip content={displayName ?? sourceToDisplayName(source)} variant="dark" {...tooltipProps}>
        <SourceSVGIcon source={source} {...svgProps} />
      </Tooltip>
    );
  }
  if (sources && sources.length > 1) {
    return (
      <Tooltip content={formatDataSources(sources)} variant="dark" {...tooltipProps}>
        <Icons.Merged {...defaultIconSize} {...svgProps} />
      </Tooltip>
    );
  }
  return null;
};
