import * as React from 'react';
import { SVGProps } from 'react';
const SvgAtSign = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle
      cx={12}
      cy={12}
      r={3.25}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 19.25a7.25 7.25 0 1 1 0-14.5c6.813 0 7.25 4.375 7.25 7.25v1.25a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2v-4.5"
    />
  </svg>
);
export default SvgAtSign;
