import React from 'react';
import cx from 'classnames';
import * as style from './Card.css';

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  className?: string;
};

export type CardVariantTypes = NonNullable<style.Variants>;

export type CardProps = style.Variants & Omit<DivProps, 'ref'>;

const CardRoot = React.forwardRef(function Card(
  {
    fullWidth = false,
    layout = 'flex',
    size = 'large',
    variant = 'bordered',
    square = false,
    children,
    className,
    ...props
  }: CardProps,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <div {...props} ref={ref} className={cx(style.card({ fullWidth, layout, size, variant, square }), className)}>
      {children}
    </div>
  );
});

export type CardHeaderProps = DivProps;

export const CardHeader = ({ children, className, ...props }: CardHeaderProps) => {
  return (
    <div {...props} className={cx(style.header, className)}>
      {children}
    </div>
  );
};

export type CardBodyProps = DivProps;

export const CardBody = ({ children, className, ...props }: CardBodyProps) => {
  return (
    <div {...props} className={cx(style.body, className)}>
      {children}
    </div>
  );
};

export const Card = Object.assign(CardRoot, {
  Header: CardHeader,
  Body: CardBody,
});
