import React from 'react';
import { CheckboxOption } from './CheckboxOption';
import { OptionControls } from './OptionControls';
import { BaseOption } from './types';
import { useOptionSelection } from './useOptionSelection';
import { NoResults } from './NoResults';
import { BaseOptionDropdownLayout } from './BaseOptionDropdownLayout';
import { useDebouncedSearchOptions } from './useDebouncedSearchOptions';

interface Props<Option extends BaseOption> {
  availableOptions: Option[];
  setSelectedOptions: (options: Option[]) => void;
  selectedOptions: Option[];
  resetSelection?: () => void;
  selectionDescription?: string;
  children: React.ReactNode;
}

export const MultiOptionDropdown = <Option extends BaseOption>({
  availableOptions,
  selectedOptions,
  setSelectedOptions,
  children,
  resetSelection,
  selectionDescription,
}: Props<Option>) => {
  const { searchTerm, setSearchTerm, options } = useDebouncedSearchOptions(availableOptions, selectedOptions);
  const onSelect = useOptionSelection<typeof selectedOptions[0]>({ selectedOptions, setSelectedOptions });
  return (
    <BaseOptionDropdownLayout setSearchTerm={setSearchTerm} trigger={children}>
      <OptionControls
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        resetSelection={resetSelection}
        selectionDescription={selectionDescription}
      />
      {options.map((option) => (
        <CheckboxOption<typeof option> key={option.key} option={option} onClick={onSelect} />
      ))}
      {!options.length && <NoResults />}
    </BaseOptionDropdownLayout>
  );
};
