import React, { SVGProps } from 'react';

const Csv = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <rect width="20" height="20" rx="4" fill="#766483" />
    <path
      d="M6.52767 11.6668H8.19434V10.8334H6.80545V9.16678H8.19434V8.33344H6.52767C6.37026 8.33344 6.23832 8.38668 6.13184 8.49316C6.02535 8.59965 5.97211 8.73159 5.97211 8.889V11.1112C5.97211 11.2686 6.02535 11.4006 6.13184 11.5071C6.23832 11.6135 6.37026 11.6668 6.52767 11.6668ZM8.69434 11.6668H10.361C10.5184 11.6668 10.6504 11.6135 10.7568 11.5071C10.8633 11.4006 10.9166 11.2686 10.9166 11.1112V10.2779C10.9166 10.1205 10.8633 9.97465 10.7568 9.84039C10.6504 9.70613 10.5184 9.639 10.361 9.639H9.52767V9.16678H10.9166V8.33344H9.24989C9.09248 8.33344 8.96054 8.38668 8.85406 8.49316C8.74758 8.59965 8.69434 8.73159 8.69434 8.889V9.72233C8.69434 9.87974 8.74758 10.0209 8.85406 10.1459C8.96054 10.2709 9.09248 10.3334 9.24989 10.3334H10.0832V10.8334H8.69434V11.6668ZM12.361 11.6668H13.1943L14.1666 8.33344H13.3332L12.7777 10.2501L12.2221 8.33344H11.3888L12.361 11.6668ZM5.55545 14.4446C5.24989 14.4446 4.98832 14.3358 4.77072 14.1182C4.55313 13.9006 4.44434 13.639 4.44434 13.3334V6.66678C4.44434 6.36122 4.55313 6.09965 4.77072 5.88205C4.98832 5.66446 5.24989 5.55566 5.55545 5.55566H14.4443C14.7499 5.55566 15.0115 5.66446 15.2291 5.88205C15.4467 6.09965 15.5554 6.36122 15.5554 6.66678V13.3334C15.5554 13.639 15.4467 13.9006 15.2291 14.1182C15.0115 14.3358 14.7499 14.4446 14.4443 14.4446H5.55545ZM5.55545 13.3334H14.4443V6.66678H5.55545V13.3334Z"
      fill="white"
    />
    <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#1E0036" strokeOpacity="0.05" />
  </svg>
);

export default Csv;
