import * as React from 'react';
import { SVGProps } from 'react';
const SvgTimer = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.25 13a6.25 6.25 0 1 1-12.5 0 6.25 6.25 0 0 1 12.5 0ZM16.5 8.5l.75-.75M12 6.5V4.75m0 0H9.75m2.25 0h2.25M12 9.75v3.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgTimer;
